import { Component } from "@angular/core";
import { Appearance } from "./appearance";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

@Component({
    templateUrl: './appearanceView.modal.html',
})
export class AppearanceViewModalComponent {
    appearance: Appearance[];
    viewForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private styleService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.appearance = this.config.data;
        this.viewForm = this.formBuilder.group({
            name: [{ value: null, disabled: true }],
            color: [{ value: null, disabled: true }],
            standard: [{ value: null, disabled: true }],
            imgUrl: [{ value: null, disabled: true }],
        });
    }

    close() {
        this.ref.close();
    }
}