import { Component } from "@angular/core";
import { AppearanceMaterial } from "./appearanceMaterial";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { Appearance } from "src/app/entities/appearance/appearance";
import { Material } from "src/app/entities/material/material";
import { S3Service } from '../../services/s3.service';
import * as AWS from 'aws-sdk/global';
import Swal from 'sweetalert2';

@Component({
    templateUrl: './appearanceMaterialEdit.modal.html',
})
export class AppearanceMaterialEditModalComponent {
    appearanceMaterial: AppearanceMaterial[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    appearances: Appearance[];
    materials: Material[];
    selectedFiles: FileList;
    tempUrl: any;

    constructor(
        private appearanceMaterialService: ApiService,
        private appearanceService: ApiService,
        private materialService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        this.appearanceService.getAll("appearances", true).then(appearances => {
            this.appearances = appearances['result'];
        });
        this.materialService.getAll("materials").then(materials => {
            this.materials = materials['result'];
        });
    }

    ngOnInit() {
        this.appearanceMaterial = this.config.data;
        this.updateForm = this.formBuilder.group({
            appearance: [null, Validators.required],
            material: [null, Validators.required]
        });
    }

    get f() {
        return this.updateForm.controls;
    }

    onSubmit(appearanceMaterial: AppearanceMaterial) {
        appearanceMaterial['appearanceId'] = appearanceMaterial.appearance.id;
        appearanceMaterial['materialId'] = appearanceMaterial.material.id;
        this.loading = true;
        this.submitted = true;
        this.appearanceMaterialService.update("appearanceMaterials", appearanceMaterial)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }
}