import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import { ApiService } from 'src/app/services/api.service';

import { Product } from 'src/app/entities/product/product';
import Swal from 'sweetalert2';
import { ProductAddModalComponent } from './productAdd.modal';
import { ProductEditModalComponent } from './productEdit.modal';
import { ProductViewModalComponent } from './productView.modal';
import { PackagedProductComponent } from '../packagedProduct/packagedProduct.component';
import { AppliedProductModalComponent } from '../appliedProduct/appliedProduct.modal';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css'],
    providers: [DialogService]
})
export class ProductComponent implements OnInit {

    @ViewChild('dt') dataTable;
    products: Product[] = [];
    allProducts = [];
    items: MenuItem[];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedItens: Product[] = [];
    status: SelectItem[];
    type: SelectItem[];
    manufacturers: any[];
    manufacturerList: SelectItem[] = [];
    brands: any[];
    brandList: SelectItem[] = [];
    categories: any[];
    categoryList: SelectItem[] = [];
    standards: any[];
    standardList: SelectItem[] = [];
    binary: SelectItem[];
    binaryWithNull: SelectItem[];
    colors: any[];
    colorList: SelectItem[] = [];
    editmode = false;
    optionsThreePoints: MenuItem[];
    currentPage: number = 1;
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    modelFilter = '';
    standardFilter = null;
    categoryFilter = null;
    manufacturerFilter = null;
    brandFilter = null;
    colorsFilter = null
    manufacturerCodeFilter = '';
    manufacturerLinkFilter = '';
    manualPdfFilter = '';
    commercialColorFilter = '';
    benefitsFilter = '';
    technicalObservationsFilter = '';
    impactAppearanceFilter = '';
    isVisibleFilter = '';
    isServiceFilter = '';
    updateFilter = '';
    rowsPerPageOptions;

    constructor(
        private userService: ApiService,
        private authenticationService: AuthenticationService,
        private productService: ApiService,
        private router: Router,
        private dialogService: DialogService
    ) {
    }

    ngOnInit() {
        this.loadAllProductsByAdmin();

        this.productService.getAll("standards").then(standards => {
            this.standards = standards['result'];
        });
        this.productService.getAll("productCategories").then(categories => {
            this.categories = categories['result'];
            this.categories.forEach(element => {
                delete element.hasCompleted;
                delete element.specifics;
            });
        });
        this.productService.getAll("manufacturers").then(manufacturers => {
            this.manufacturers = manufacturers['result'];
            this.manufacturers.forEach(element => {
                delete element.brands;
            });
        });
        this.productService.getAll("brands").then(brands => {
            this.brands = brands['result'];
        })
        this.productService.getAll("colors").then(colors => {
            this.colors = colors['result'];
        });

        this.cols = [
            { orderCol: 'orderStandards', field: 'standard', header: 'Padrões' },
            { orderCol: 'productCategory.name', field: 'category', header: 'Categoria' },
            { orderCol: 'packagedProducts', field: 'packagedProducts', header: 'Embalagem' },
            { orderCol: 'appliedProduct', field: 'appliedProduct', header: 'Produto Aplicado' },
            { orderCol: 'orderColors', field: 'color', header: 'Cores' },
            { orderCol: 'name', field: 'name', header: 'Nome' },
            { orderCol: 'model', field: 'model', header: 'Modelo' },
            { orderCol: 'manufacturer.name', field: 'manufacturer', header: 'Fabricante' },
            { orderCol: 'brand.name', field: 'brand', header: 'Marca' },
            { orderCol: 'manufacturerCode', field: 'manufacturerCode', header: 'Código do Fabricante' },
            { orderCol: 'manufacturerLink', field: 'manufacturerLink', header: 'Link do Fabricante' },
            { orderCol: 'manualPdf', field: 'manualPdf', header: 'Manual - PDF' },
            { orderCol: 'commercialColor', field: 'commercialColor', header: 'Cor comercial' },
            { orderCol: 'benefits', field: 'benefits', header: 'Vantagens' },
            { orderCol: 'technicalObservations', field: 'technicalObservations', header: 'Observações Técnicas' },
            { orderCol: 'impactAppearance', field: 'impactAppearance', header: 'Impacto na aparência' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'isService', field: 'isService', header: 'Tipo' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.type = [
            { label: 'Produto', value: false},
            { label: 'Serviço', value: true}
        ];

        this.colsToggle = [
            { orderCol: 'name', field: 'name', header: 'Nome' },
            { orderCol: 'packagedProducts', field: 'packagedProducts', header: 'Embalagem' },
            { orderCol: 'appliedProduct', field: 'appliedProduct', header: 'Produto Aplicado' },
            { orderCol: 'productCategory.name', field: 'category', header: 'Categoria' },
            { orderCol: 'orderStandards', field: 'standard', header: 'Padrões' },
            { orderCol: 'orderColors', field: 'color', header: 'Cores' },
            { orderCol: 'manufacturer.name', field: 'manufacturer', header: 'Fabricante' },
            { orderCol: 'brand.name', field: 'brand', header: 'Marca' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'isService', field: 'isService', header: 'Tipo' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        this.selectedColumns = this.colsToggle;

        this.binary = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.binaryWithNull = [
            { label: '', value: null },
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];
    }

    onRowSelect(event, row) {
        this.selectedItens.push(row);
    }

    clearFilters(dt) {
        this.nameFilter = '';
        this.modelFilter = '';
        this.standardFilter = null;
        this.categoryFilter = null;
        this.manufacturerFilter = null;
        this.brandFilter = null;
        this.colorsFilter = null
        this.manufacturerCodeFilter = '';
        this.manufacturerLinkFilter = '';
        this.manualPdfFilter = '';
        this.commercialColorFilter = '';
        this.benefitsFilter = '';
        this.technicalObservationsFilter = '';
        this.impactAppearanceFilter = '';
        this.isVisibleFilter = '';
        this.isServiceFilter = '';
        this.updateFilter = '';
        this.products = this.allProducts;
        dt.reset();
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.productService.delete("products", id).then(() => {
                    this.loadAllProductsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail(error);
                    });
            }
        })
    }

    loadFilters() {
        let categoryName = [];
        let standardListName = [];
        let manufacturerName = [];
        let brandName = [];
        let colorListName = [];
        this.categoryList = [];
        this.standardList = [];
        this.manufacturerList = [];
        this.brandList = [];
        this.colorList = [];

        for (var i = 0; i < this.products.length; i++) {
            if (!categoryName.includes(this.products[i].productCategory.name)) {
                categoryName.push(this.products[i].productCategory.name);
                this.categoryList.push({ "label": this.products[i].productCategory.name, "value": this.products[i].productCategory.name });
            }
            if (!manufacturerName.includes(this.products[i].manufacturer.name)) {
                manufacturerName.push(this.products[i].manufacturer.name);
                this.manufacturerList.push({ "label": this.products[i].manufacturer.name, "value": this.products[i].manufacturer.name });
            }
            if (!brandName.includes(this.products[i].brand.name)) {
                brandName.push(this.products[i].brand.name);
                this.brandList.push({ "label": this.products[i].brand.name, "value": this.products[i].brand.name });
            }
            standardListName.push([]);

            if (this.products[i].standard.length) {
                for (var j = 0; j < this.products[i].standard.length; j++) {
                    delete this.products[i].standard[j].ProductStandard;

                    if (!standardListName[i].includes(this.products[i].standard[j].name)) {
                        standardListName[i].push(this.products[i].standard[j].name);
                    }
                }
                var abc = JSON.stringify(standardListName[i]);
                abc = abc.replace(/[\[\"{}\"\]]/g, '');
                if (!this.standardList.some(colList => colList["label"] === abc)) {
                    this.standardList.push({ "label": abc, "value": standardListName[i] });
                }
                this.products[i]['orderStandards'] = standardListName[i];
            }
            colorListName.push([]);

            if (this.products[i].color.length) {
                for (var j = 0; j < this.products[i].color.length; j++) {
                    delete this.products[i].color[j].ProductColor;

                    if (!colorListName[i].includes(this.products[i].color[j].name)) {
                        colorListName[i].push(this.products[i].color[j].name);
                    }
                }
                var abc = JSON.stringify(colorListName[i]);
                abc = abc.replace(/[\[\"{}\"\]]/g, '');
                if (!this.colorList.some(colList => colList["label"] === abc)) {
                    this.colorList.push({ "label": abc, "value": colorListName[i] });
                }
                this.products[i]['orderColors'] = colorListName[i];
            }
        }
    }

    loadAllProductsByAdmin() {
        this.productService.getAll("products", true).then(products => {

            
            products['result'].forEach(element => {
                delete element.productCategory.specifics;
            });

            this.rowsPerPageOptions = undefined;
            if (products['result'].length > 10 && products['result'].length < 25) {
              this.rowsPerPageOptions = [10, 25];
            }
            else if (products['result'].length > 25) {
              this.rowsPerPageOptions = [10, 25, products['result'].length];
            }

            this.products = products['result'];
            console.log("this.products", this.products);
            
            this.allProducts = products['result'];
            // if(this.currentPage != -1)
            // this.setCurrentPage(this.currentPage) 
            // this.currentPage = -1;
            this.loadFilters();
        });
    }

    
    teste(ev){
        console.log('ev', ev);
        console.log('first', ev.first);
        this.currentPage = (ev.first + ev.rows) / ev.rows;
        console.log('page', this.currentPage);
    }

    setCurrentPage(n: number) {
        let paging = {
        first: ((n - 1) * this.dataTable.rows),
        rows: this.dataTable.rows
    };
    this.dataTable.paginate(paging);
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllProductsByAdmin();
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedItens.forEach(item => {
            ids.push(item.id);
        });
        this.productService.deleteAll("products", { "ids": ids }).then(() => {
            this.selectedItens.length = 0;
            this.loadAllProductsByAdmin();
        }, (error) => {
            this.swalDeleteFail(error);
        });
    }

    swalDeleteFail(er) {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: er.error.messages,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    all = [];
    filterObject(event) {
        this.products = this.allProducts;
        let tempObj: Product[] = [];
        let ev = event.toUpperCase();
        console.log("product", this.products);
        this.products.forEach(val => {
            if ((val.productCategory.name.toUpperCase().includes(ev)) || (val.manufacturer.name.toUpperCase().includes(ev) )
            || (val.name.toUpperCase().includes(ev) )
            || (val.benefits.toUpperCase().includes(ev) )
            || (val.brand && val.brand.name.toUpperCase().includes(ev) )
            || (val.commercialColor.toUpperCase().includes(ev) )) {
                tempObj.push(val);
            }
        });
        this.all = tempObj;


        if (this.all.length != 0) {
            this.products = this.all;
        }
    }

    edit(product: Product) {
        product.productCategoryId = product.productCategory.id;
        product.manufacturerId = product.manufacturer.id;
        if (product.brand != null) product.brandId = product.brand.id;
        //Cria a lista de ids de cores para edicao
        var listIdColors = [];
        product.color.forEach(e => {
            listIdColors.push(e.id);
        });
        //TO DO: aqui ele substitui o objeto exibido na tela oq faz a lista de intervencoes sumir por alguns segundos 
        product.color = listIdColors;

        var listIdStandard = [];
        product.standard.forEach(e =>{
            listIdStandard.push(e.id);
        });
        product.standard = listIdStandard;
        this.productService.update("products", product).then(() => {
            this.loadAllProductsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllProductsByAdmin();
        });
    }

    showPackagedProducts(product: Product) {
        this.dialogService.open(PackagedProductComponent, {
            data: product,
            header: 'Embalagens do produto: ' + product.name,
            width: '80%',
            contentStyle: { "max-height": "90vh", "overflow-y": "auto", "background-color": "#FAFAFA" }
        }).onClose.subscribe(() => {
            this.loadAllProductsByAdmin();
        });
    }

    showAppliedProducts(product: Product) {
        this.dialogService.open(AppliedProductModalComponent, {
            data: product,
            header: 'Aplicações de Produto do produto: ' + product.name,
            width: '80%',
            contentStyle: { "max-height": "90vh", "overflow-y": "auto", "background-color": "#FAFAFA" }
        }).onClose.subscribe(() => {
            this.loadAllProductsByAdmin();
        });
    }


    showAdd() {
        this.dialogService.open(ProductAddModalComponent, {
            header: 'Cadastrar Produto',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto", "padding": "2%" }
        }).onClose.subscribe(() => {
            this.loadAllProductsByAdmin();
        });
    }

    showEdit(product: Product) {
        this.dialogService.open(ProductEditModalComponent, {
            data: product,
            header: 'Editar Produto',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllProductsByAdmin();
        });
    }

    showView(product: Product) {
        this.dialogService.open(ProductViewModalComponent, {
            data: product,
            header: 'Visualizar Produto',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            // this.loadAllProductsByAdmin();
        });
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: Product) {
        let arrayColor = [];
        row['color'].forEach(el => {
            arrayColor.push(el.id);
        });
        let arrayStandard = [];
        row['standard'].forEach(el =>{
            arrayStandard.push(el.id);
        });
        let arraySpecifics = [];
        row['productProductSpecifics'].forEach(el => {
            arraySpecifics.push({ id: el.productCategorySpecificationId, name: el.value });
        })
        this.productService.register("products", <any>{
            "name": this.makeIdToDuplicateRow(5) + row['name'],
            "model": row['model'],
            "standard": arrayStandard,
            "productCategoryId": row['productCategory'].id,
            "productCategory": row['productCategory'],
            "color": arrayColor,
            "manufacturerId": row['manufacturer'].id,
            "manufacturer": row['manufacturer'],
            "brandId": row['brandId'],
            "brand": row['brand'],
            "manufacturerCode": row['manufacturerCode'],
            "manufacturerLink": row['manufacturerLink'],
            "manualPdf": row['manualPdf'],
            "commercialColor": row['commercialColor'],
            "benefits": row['benefits'],
            "technicalObservations": row['technicalObservations'],
            "impactAppearance": row['impactAppearance'],
            "isVisible": row['isVisible'],
            "isService": row['isService'],
            "productSpecifics": arraySpecifics,
            "allColorsSelected": row['allColors'],
            "allStandardsSelected": row['allStandard']
        }).then((prod) => {
            for (const appProd of row['appliedProduct']) {
                let copyConsumptionCriterias = JSON.parse(JSON.stringify(appProd.consumptionCriterias));
                let copyInclusionCriterias = JSON.parse(JSON.stringify(appProd.inclusionCriterias));
                delete appProd.id;
                delete appProd.consumptionCriterias;
                delete appProd.inclusionCriterias;
                appProd.productId = prod['result'].id;
                this.productService.register("appliedProducts", appProd).then((appProdGenerated)=>{
                    for (const icrit of copyInclusionCriterias) {
                        let copyInclusionConditions = JSON.parse(JSON.stringify(icrit.inclusionConditions));
                        delete icrit.id;
                        icrit.appliedProductId = appProdGenerated['result'].id;
                        this.productService.register("inclusionCriterias", icrit).then((icritGenerated)=>{
                            for (const icond of copyInclusionConditions) {
                                let copyLeftExp = JSON.parse(JSON.stringify(icond.leftExp));
                                let copyRightExp = JSON.parse(JSON.stringify(icond.rightExp));
                                let copyExpression = copyLeftExp.concat(copyRightExp);
                                delete icond.id;
                                icond.inclusionCriteriaId = icritGenerated['result'].id;
                                this.productService.register("inclusionConditions", icond).then((icondGenerated)=>{
                                    for (const exp of copyExpression) {
                                        delete exp.id;
                                        exp.inclusionConditionId = icondGenerated['result'].id;
                                        this.productService.register("inclusionConditionExpressions", exp);
                                    }
                                })
                            }
                        })
                    }
                    for (const conCrit of copyConsumptionCriterias) {
                        let copyConsumptionConditions = JSON.parse(JSON.stringify(conCrit.consumptionConditions));
                        delete conCrit.id;
                        conCrit.appliedProductId = appProdGenerated['result'].id;
                        conCrit.isDuplicate = true;
                        this.productService.register("consumptionCriterions", conCrit).then((conCritGenerated)=>{
                            for (const ccond of copyConsumptionConditions) {
                                let copyLeftExp = JSON.parse(JSON.stringify(ccond.leftExp));
                                let copyRightExp = JSON.parse(JSON.stringify(ccond.rightExp));
                                let copyExpression = copyLeftExp.concat(copyRightExp);
                                delete ccond.id;
                                ccond.consumptionCriterionId = conCritGenerated['result'].id;
                                this.productService.register("consumptionConditions", ccond).then((ccondGenerated)=>{
                                    for (const exp of copyExpression) {
                                        delete exp.id;
                                        exp.isDuplicate = true;
                                        exp.consumptionConditionId = ccondGenerated['result'].id;
                                        this.productService.register("consumptionConditionExpressions", exp);
                                    }
                                })
                            }
                        }).catch(err=> console.log('error', err))
                    }
                })

            }
            // row['packagedProducts'].forEach(element => {
            //     element.packagedProductStore = [];
            //     for (let i = 0; i < element.stores.length; i++) {
            //         element.packagedProductStore.push({ storeId: element.stores[i].id, storeUrl: element.stores[i].storeUrl, price: element.stores[i].price })
            //     }
            //     element.productId = prod['result'].id;
            // });

            // for (let j = 0; j < row['packagedProducts'].length; j++) {
            //     delete row['packagedProducts'][j].id;
            //     row['packagedProducts'][j].barCode = this.makeIdToDuplicateRow(5) + row['packagedProducts'][j].barCode;
            //     this.productService.register("packagedProducts", row['packagedProducts'][j]).then(() => {
            //     });
            // }
            this.loadAllProductsByAdmin();

        },
            (er) => {
                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            }
        );
    }

    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");

        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table-edit");
                inputTable[i].classList.add("form-pro-table");
            }
        }
    }
}
