import { Component, OnInit } from '@angular/core';
import { PossibleAction } from 'src/app/entities/possibleAction/possibleAction';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { DynamicDialogRef } from 'primeng/components/dynamicdialog/dynamicdialog-ref';
import { DynamicDialogConfig } from 'primeng/components/dynamicdialog/dynamicdialog-config';

@Component({
  templateUrl: './possibleActionView.modal.component.html'
})
export class PossibleActionViewModalComponent implements OnInit {

  possibleAction: PossibleAction[];
  viewForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private possibleActionService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.possibleAction = this.config.data;
    this.viewForm = this.formBuilder.group({
      name: [{ value: null, disabled: true }],
      action: [{ value: null, disabled: true }],
      preexistence: [{ value: null, disabled: true }],
      material: [{ value: null, disabled: true }],
      interventions: [{ value: null, disabled: true }],
      imgUrl: [{ value: null, disabled: true }],
      speed: [{value: null, disabled: true}]
    });
  }

  close() {
    this.ref.close();
  }

}
