import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';

import { PreExistence } from "./preexistence";
import { ApiService } from "../../services/api.service";

@Component({
    templateUrl: './preexistenceEdit.modal.html',
})
export class PreexistenceEditModalComponent {
    preexistence: PreExistence[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    flag: SelectItem[]

    constructor(
        private preexistenceService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.flag = [
            { label: 'Piso', value: 'floor'},
            { label: 'Parede', value: 'wall'},
          ]
      
        this.preexistence = this.config.data;
        this.updateForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            description: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            flag: [null, Validators.required]
        });
    }

    get f() {
        return this.updateForm.controls;
    }

    onSubmit(preexistence: PreExistence) {
        this.loading = true;
        this.preexistenceService.update("preexistences", preexistence)
            .then(
                () => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }
}