import { Component } from "@angular/core";
import { Material } from "./material";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { S3Service } from '../../services/s3.service';
import Swal from 'sweetalert2';

@Component({
    templateUrl: './materialEdit.modal.html',
    styleUrls: ['./material.component.css'],
})
export class MaterialEditModalComponent {
    material: Material[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    tempUrl: any;
    binary: SelectItem[];
    floorWall: SelectItem[];
    usageType: SelectItem[];
    environmentType: SelectItem[];

    constructor(
        private materialService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private s3Service: S3Service,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.material = this.config.data;
        this.tempUrl = this.material['imgUrl'];
        this.updateForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            waterResistant: ['', Validators.required],
            waterResistantWall: ['', Validators.required],
            nonSlip: ['', Validators.required],
            nonSlipWall: ['', Validators.required],
            scratchResistant: ['', Validators.required],
            scratchResistantWall: ['', Validators.required],
            stainResistant: ['', Validators.required],
            stainResistantWall: ['', Validators.required],
            cold: ['', Validators.required],
            coldWall: ['', Validators.required],
            description: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            environmentType: ['', Validators.required],
            environmentTypeWall: ['', Validators.required],
            usageType: ['', Validators.required],
            usageTypeWall: ['', Validators.required],
            type: [''],
            imgUrl: ['', Validators.required]
        });


        this.binary = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.floorWall = [
            { label: 'Piso', value: 'Piso'},
            { label: 'Parede', value: 'Parede'}
        ];


        this.environmentType = [
            { label: 'Interno', value: 'Interno' },
            { label: 'Externo', value: 'Externo' }
        ];

        this.usageType = [
            { label: 'Residencial', value: 'Residencial' },
            { label: 'Comercial', value: 'Comercial' }
        ];

    }

    get f() {
        return this.updateForm.controls;
    }

    onSubmit(material: Material) {
        this.loading = true;
        this.updateForm.value.imgUrl = this.tempUrl;
        material['imgUrl'] = this.tempUrl;
        this.materialService.update("materials", material)
            .then(
                () => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    deleteImg() {
        this.tempUrl = null;
        this.updateForm.value.imgUrl1 = null;
        document.getElementById("imgUrl")['value'] = "";
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }

    close() {
        this.ref.close();
    }
}
