import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import { ApiService } from "src/app/services/api.service";

import { Lead } from "src/app/entities/lead/lead";
import Swal from "sweetalert2";

@Component({
  selector: "app-lead",
  templateUrl: "./lead.component.html",
  styleUrls: ["./lead.component.css"],
  providers: [DialogService],
})
export class LeadComponent implements OnInit {
  leads: Lead[] = [];
  allLeads = [];
  items: MenuItem[];
  cr: any;
  cols: any[];
  colsToggle: any[];
  selectedColumns: any[];
  selectedItens: Lead[] = [];
  status: SelectItem[];
  isValidOptions: SelectItem[];
  actions: any[];
  actionList: SelectItem[] = [];
  materials: any[];
  materialList: SelectItem[] = [];
  preexistences: any[];
  preexistenceList: SelectItem[] = [];
  appearances: any[];
  appearanceList: SelectItem[] = [];
  styles: any[];
  styleList: SelectItem[] = [];
  environments: any[];
  binary: SelectItem[];
  ternary: SelectItem[];
  floorWallOptions: SelectItem[];

  editmode = false;
  optionsThreePoints: MenuItem[];
  ptBr = {
    firstDayOfWeek: 0,
    dayNames: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
    dateFormat: "dd/mm/yy",
    weekHeader: "Sem",
  };
  nameFilter = "";
  listNameFilter = "";
  emailFilter = "";
  stateFilter = "";
  areaFilter = "";
  appearanceFilter = null;
  styleFilter = null;
  actionFilter = null;
  materialFilter = null;
  preexistenceFilter = null;
  statusFilter = "";
  updateFilter = "";
  interventionsNameFilter = "";
  resumeProductsFilter = "";
  rowsPerPageOptions;
  floorWallFilter = null;
  commentFilter = "";
  evaluationFilter = null;

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private router: Router,
    private dialogService: DialogService
  ) {
    this.apiService.getAll("actions").then((actions) => {
      this.actions = actions["result"];
    });
    this.apiService.getAll("materials").then((materials) => {
      this.materials = materials["result"];
    });
    this.apiService.getAll("preexistences").then((preexistences) => {
      this.preexistences = preexistences["result"];
    });
    this.apiService.getAll("appearances").then((appearances) => {
      this.appearances = appearances["result"];
    });
    this.apiService.getAll("styles").then((styles) => {
      this.styles = styles["result"];
    });
    this.apiService.getAll("environments").then((environments) => {
      this.environments = environments["result"];
    });
  }

  ngOnInit() {
    this.loadAllLeadsByAdmin();

    this.cols = [
      { orderCol: "name", field: "name", header: "Nome" },
      { orderCol: "email", field: "email", header: "E-mail" },
      { orderCol: "stateSelected", field: "stateSelected", header: "Estado" },
      { orderCol: "listName", field: "listName", header: "Nome da Lista" },
      { orderCol: "typeCoating", field: "typeCoating", header: "Piso/Parede" },
      { orderCol: "areaM2", field: "areaM2", header: "Área(M²)" },
      {
        orderCol: "statusMonitoring",
        field: "statusMonitoring",
        header: "Status de Monitoramento",
      },
      {
        orderCol: "preexistence.name",
        field: "preexistence",
        header: "Preexistência",
      },
      {
        orderCol: "environment.name",
        field: "environment",
        header: "Ambientação",
      },
      { orderCol: "appearance.name", field: "appearance", header: "Aparência" },
      { orderCol: "material.name", field: "material", header: "Material" },
      { orderCol: "style.name", field: "style", header: "Estilo" },
      { orderCol: "action.name", field: "action", header: "Ação" },
      {
        orderCol: "interventionsName",
        field: "interventionsName",
        header: "Intervenções",
      },
      {
        orderCol: "detailProducts",
        field: "detailProducts",
        header: "Produtos",
      },
      { orderCol: "comment", field: "comment", header: "Comentário" },
      { orderCol: "evaluation", field: "evaluation", header: "Nota" },
      { orderCol: "updatedAt", field: "updatedAt", header: "Modificado" },
    ];

    this.status = [
      { label: "Ativo", value: true },
      { label: "Inativo", value: false },
    ];

    this.isValidOptions = [
      { label: "Válido", value: true },
      { label: "Inválido", value: false },
    ];

    this.colsToggle = [
      { orderCol: "name", field: "name", header: "Nome" },
      { orderCol: "email", field: "email", header: "E-mail" },
      { orderCol: "stateSelected", field: "stateSelected", header: "Estado" },
      { orderCol: "listName", field: "listName", header: "Nome da Lista" },
      { orderCol: "typeCoating", field: "typeCoating", header: "Piso/Parede" },
      { orderCol: "areaM2", field: "areaM2", header: "Área(M²)" },
      {
        orderCol: "environment.name",
        field: "environment",
        header: "Ambientação",
      },
      {
        orderCol: "interventionsName",
        field: "interventionsName",
        header: "Intervenções",
      },
      {
        orderCol: "detailProducts",
        field: "detailProducts",
        header: "Produtos",
      },
      { orderCol: "comment", field: "comment", header: "Comentário" },
      { orderCol: "evaluation", field: "evaluation", header: "Nota" },
      {
        orderCol: "statusMonitoring",
        field: "statusMonitoring",
        header: "Status de Monitoramento",
      },
    ];

    this.selectedColumns = this.colsToggle;

    this.binary = [
      { label: "Sim", value: "Sim" },
      { label: "Não", value: "Não" },
    ];

    this.ternary = [
      { label: "Visualizado", value: "Visualizado" },
      { label: "Respondido", value: "Respondido" },
      { label: "Monitorado", value: "Monitorado" },
    ];

    this.floorWallOptions = [
      { label: "Piso", value: "piso" },
      { label: "Parede", value: "parede" },
    ];
  }

  onRowSelect(event, row) {
    this.selectedItens.push(row);
  }

  edit(lead: Lead) {
    this.apiService.update("leads", lead).then(
      () => {
        this.loadAllLeadsByAdmin();
      },
      (error) => {
        var text = "";
        error.error["messages"].forEach((item, index) => {
          text += item;
          if (index < error.error["messages"].length - 1) text += ", ";
        });
        Swal.fire({
          title: "Edição Não Realizada!",
          text: text,
          type: "warning",
          confirmButtonColor: "#D95536",
          allowOutsideClick: false,
        });
        this.loadAllLeadsByAdmin();
      }
    );
  }

  clearFilters(dt) {
    this.nameFilter = "";
    this.actionFilter = null;
    this.materialFilter = null;
    this.preexistenceFilter = null;
    this.areaFilter = "";
    this.appearanceFilter = null;
    this.styleFilter = null;
    this.statusFilter = "";
    this.updateFilter = "";
    this.listNameFilter = "";
    this.stateFilter = "";
    this.interventionsNameFilter = "";
    this.resumeProductsFilter = "";
    this.floorWallFilter = null;
    this.leads = this.allLeads;
    dt.reset();
  }

  loadFilters() {
    let actionName = [];
    let materialName = [];
    let preexistenceName = [];
    let appearanceName = [];
    let styleName = [];
    this.actionList = [];
    this.materialList = [];
    this.preexistenceList = [];
    this.appearanceList = [];
    this.styleList = [];

    for (var i = 0; i < this.leads.length; i++) {
      try {
        if (!actionName.includes(this.leads[i].action.name)) {
          actionName.push(this.leads[i].action.name);
          this.actionList.push({
            label: this.leads[i].action.name,
            value: this.leads[i].action.name,
          });
        }
        if (!materialName.includes(this.leads[i].material.name)) {
          materialName.push(this.leads[i].material.name);
          this.materialList.push({
            label: this.leads[i].material.name,
            value: this.leads[i].material.name,
          });
        }
        if (!preexistenceName.includes(this.leads[i].preexistence.name)) {
          preexistenceName.push(this.leads[i].preexistence.name);
          this.preexistenceList.push({
            label: this.leads[i].preexistence.name,
            value: this.leads[i].preexistence.name,
          });
        }
        if (!appearanceName.includes(this.leads[i].appearance.name)) {
          appearanceName.push(this.leads[i].appearance.name);
          this.appearanceList.push({
            label: this.leads[i].appearance.name,
            value: this.leads[i].appearance.name,
          });
        }
        if (!styleName.includes(this.leads[i].style.name)) {
          styleName.push(this.leads[i].style.name);
          this.styleList.push({
            label: this.leads[i].style.name,
            value: this.leads[i].style.name,
          });
        }
      } catch (e) {
        // console.log(e);
      }
    }
  }

  loadAllLeadsByAdmin() {
    this.apiService.getAll("leads", true).then((leads) => {
      this.rowsPerPageOptions = undefined;
      if (leads["result"].length > 10 && leads["result"].length < 25) {
        this.rowsPerPageOptions = [10, 25];
      } else if (leads["result"].length > 25) {
        this.rowsPerPageOptions = [10, 25, leads["result"].length];
      }
      this.leads = leads["result"];
      this.allLeads = leads["result"];
      console.log("Leads: ", leads["result"]);
      //Exclui a relacao entre leads e interventions para bind no select de intervencoes na tabela
      this.loadFilters();
    });
  }

  testExportCSV(dt) {
    dt.value.forEach((v) => {
      for (let key in v) {
        if (typeof v[key] === "object" && v[key] != null) {
          if (key != "imgUrl") {
            if (v[key].length) {
              let coc = "";
              for (let i = 0; i < v[key].length; i++) {
                coc += v[key][i] + " - ";
              }
              v[key] = coc;
            } else {
              v[key] = v[key].name;
            }
          }
        }
        if (key == "updatedAt") {
          v[key] = new Date(v[key]).toLocaleString();
        }
      }
    });
    console.log("dt", dt);

    dt.exportCSV();
    this.loadAllLeadsByAdmin();
  }

  filterUpdatedAtOnTable(value, dt) {
    value = value.getTime();
    dt.value.forEach((val) => {
      if (typeof val.updatedAt === "string") {
        val.updatedAt = new Date(val.updatedAt).getTime();
      }
    });
    dt.filter(value, "updatedAt", "lte");
  }

  all = [];
  filterObject(event) {
    this.leads = this.allLeads;
    let tempObj: Lead[] = [];
    let ev = event.toUpperCase();
    this.leads.forEach((val) => {
      if (
        val.material.name.toUpperCase().includes(ev) ||
        val.action.name.toUpperCase().includes(ev) ||
        val.preexistence.name.toUpperCase().includes(ev)
      ) {
        tempObj.push(val);
      }
    });
    this.all = tempObj;

    if (this.all.length != 0) {
      this.leads = this.all;
    }
  }

  disabledEditTable() {
    var inputTable = document.getElementsByClassName("disabledInput");

    if (!this.editmode) {
      this.editmode = true;
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table");
        inputTable[i].classList.add("form-pro-table-edit");
      }
    } else {
      this.editmode = false;
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table-edit");
        inputTable[i].classList.add("form-pro-table");
      }
    }
  }
}
