import { Component } from "@angular/core";
import { Whitelabel } from "./whitelabel";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';

@Component({
    templateUrl: './whitelabelAdd.modal.html',
})
export class WhitelabelAddModalComponent {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    retailsOpts: any[]
    selectedRetails: any[];

    constructor(
        private whitelabelService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { 
        this.whitelabelService.getAll("retails", ).then(retails => {
            console.log('reta', retails);
            this.retailsOpts = retails['result'];
        })
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            retails: [null, Validators.required]
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        let obj = {retail: this.registerForm.value.retails.map(retail => retail.id)};
        console.log(obj);
        this.whitelabelService.register("whitelabels", obj).then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
        
    }

    close() {
        this.ref.close();
    }
}