import { Component } from "@angular/core";
import { Style } from "./style";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { S3Service } from '../../services/s3.service';
import * as AWS from 'aws-sdk/global';

@Component({
    templateUrl: './styleEdit.modal.html',
})
export class StyleEditModalComponent {
    style: Style[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    selectedFiles: FileList;
    tempUrl1: any;
    tempUrl2: any;
    tempUrl3: any;

    constructor(
        private styleService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) { }

    ngOnInit() {
        this.style = this.config.data;
        this.tempUrl1 = this.style['imgUrl1']
        this.tempUrl2 = this.style['imgUrl2']
        this.tempUrl3 = this.style['imgUrl3']
        this.updateForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            imgUrl1: [null],
            imgUrl2: [null],
            imgUrl3: [null],
        });
    }

    get f() {
        return this.updateForm.controls;
    }

    deleteImg(index) {
        if (index == 1) {
            this.tempUrl1 = null;
            this.updateForm.value.imgUrl1 = null;
            document.getElementById("imgUrl1")['value'] = "";
        }
        if (index == 2) {
            this.tempUrl2 = null;
            this.updateForm.value.imgUrl2 = null;
            document.getElementById("imgUrl2")['value'] = "";
        }
        if (index == 3) {
            this.tempUrl3 = null;
            this.updateForm.value.imgUrl3 = null;
            document.getElementById("imgUrl3")['value'] = "";
        }
    }

    onSubmit(style: Style) {
        style['imgUrl1'] = this.tempUrl1;
        style['imgUrl2'] = this.tempUrl2;
        style['imgUrl3'] = this.tempUrl3;
        this.loading = true;
        this.submitted = true;
        this.styleService.update("styles", style)
            .then(
                () => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    async selectFile(event, index) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    if (imgUrl) {
                        if (index == 1) {
                            this.tempUrl1 = imgUrl.toString();
                        }
                        if (index == 2) {
                            this.tempUrl2 = imgUrl.toString();
                        }
                        if (index == 3) {
                            this.tempUrl3 = imgUrl.toString();
                        }
                    }
                });
            } else {
                this.deleteImg(index);
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg(index);
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }
}