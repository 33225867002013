import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from './user';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DialogService, SelectItem } from "primeng/api";
import { UserAddModalComponent } from "./userAdd.modal";
import { UserEditModalComponent } from "./userEdit.modal";
import { UserViewModalComponent } from "./userView.modal";
import { MenuItem } from "primeng/api";
import Swal from 'sweetalert2';

@Component({
    templateUrl: 'user.component.html',
    styleUrls: ['user.component.css'],
    providers: [DialogService]
})
export class UserComponent implements OnInit {
    users: User[] = [];
    items: MenuItem[];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedItens: User[] = [];
    roles: SelectItem[];
    status: SelectItem[];
    editmode = false;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    emailFilter = '';
    roleFilter = '';
    socialNameFilter = '';
    cnpjFilter = '';
    addressFilter = '';
    telephoneFilter = '';
    statusFilter = '';
    updateFilter = '';
    rowsPerPageOptions;

    constructor(
        private userService: ApiService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.loadAllItemsByAdmin();

        this.cr = this.authenticationService.currentUserValue;
        this.roles = [
            { label: 'Administrador', value: 'admin' },
            { label: 'Varejista', value: 'retailer' },
            { label: 'Fabricante', value: 'manufacturer' },
            { label: 'Prestador de Serviço', value: 'provider' },
            { label: 'Usuário', value: 'user' },
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.cols = [
            { field: 'name', header: 'Nome' },
            { field: 'email', header: 'Email' },
            { field: 'role', header: 'Perfil' },
            { field: 'imgUrl', header: 'Foto' },
            { field: 'socialName', header: 'Nome Social' },
            { field: 'cnpj', header: 'CPF/CNPJ' },
            { field: 'address', header: 'Endereço' },
            { field: 'telephone', header: 'Telefone' },
            { field: 'isVisible', header: 'Status' },
            { field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'email', header: 'Email' },
                { field: 'role', header: 'Perfil' },
                { field: 'isVisible', header: 'Status' },
                { field: 'updatedAt', header: 'Modificado' }
            ];

        }

        this.selectedColumns = this.colsToggle;
    }

    onRowSelect(event, row) {
        this.selectedItens.push(row);
    }

    clearFilters(dt) {
        this.nameFilter = '';
        this.emailFilter = '';
        this.roleFilter = '';
        this.socialNameFilter = '';
        this.cnpjFilter = '';
        this.addressFilter = '';
        this.telephoneFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        dt.reset();
    }

    options(row: any) {
        if (row['id'] == this.cr.id) {
            this.optionsThreePoints = [
                { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
                { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
                { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' }
            ];
        } else {
            this.optionsThreePoints = [
                { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
                { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
                { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
                { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
            ];
        }
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.userService.delete("users", id).then(() => {
                    this.loadAllItemsByAdmin();
                });
            }
        })
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: User) {
        this.userService.register("users", {
            "name": row['name'],
            "email": this.makeIdToDuplicateRow(5) + row['email'],
            "password": "Pro-ref@19",
            "role": row['role'],
            "imgUrl": row['imgUrl'],
            "socialName": row['socialName'],
            "cnpj": row['cnpj'],
            "address": row['address'],
            "telephone": row['telephone'],
            "isVisible": row['isVisible']
        }).then(() => {
            this.loadAllItemsByAdmin();
            Swal.fire({
                title: 'Duplicação Realizada!',
                text: "A senha padrão para um usuário duplicado é Pro-ref@19",
                type: 'success',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            })
        },
            (er) => {
                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no email.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            }
        );
    }

    private loadAllItemsByAdmin() {
        this.userService.getAll("users", true).then(users => {
            this.rowsPerPageOptions = undefined;
            if (users['result'].length > 10 && users['result'].length < 25) {
                this.rowsPerPageOptions = [10, 25];
            }
            else if (users['result'].length > 25) {
                this.rowsPerPageOptions = [10, 25, users['result'].length];
            }
            this.users = users['result'];
        });
    }

    showAdd() {
        this.dialogService.open(UserAddModalComponent, {
            header: 'Cadastrar Usuário',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    showEdit(user: User) {
        this.dialogService.open(UserEditModalComponent, {
            data: user,
            header: 'Editar Usuário',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    showView(user: User) {
        this.dialogService.open(UserViewModalComponent, {
            data: user,
            header: 'Visualizar Usuário',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedItens.forEach(user => {
            if (user.id != this.cr['id']) {
                ids.push(user.id);
            }
        });
        this.userService.deleteAll("users", { "ids": ids }).then(() => {
            this.selectedItens.length = 0;
            this.loadAllItemsByAdmin();
        }, (error) => {
            Swal.fire({
                title: 'Deleção Não Realizada!',
                text: "Tente novamente mais tarde",
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            })
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(user: User) {
        this.userService.update("users", user).then(() => {
            this.loadAllItemsByAdmin();
        }, (error) => {
            var msgs = error.error['messages'];
            var message = '';
            msgs.forEach((m: string, index: number) => {
                message += m;
                if (index < msgs.length - 1) message += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: message,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            })
            this.loadAllItemsByAdmin();
        });
    }

    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");

        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table-edit");
                inputTable[i].classList.add("form-pro-table");
            }
        }
    }

}