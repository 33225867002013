import { Component } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';

@Component({
    templateUrl: './productCategoryAdd.modal.html',
})
export class ProductCategoryAddModalComponent {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    optionsType: SelectItem[];

    constructor(
        private categoryService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            unityMeasurement: ['', Validators.required]
        });

        this.optionsType = [
            {label: 'MM', value: 'MM'},
            {label: 'CM', value: 'CM'},
            {label: 'M', value: 'M'},
            {label: 'FT', value: 'FT'},
            {label: 'SQFT', value: 'SQFT'},
            {label: 'IN', value: 'IN'},
            {label: 'CM2', value: 'CM2'},
            {label: 'M2', value: 'M2'},
            {label: 'CM3', value: 'CM3'},
            {label: 'M3', value: 'M3'},
            {label: 'L', value: 'L'},
            {label: 'G', value: 'G'},
            {label: 'KG', value: 'KG'},
            {label: 'U', value: 'U'}
        ]
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        this.categoryService.register("productCategories", this.registerForm.value)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }
}