import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef, MenuItem, SelectItem } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-environment-product',
  templateUrl: './environment-product.component.html',
  styleUrls: ['./environment-product.component.css']
})
export class EnvironmentProductComponent implements OnInit {

  activated = false;
  environment;
  name: string;
  productsExclusive = [];
  productExclusiveModel: any;
  cr: any;
  cols: any[];
  colsToggle: any[];
  selectedColumns: any[];
  selectedProductExclusive = [];
  visibleSidebar1 = false;
  status: SelectItem[];
  products = [];
  productModel;
  materialModel;
  materials = [];
  registerForm: FormGroup;
  optionsThreePoints: MenuItem[];
  ptBr = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
      dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
      monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Sem'
  };
  nameFilter = '';
  productExclusiveFilter = '';
  materialFilter = '';
  statusFilter = '';
  updateFilter = '';

  constructor(
      private userService: ApiService,
      private specificService: ApiService,
      private router: Router,
      private dialogService: DialogService,
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private formBuilder: FormBuilder,
  ) {

  }

  ngOnInit() {
      this.environment = this.config.data;

      this.userService.getById("users", localStorage.getItem('currentUserId')).then((cur) => {
              this.cr = cur['result'];
              this.loadAllEnviProdByAdmin();
              this.specificService.getEntity('products').then((prods) => {
                this.products = prods['result'];
              });
              this.specificService.getEntity('materials').then((mats)=>{
                this.materials = mats['result'];
              });
          });

      this.registerForm = this.formBuilder.group({
          product: ['', Validators.required],
          material: ['', Validators.required]
      });

      this.cols = [
          { orderCol: 'product.name', field: 'product', header: 'Produto' },
          { orderCol: 'material.name', field: 'material', header: 'Material'},
          { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
          { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
      ];

      this.status = [
          { label: 'Ativo', value: true },
          { label: 'Inativo', value: false }
      ];

      //Check responsiveness to adjust columns to display on mobile
      var width = window.innerWidth;
      if (width <= 500) {
          this.colsToggle = [
            { orderCol: 'product.name', field: 'product', header: 'Produto' },
            { orderCol: 'material.name', field: 'material', header: 'Material'},
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
          ];
      }
      else {
          this.colsToggle = [
            { orderCol: 'product.name', field: 'product', header: 'Nome' },
            { orderCol: 'material.name', field: 'material', header: 'Material'},
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
          ];
      }

      this.selectedColumns = this.colsToggle;

  }

  get f() {
      return this.registerForm.controls;
  }


  onRowSelect(event, row) {
      this.selectedProductExclusive = row;
  }


  clearFilters(dt) {
      this.nameFilter = '';
      this.productExclusiveFilter = '';
      this.materialFilter = '';
      this.statusFilter = '';
      this.updateFilter = '';
      dt.reset();
  }

  options(row: any) {
      this.optionsThreePoints = [
          // { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
          // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
          // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
          { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
      ]
      return this.optionsThreePoints;
  }

  delete(id: string) {
      Swal.fire({
          title: 'Você tem certeza que deseja realizar a deleção?',
          text: "Esta ação não poderá ser revertida!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#D95536',
          confirmButtonText: 'Deletar',
          cancelButtonText: 'Cancelar',
          allowOutsideClick: false
      }).then((result) => {
          if (result.value) {
              this.specificService.delete("environmentProducts", id).then(() => {
                  this.loadAllEnviProdByAdmin();
              },
                  (error) => {
                      this.swalDeleteFail();
                  });
          }
      })
  }

  closeModal() {
      this.ref.close();
  }

  loadAllEnviProdByAdmin() {
      this.specificService.listChildByEntityId(this.environment.id, 'getProductsByEnvironmentId/').then(productsExclusive => {
          this.productsExclusive = productsExclusive['result'];
          console.log("prodsExcl", this.productsExclusive);
          
      });
  }

  showAdd() {
      document.getElementById('btns-mng').style.display = 'none';
  }

  registerEnvProd() {
      let objReg = this.registerForm.value;
      objReg.environmentId = this.environment.id;
      objReg.productId = this.productModel.id;
      objReg.materialId = this.materialModel.id;
      objReg.isVisible = true;

      this.specificService.register("environmentProducts", objReg)
          .then(
              (success) => {
                  Swal.fire({
                      title: 'Cadastro realizado com sucesso!',
                      type: 'success',
                      confirmButtonColor: '#D95536',
                      allowOutsideClick: false
                  }).then((result) => {
                      if (result.value) {
                          this.registerForm.reset();
                          this.name = '';
                          this.productExclusiveModel = null;
                          this.loadAllEnviProdByAdmin();
                      }
                  })
              },
              (error) => {
                  var errorMsg = error.error.messages.toString();
                  errorMsg = errorMsg.replace(/,/g, '');
                  Swal.fire({
                      title: 'Adição Não Realizada!',
                      text: errorMsg,
                      type: 'warning',
                      confirmButtonColor: '#D95536',
                      allowOutsideClick: false
                  });
              });
  }

  close() {
      this.name = null;
      this.productExclusiveModel = null;
      document.getElementById('btns-mng').style.display = 'initial';
  }

  deleteAll() {
      let ids: string[] = [];
      this.selectedProductExclusive.forEach(user => {
          ids.push(user.id);
      });
      this.specificService.deleteAll("environmentProducts", { "ids": ids }).then(() => {
          this.selectedProductExclusive.length = 0;
          this.loadAllEnviProdByAdmin();
      }, (error) => {
          this.swalDeleteFail();
      });
  }

  swalDeleteFail() {
      Swal.fire({
          title: 'Deleção Não Realizada!',
          text: "Verifique se há relação com outro objeto!",
          type: 'warning',
          confirmButtonColor: '#D95536',
          allowOutsideClick: false
      });
  }

  makeIdToDuplicateRow(length) {
      let result = '';
      let characters = '123456790';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      result = "copy_" + result + "_";
      return result;
  }

  testExportCSV(dt) {
      dt.value.forEach(v => {
          for (let key in v) {
              if (typeof v[key] === "object" && v[key] != null) {
                  if (key != 'imgUrl') v[key] = v[key].name;
              }
              if (key == 'updatedAt') {
                  v[key] = new Date(v[key]).toLocaleString();
              }
          }
      });
      dt.exportCSV();
      this.loadAllEnviProdByAdmin();
  }

  filterUpdatedAtOnTable(value, dt) {
      value = value.getTime();
      dt.value.forEach(val => {
          if (typeof val.updatedAt === "string") {
              val.updatedAt = new Date(val.updatedAt).getTime();
          }
      });
      dt.filter(value, 'updatedAt', 'lte');
  }

  edit(environmentProd) {
      this.specificService.update("environmentProducts", environmentProd).then(() => {
          this.loadAllEnviProdByAdmin();
      }, (error) => {
          var text = '';
          error.error['messages'].forEach((item, index) => {
              text += item;
              if (index < error.error['messages'].length - 1) text += ', ';
          });
          Swal.fire({
              title: 'Edição Não Realizada!',
              text: text,
              type: 'warning',
              confirmButtonColor: '#D95536',
              allowOutsideClick: false
          });
          this.loadAllEnviProdByAdmin();
      });
  }

  editmode = false;
  disabledEditTable() {
      var inputTable = document.getElementsByClassName("disabledInput");
      if (!this.editmode) {
          this.editmode = true;
          for (let i = 0; i < inputTable.length; i++) {
              inputTable[i].classList.remove("form-pro-table");
              inputTable[i].classList.add("form-pro-table-edit");
          }
      }
      else {
          this.editmode = false;
          for (let j = 0; j < inputTable.length; j++) {
              inputTable[j].classList.remove("form-pro-table-edit");
              inputTable[j].classList.add("form-pro-table");
          }
      }
  }
}
