import { Component } from "@angular/core";
import { Environment } from "./environment";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { S3Service } from '../../services/s3.service';
import { Appearance } from "src/app/entities/appearance/appearance";
import { Style } from "src/app/entities/style/style";

@Component({
    templateUrl: './environmentEdit.modal.html',
})
export class EnvironmentEditModalComponent {
    environment: Environment[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    selectedFiles: FileList;
    appearances: Appearance[];
    styles: Style[];
    tempUrl: any;
    binary: SelectItem[];

    constructor(
        private environmentService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private appearanceService: ApiService,
        private styleService: ApiService,
        private router: Router
    ) {
        this.appearanceService.getAll("appearances", true).then(appearances => {
            this.appearances = appearances['result'];
        });
        this.styleService.getAll("styles").then(styles => {
            this.styles = styles['result'];
        });
    }

    ngOnInit() {
        this.environment = this.config.data;
        this.tempUrl = this.environment['imgUrl'];
        this.updateForm = this.formBuilder.group({
            descriptionFloor: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            descriptionWall: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            appearance: [null, Validators.required],
            style: [null, Validators.required],
            appearanceWall: [null, Validators.required],
            // styleWall: [null, Validators.required],
            isExclusive: [null, Validators.required],
            displayOrder: ['', Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            imgUrl: ['', Validators.required],
            photoCredits: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            blogUrl: ['', Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])]
        });
        this.binary = [
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
          ];
    }

    deleteImg() {
        this.tempUrl = null;
        this.updateForm.value.imgUrl1 = null;
        document.getElementById("imgUrl")['value'] = "";
    }

    get f() {
        return this.updateForm.controls;
    }

    onSubmit(environment: Environment) {
        this.loading = true;
        environment['imgUrl'] = this.tempUrl;
        this.environment['appearanceId'] = environment.appearance.id;
        this.environment['styleId'] = environment.style.id;
        this.environment['appearanceWallId'] = environment.appearanceWall.id
        // this.environment['styleWallId'] = environment.styleWall.id;
        this.environmentService.update("environments", environment)
            .then(
                () => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }
}
