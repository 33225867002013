import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from "src/app/services/api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { S3Service } from 'src/app/services/s3.service';
import { PackagedProduct } from './packagedProduct';
import { Product } from '../product/product';
import { Store } from '../store/store';

@Component({
    templateUrl: './packagedProduct.component.html',
    styleUrls: ['./packagedProduct.component.css'],
    providers: [DialogService]
})
export class PackagedProductComponent implements OnInit {

    activated = false;
    packagedProduct_pass: PackagedProduct;
    product: Product;
    unityPack: string;
    descriptionPack: string;
    storesArray;
    heightPack: string;
    widthPack: string;
    lengthPack: string;    
    weightPack: string;
    barCode: string;
    passBarCode: string;
    imgUrl : string;
    packagedProducts: PackagedProduct[] = [];
    stores: Store[];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedPackagedProducts: PackagedProduct[] = [];
    status: SelectItem[];
    registerForm: FormGroup;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    unityPackFilter = '';
    descriptionPackFilter = '';
    heightPackFilter = '';
    lengthPackFilter = '';
    widthPackFilter = '';        
    weightPackFilter = '';
    barCodeFilter = '';
    statusFilter = '';
    updateFilter = '';
    tempUrl: any;

    constructor(
        private packagedProductService: ApiService,
        private router: Router,
        private dialogService: DialogService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
    ) {

    }

    ngOnInit() {
        this.product = this.config.data;
        console.log("ths.prod", this.product.productCategory.unityMeasurement);
        
        this.packagedProductService.getAll("stores").then(result => this.stores = result['result']);
        this.loadAllPackagedProductsByAdmin();

        this.registerForm = this.formBuilder.group({
            unityPack: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            descriptionPack: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            heightPack: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(100)])],
            widthPack: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(100)])],            
            lengthPack: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(100)])],
            weightPack: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(100)])],
            barCode: [null, Validators.compose([Validators.minLength(1), Validators.maxLength(100), Validators.pattern(/^[0-9]*$/)])],
            imgUrl: ['']
            // stores: ['']
        });
        
        this.cols = [
            { field: 'unityPack', header: 'Quantidade' },
            { field: 'descriptionPack', header: 'Tipo de embalagem'},
            { field: 'heightPack', header: 'Altura (cm)'},
            { field: 'widthPack', header: 'Largura (cm)'},
            { field: 'lengthPack', header: 'Comprimento (cm)'},
            { field: 'weightPack', header: 'Peso(frete) - (kg)'},
            { field: 'stores', header: 'Lojas'},
            { field: 'barCode', header: 'Código de barras'},
            { field: 'imgUrl', header: 'Imagem'},
            { field: 'isVisible', header: 'Status' },
            { field: 'updatedAt', header: 'Modificado' }
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'unityPack', header: 'Quantidade' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'unityPack', header: 'Quantidade' },
                { field: 'barCode', header: 'Código de barras'},
                { field: 'stores', header: 'Lojas'},
                { field: 'imgUrl', header: 'Imagem'},
                { field: 'isVisible', header: 'Status' },
                { field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    get f() {
        return this.registerForm.controls;
    }


    onRowSelect(event, row) {
        this.selectedPackagedProducts = row;
    }

    showStores(row){
        document.getElementById('sp_cat').style.display = "initial";
        document.getElementById('sp_field').style.display = "none";
        document.getElementById('it_disp').style.display = "initial";
        document.getElementById('br_disp').style.display = "initial";
        this.config.header = 'Lojas do Produto-Embalado: ' + row.barCode;
        this.passBarCode = row.barCode;
        this.activated = !this.activated;
        this.packagedProduct_pass = row;
    }

    backPackagedProduct() {
        if (this.activated) {
            document.getElementById('sp_cat').style.display = "none";
            document.getElementById('sp_field').style.display = "initial";
            document.getElementById('it_disp').style.display = "none";
            document.getElementById('br_disp').style.display = "none";
            this.config.header = 'Embalagens do produto: ' + this.product.name;
            this.loadAllPackagedProductsByAdmin();
            this.activated = !this.activated;
        }
    }



    clearFilters(dt) {
        this.unityPackFilter = '';
        this.descriptionPackFilter = '';
        this.heightPackFilter = '';
        this.lengthPackFilter = '';
        this.widthPackFilter = '';
        this.weightPackFilter = '';
        this.barCodeFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        dt.reset();
    }

    options(row: any) {
        this.optionsThreePoints = [
            // { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.packagedProductService.delete("packagedProducts", id).then(() => {
                    this.loadAllPackagedProductsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    private loadAllPackagedProductsByAdmin() {
        this.packagedProductService.listChildByEntityId(this.product.id, '/listPackagedProductsByProducts/').then(packagedProducts => {
            this.packagedProducts = packagedProducts['result'];
            console.log("this.packagedProducts", this.packagedProducts);
            
        });
    }

    showAdd() {
        document.getElementById('btns-mng').style.display = 'none'
    }

    registerPackagedProduct(){
        // let checkValidPackagedProductStore = true;
        // let packagedProductStore = [];
        // this.packStore.forEach(packagedProductStore => {
        //     if(packagedProductStore.price == null || packagedProductStore.storeUrl == null)
        //     checkValidPackagedProductStore = false;    
        // });
        // if(!checkValidPackagedProductStore){
        //     Swal.fire({
        //         title: 'O Preço e Url das lojas selecionadas são obrigatórios!',
        //         text: "Verifique os campos e tente novamente",
        //         type: 'warning',
        //         confirmButtonColor: '#D95536',
        //         allowOutsideClick: false
        //     });
        // } else{
        //     this.packStore.forEach(element => {
        //         packagedProductStore.push({storeId: element.id, storeUrl: element.storeUrl, price: element.price.toString() });
        //     });
        if(!this.tempUrl) this.tempUrl = "https://pro-ref.s3.amazonaws.com/4jtattca5m4klo6th5amd.jpg";
            this.registerForm.value.imgUrl = this.tempUrl;
            let objReg = this.registerForm.value;
            objReg.productId = this.product.id;
            // objReg.packagedProductStore = packagedProductStore;
            
            this.packagedProductService.register("packagedProducts", objReg)
            .then(
                () => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            
                            this.unityPack = null;
                            this.descriptionPack = null;
                            this.storesArray = null; 
                            this.packStore = null;
                            this.heightPack = null;
                            this.widthPack = null;
                            this.lengthPack = null;
                            this.weightPack = null;
                            this.barCode = null;
                            this.imgUrl = null;
                            this.tempUrl = null;
                            this.loadAllPackagedProductsByAdmin();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                });
        // }
    }

    close(){
        this.unityPack = null;
        this.descriptionPack = null;
        this.storesArray = null;
        this.packStore = null;
        this.heightPack = null;
        this.lengthPack = null;        
        this.widthPack = null;        
        this.weightPack = null;
        this.barCode = null;
        this.imgUrl = null;
        this.tempUrl = null;
        this.deleteImg();
        document.getElementById('btns-mng').style.display = 'initial';
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedPackagedProducts.forEach(user => {
            ids.push(user.id);
        });
        this.packagedProductService.deleteAll("packagedProducts", { "ids": ids }).then(() => {
            this.selectedPackagedProducts.length = 0;
            this.loadAllPackagedProductsByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllPackagedProductsByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(packagedProduct: PackagedProduct) {
        
        this.packagedProductService.update("packagedProducts", packagedProduct).then((re) => {
            console.log("ret", re);
            
            this.loadAllPackagedProductsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllPackagedProductsByAdmin();
        });
    }

    resultStorePackagedProduct = [];
    packStore = [];
    loadPackagedProductStore(ev){
        ev[0].price = null;
        ev[0].storeUrl = null;
        this.packStore = ev;
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    deleteImg() {
        this.tempUrl = null;
        this.imgUrl = null;
    }


    async selectFile(event, opt, row) {
        if(opt === 'register'){
            if (event.files.item(0).type.includes("image")) {
                if (event.files.item(0).size <= 2000000) {
                    await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                        this.tempUrl = imgUrl.toString();
                    });
                } else {
                    this.deleteImg();
                    Swal.fire({
                        title: 'Upload de imagem não realizado!',
                        text: 'Coloque uma imagem com tamanho máximo de 2mb',
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            if (event.files.item(0).type.includes("image")) {
                if (event.files.item(0).size <= 2000000) {
                    await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                        row['imgUrl'] = imgUrl.toString();
                        this.edit(row);
                    });
                } else {
                    // this.deleteImg();
                    Swal.fire({
                        title: 'Upload de imagem não realizado!',
                        text: 'Coloque uma imagem com tamanho máximo de 2mb',
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        }
    }
}

