import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    returnUrl: string; 
    private auth: any;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,

    ) {
        console.log('entrou login')
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/home']);
        }

    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.email])],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }

    get f() { return this.loginForm.controls; }
    
    onSubmit() {
        this.submitted = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value, this.returnUrl);
    }
}
