import { Component } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import Swal from 'sweetalert2';
import { Retail } from "./retail";
import { S3Service } from "src/app/services/s3.service";

@Component({
    templateUrl: './retailEdit.modal.html',
})
export class RetailEditModalComponent {
    retail: Retail[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    tempUrl: any;

    constructor(
        private retailService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service
    ) { }

    ngOnInit() {
        this.retail = this.config.data;
        this.tempUrl = this.retail['logoUrl'];
        this.updateForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            cnpj: [null],
            municipalRegistration: [null],
            stateRegistration: [null],
            telephone: [null],
            address: [null],
            site: [null],
            email: [null],
            facebook: [null],
            instagram: [null],
            contactName: [null],
            contactEmail: [null],
            contactCell: [null],
            logoUrl: ['']
        });
    }

    get f() {
        return this.updateForm.controls;
    }

    onSubmit(retail: Retail) {
        this.loading = true;
        this.updateForm.value.logoUrl = this.tempUrl;
        retail['logoUrl'] = this.tempUrl;
        this.retailService.update("retails", retail)
            .then(
                () => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    deleteImg() {
        this.tempUrl = null;
        this.updateForm.value.logoUrl = null;
        document.getElementById("logoUrl")['value'] = "";
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }
}