import { Component } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { ProductCategory } from "../productCategory/productCategory";
import { ProductApplication } from "./productApplication";

@Component({
    templateUrl: './productApplicationView.modal.html',
})
export class ProductApplicationViewModalComponent {
    viewForm: FormGroup;
    loading = false;
    submitted = false;
    selectedProductCategory: ProductCategory;
    selectedFiles: FileList;
    tempUrl: any;
    productCategories: any[];
    productApplication: ProductApplication;

    constructor(
        private productApplicationService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        productApplicationService.getAll("productCategories", true).then(productCategories =>{ 
            this.productCategories = productCategories['result']
            this.productCategories.forEach(el => {
                delete el.specifics;
                delete el.hasCompleted;
            });
        });
    }

    ngOnInit() {
        this.productApplication = this.config.data;
        this.viewForm = this.formBuilder.group({
            name: [{ value: null, disabled: true }],
            productCategory: [{ value: null, disabled: true }]
        });
    }

    get f() {
        return this.viewForm.controls;
    }


    close() {
        this.ref.close();
    }
}