import { Component, OnInit } from '@angular/core';
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import { RetailAddModalComponent } from './retailAdd.modal';
import { ApiService } from 'src/app/services/api.service';
import { Retail } from 'src/app/entities/retail/retail';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { RetailEditModalComponent } from './retailEdit.modal';
import { RetailViewModalComponent } from './retailView.modal';
import { StoreComponent } from '../store/store.component';


@Component({
    templateUrl: './retail.component.html',
    styleUrls: ['./retail.component.css'],
    providers: [DialogService]
})
export class RetailComponent implements OnInit {
    
    retails: Retail[] = [];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedRetails: Retail[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    cnpjFilter = '';
    municipalRegistrationFilter = '';
    stateRegistrationFilter = '';
    telephoneFilter = '';
    addressFilter = '';
    siteFilter = '';
    emailFilter = '';
    facebookFilter = '';
    instagramFilter = '';
    contactNameFilter = '';
    contactEmailFilter = '';
    contactTelephoneFilter = ''
    statusFilter = '';
    updateFilter = '';
    rowsPerPageOptions;
    
    constructor(
        private dialogService: DialogService,
        private retailService: ApiService,
    ) {

    }

    ngOnInit() {
        this.retailService.getById("users", localStorage.getItem('currentUserId')).then((cur) => {
                this.cr = cur['result'];
                this.loadAllRetailsByAdmin();
            });

        this.cols = [
            { field: 'logoUrl', header: 'Logo' },
            { field: 'name', header: 'Nome' },
            { field: 'cnpj', header: 'Cnpj' },
            { field: 'stateRegistration', header: 'Inscrição Estadual' },
            { field: 'municipalRegistration', header: 'Inscrição Municipal' },
            { field: 'stores', header: 'Lojas' },
            { field: 'site', header: 'Site' },
            { field: 'email', header: 'Email' },
            { field: 'facebook', header: 'Facebook' },
            { field: 'instagram', header: 'Intagram' },
            { field: 'address', header: 'Endereço' },
            { field: 'telephone', header: 'Telefone' },
            { field: 'contactName', header: 'Nome do Contato' },
            { field: 'contactEmail', header: 'Email do Contato' },
            { field: 'contactCell', header: 'Celular do Contato' },
            { field: 'isVisible', header: 'Status' },
            { field: 'updatedAt', header: 'Modificado' }
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [                
                { field: 'name', header: 'Nome' },
                { field: 'cnpj', header: 'Cnpj' },
                { field: 'stores', header: 'Lojas' },
                { field: 'site', header: 'Site' },
                { field: 'facebook', header: 'Facebook' },
                { field: 'isVisible', header: 'Status' },
                { field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    showView(retail: Retail) {
        this.dialogService.open(RetailViewModalComponent, {
            data: retail,
            header: 'Visualizar Varejo',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllRetailsByAdmin();
        });
    }

    showEdit(retail: Retail) {
        this.dialogService.open(RetailEditModalComponent, {
            data: retail,
            header: 'Editar Varejo',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllRetailsByAdmin();
        });
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida! A deleção impactará no módulo de Loja!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.retailService.delete("retails", id).then(() => {
                    this.loadAllRetailsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedRetails.forEach(user => {
            ids.push(user.id);
        });
        this.retailService.deleteAll("retails", { "ids": ids }).then(() => {
            this.selectedRetails.length = 0;
            this.loadAllRetailsByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    private loadAllRetailsByAdmin() {
        this.retailService.getAll("retails", true).then(retails => {
            this.rowsPerPageOptions = undefined;
            if (retails['result'].length > 10 && retails['result'].length < 25) {
              this.rowsPerPageOptions = [10, 25];
            }
            else if (retails['result'].length > 25) {
              this.rowsPerPageOptions = [10, 25, retails['result'].length];
            }
            this.retails = retails['result'];
        });
    }

    showAdd() {
        this.dialogService.open(RetailAddModalComponent, {
            header: 'Cadastrar Varejo',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllRetailsByAdmin();
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(retail: Retail) {
        this.retailService.update("retails", retail).then(() => {
            this.loadAllRetailsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllRetailsByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    showStores(retail: Retail){
        this.dialogService.open(StoreComponent, {
            data: retail,
            header: 'Lojas do varejo : '+ retail.name,
            width: '80%',
            contentStyle: { "max-height": "90vh", "overflow-y": "auto", "background-color": "#FAFAFA" }
        }).onClose.subscribe(() => {
            this.loadAllRetailsByAdmin();
        });
    }


    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllRetailsByAdmin();
    }

    clearFilters(dt) {
        this.nameFilter = '';
        this.cnpjFilter = '';
        this.municipalRegistrationFilter = '';
        this.stateRegistrationFilter = '';
        this.telephoneFilter = '';
        this.addressFilter = '';
        this.siteFilter = '';
        this.facebookFilter = '';
        this.instagramFilter = '';
        this.contactNameFilter = '';
        this.contactEmailFilter = '';
        this.contactTelephoneFilter = ''
        this.statusFilter = '';
        this.updateFilter = '';
        this.emailFilter = '';
        dt.reset();
    }

}