import { Injectable } from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";

import { NgxUiLoaderService } from "ngx-ui-loader";

const bucket = new S3({
  region: "us-east-1",
});

@Injectable({
  providedIn: "root",
})
export class S3Service {
  constructor(private ngxService: NgxUiLoaderService) {}

  async uploadFile(file) {
    if (file) {
      this.ngxService.start();
      const contentType = file.type;
      const params = {
        Bucket: "proreforma2-prod-static-images",
        Key:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15) +
          ".jpg",
        Body: file,
        ACL: "public-read",
        ContentType: contentType,
      };

      let s3UploadPromise = await bucket.upload(params).promise();
      let imgUrl = s3UploadPromise.Location;
      this.ngxService.stop();
      return imgUrl;
    }
  }
}
