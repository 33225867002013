import { Component, OnInit } from '@angular/core';
import { Material } from 'src/app/entities/material/material';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from "src/app/services/api.service";
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DialogService, SelectItem } from "primeng/api";
import { MaterialAddModalComponent } from "./materialAdd.modal";
import { MaterialEditModalComponent } from './materialEdit.modal';
import { MaterialViewModalComponent } from './materialView.modal';
import { MenuItem } from "primeng/api";
import Swal from 'sweetalert2';

@Component({
    selector: 'app-materials',
    templateUrl: 'material.component.html',
    styleUrls: ['material.component.css'],
    providers: [DialogService]
})
export class MaterialComponent implements OnInit {
    materials: Material[] = [];
    cr: any;
    cols: any[];
    items: MenuItem[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedItens: Material[] = [];
    roles: SelectItem[];
    status: SelectItem[];
    floorWall: SelectItem[];
    binary: SelectItem[];
    envBinary: SelectItem[];
    usageBinary: SelectItem[];
    editmode = false;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    slipFilter = '';
    coldFilter = '';
    waterFilter = '';
    scratchFilter = '';
    stainFilter = '';
    statusFilter = '';
    floorWallFilter = '';
    useFilter = '';
    updateFilter = '';
    descriptionFilter = '';
    environmentTypeFilter = '';
    usageTypeFilter = '';
    slipFilterWall = '';
    coldFilterWall = '';
    waterFilterWall = '';
    scratchFilterWall = '';
    stainFilterWall = '';
    environmentTypeFilterWall = '';
    usageTypeFilterWall = '';
    imgUrlFilter = '';
    rowsPerPageOptions;

    constructor(
        private authenticationService: AuthenticationService,
        private materialService: ApiService,
        private router: Router,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.loadAllItemsByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.floorWall = [
            { label: '', value: null},
            { label: 'Piso', value: 'Piso'},
            { label: 'Parede', value: 'Parede'}
        ]

        this.cols = [
            { field: 'name', header: 'Nome' },
            { field: 'waterResistant', header: 'Resistência a água' },
            { field: 'nonSlip', header: 'Antiderrapante' },
            { field: 'scratchResistant', header: 'Resistência a risco' },
            { field: 'stainResistant', header: 'Resistência a abrasão' },
            { field: 'cold', header: 'Toque frio' },
            { field: 'waterResistantWall', header: 'Resistência a água Parede' },
            { field: 'nonSlipWall', header: 'Antiderrapante Parede' },
            { field: 'scratchResistantWall', header: 'Resistência a risco Parede' },
            { field: 'stainResistantWall', header: 'Resistência a abrasão Parede' },
            { field: 'coldWall', header: 'Toque frio Parede' },
            { field: 'description', header: 'Descrição' },
            { field: 'environmentTypeWall', header: 'Tipo de ambiente Parede' },
            { field: 'usageTypeWall', header: 'Tipo de uso Parede' },
            { field: 'environmentType', header: 'Tipo de ambiente' },
            { field: 'usageType', header: 'Tipo de uso' },
            { field: 'imgUrl', header: 'Imagem' },
            { field: 'isVisible', header: 'Status' },
            { field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'waterResistant', header: 'Resistência a água' },
                { field: 'nonSlip', header: 'Antiderrapante' },
                { field: 'isVisible', header: 'Status' },
                { field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.binary = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.usageBinary = [
            { label: 'Comercial', value: 'Comercial' },
            { label: 'Residencial', value: 'Residencial' }
        ];

        this.envBinary = [
            { label: 'Externo', value: 'Externo' },
            { label: 'Interno', value: 'Interno' } 
        ];


        this.selectedColumns = this.colsToggle;
    }

    clearFilters(dt) {
        this.nameFilter = '';
        this.slipFilter = '';
        this.coldFilter = '';
        this.waterFilter = '';
        this.scratchFilter = '';
        this.stainFilter = '';
        this.statusFilter = '';
        this.floorWallFilter = '';
        this.useFilter = '';
        this.updateFilter = '';
        this.descriptionFilter = '';
        this.environmentTypeFilter = '';
        this.usageTypeFilter = '';
        this.slipFilterWall = '';
        this.coldFilterWall = '';
        this.waterFilterWall = '';
        this.scratchFilterWall = '';
        this.stainFilterWall = '';
        this.environmentTypeFilterWall = '';
        this.usageTypeFilterWall = '';
        this.imgUrlFilter = '';
        dt.reset();
    }


    onRowSelect(event, row) {
        this.selectedItens.push(row);
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida! A deleção impactará nos módulos de AparênciaMaterial e Ação Possível!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.materialService.delete("materials", id).then(() => {
                    this.loadAllItemsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: Material) {
        this.materialService.register("materials", {
            "name": this.makeIdToDuplicateRow(5) + row['name'],
            "waterResistant": row['waterResistant'],
            "nonSlip": row['nonSlip'],
            "scratchResistant": row['scratchResistant'],
            "stainResistant": row['stainResistant'],
            "cold": row['cold'],
            "description": row['description'],
            "environmentType": row['environmentType'],
            "usageType": row['usageType'],
            "imgUrl": row['imgUrl'],
            "isVisible": false
        }).then(() => {
            this.loadAllItemsByAdmin();
        },
            (er) => {
                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            }
        );
    }

    private loadAllItemsByAdmin() {
        this.materialService.getAll("materials", true).then(materials => {
            this.rowsPerPageOptions = undefined;
            if(materials['result'].length > 10 && materials['result'].length < 25){
                this.rowsPerPageOptions = [10, 25];
            }
            else if(materials['result'].length > 25){
                this.rowsPerPageOptions = [10, 25, materials['result'].length];
            }
            this.materials = materials['result'];
        });
    }

    showAdd() {
        this.dialogService.open(MaterialAddModalComponent, {
            header: 'Cadastrar Material',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    showEdit(material: Material) {
        this.dialogService.open(MaterialEditModalComponent, {
            data: material,
            header: 'Editar Material',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    showView(material: Material) {
        this.dialogService.open(MaterialViewModalComponent, {
            data: material,
            header: 'Visualizar Material',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedItens.forEach(material => {
            ids.push(material.id);
        });
        this.materialService.deleteAll("materials", { "ids": ids }).then(() => {
            this.selectedItens.length = 0;
            this.loadAllItemsByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }
    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllItemsByAdmin();
    }

    edit(material: Material) {        
        this.materialService.update("materials", material).then(() => {
            this.loadAllItemsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllItemsByAdmin();
        });
    }

    editStatus(material: Material) {    
        if(material.isVisible === true) {
            this.edit(material);
        } else {
            Swal.fire({
                title: 'Você tem certeza que deseja realizar a desativação?',
                text: "Esta ação fará com que desative o material equivalente em todos os whitelabels",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D95536',
                confirmButtonText: 'Desativar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
              }).then((result) => {
                if (result.value) {
                    this.materialService.update("materials", material).then(() => {
                        this.loadAllItemsByAdmin();
                    }, (error) => {
                        var text = '';
                        error.error['messages'].forEach((item, index) => {
                            text += item;
                            if (index < error.error['messages'].length - 1) text += ', ';
                        });
                        Swal.fire({
                            title: 'Edição Não Realizada!',
                            text: text,
                            type: 'warning',
                            confirmButtonColor: '#D95536',
                            allowOutsideClick: false
                        });
                        this.loadAllItemsByAdmin();
                    });
                } else if(result.dismiss){
                    material.isVisible = true;
                }
              })
        }
    }

    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");

        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table-edit");
                inputTable[i].classList.add("form-pro-table");
            }
        }
    }
}
