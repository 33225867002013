import { Component, OnInit } from '@angular/core';
import { PreExistence } from 'src/app/entities/preexistence/preexistence';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { ApiService } from 'src/app/services/api.service';

@Component({
  templateUrl: './preExistenceView.modal.html'
})
export class PreexistenceViewModalComponent implements OnInit {
  preexistence: PreExistence[];
  viewForm: FormGroup;
  flag: SelectItem[];
  loading: false;
  submitted: false;

  constructor(
    private preexistenceService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.flag = [
      { label: 'Piso', value: 'floor'},
      { label: 'Parede', value: 'wall'},
    ]

    this.preexistence = this.config.data;
    this.viewForm = this.formBuilder.group({
      name: [{ value: null, disabled: true }],
      description: [{ value: null, disabled: true }],
      flag: [{value: null, disabled: true}]
    });
  }

  close() {
    this.ref.close();
  }

}
