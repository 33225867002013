import { Product } from "../product/product";
import { ProductApplication } from "../productApplication/productApplication";

export class AppliedProduct {
    id: string;
    productId: string;
    productApplicationId: string;
    products: Product;
    productApplications: ProductApplication;
    externalLocation: string;
    commercialUse: string;
    waterResistant: string;
    hasInclusionCriteria: string;
    preexistences: any[];
    consumptionObservations: string;
    complementaryApplication1Id: string;
    complementaryApplication2Id: string;
    complementaryApplication3Id: string;
    complementaryApplication1: any;
    complementaryApplication2: any;
    complementaryApplication3: any;
    updatedAt: string;
    isVisible: boolean;
}