import { Component } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { Manufacturer } from "./manufacturer";

@Component({
    templateUrl: './manufacturerView.modal.html',
})
export class ManufacturerViewModalComponent {
    manufacturer: Manufacturer[];
    viewForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private userService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.manufacturer = this.config.data;
        this.viewForm = this.formBuilder.group({
            name: [{ value: null, disabled: true }],
            cnpj: [{ value: null, disabled: true }],
            municipalRegistration:  [{ value: null, disabled: true }],
            stateRegistration: [{ value: null, disabled: true }],
            telephone: [{ value: null, disabled: true }],
            address: [{ value: null, disabled: true }],
            site: [{ value: null, disabled: true }],
            email: [{ value: null, disabled: true }],
            facebook: [{ value: null, disabled: true }],
            instagram: [{ value: null, disabled: true }],
            contactName: [{ value: null, disabled: true }],
            contactEmail: [{ value: null, disabled: true }],
            contactTelephone: [{ value: null, disabled: true }],
        });
    }

    close() {
        this.ref.close();
    }
}