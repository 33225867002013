import { Component, OnInit } from '@angular/core';
import { MenuItem, SelectItem, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { AppearanceMaterial } from '../appearanceMaterial/appearanceMaterial';
import { Whitelabel } from '../whitelabel/whitelabel';

@Component({
  selector: 'app-whitelabel-apperance-material',
  templateUrl: './whitelabel-apperance-material.component.html',
  styleUrls: ['./whitelabel-apperance-material.component.css']
})
export class WhitelabelApperanceMaterialComponent implements OnInit {
  whitelabelAppearanceMaterial: AppearanceMaterial[] = [];
  allWhitelabelAppearanceMaterial = [];
  items: MenuItem[];
  cr: any;
  cols: any[];
  colsToggle: any[];
  selectedColumns: any[];
  selectedWhitelabelAppearanceMaterials = [];
  status: SelectItem[];
  isValidOptions: SelectItem[];
  appearances: any[];
  appearanceList: SelectItem[] = [];
  materials: any[];
  materialList: SelectItem[] = [];
  optionsThreePoints: MenuItem[];
  appearanceFilter = null;
  materialFilter = null;
  validFilter = '';
  statusFilter = '';
  updateFilter = '';
  rowsPerPageOptions;
  ptBr = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
      dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
      monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Sem'
  };
    whitelabel: Whitelabel;

    constructor(
      private appearanceMaterialService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
    ) {
      this.appearanceMaterialService.getAll("appearances").then(appearances => {
        this.appearances = appearances['result'].map((appearance) => {
          let obj = {
            color: {name: appearance.color.name},
            colorId: appearance.colorId,
            name: appearance.name,
            standard: {name: appearance.standard.name},
            standardId: appearance.standardId
          }
          
          return obj;
        });
    });
    this.appearanceMaterialService.getAll("materials").then(materials => {
        this.materials = materials['result'].map((material)=> {
          let obj = {name: material.name}
          return obj;
        });
    });
    }

    ngOnInit() {
        this.whitelabel = this.config.data;
        
        this.loadAllAppearanceMaterialsByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.cols = [
            {  orderCol: 'appearance.name', field: 'appearance', header: 'Aparência', width: '40%' },
            {  orderCol: 'material.name', field: 'material', header: 'Material', width: '40%' },
            {  orderCol: 'isVisible', field: 'isVisible', header: 'Válido' },
            {  orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'appearance', header: 'Aparência', width: '40%' },
                { field: 'material', header: 'Material', width: '40%' },
                { field: 'isVisible', header: 'Válido' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'appearance.name', field: 'appearance', header: 'Aparência', width: '40%' },
                { orderCol: 'material.name', field: 'material', header: 'Material', width: '40%' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Válido' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    clearFilters(dt){
        this.appearanceFilter = null;
        this.materialFilter = null;
        this.validFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        this.whitelabelAppearanceMaterial = this.allWhitelabelAppearanceMaterial;
        dt.reset();
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllAppearanceMaterialsByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    loadFilters(){
        let appearanceName = [];
        let materialName = [];
        this.appearanceList = [];
        this.materialList = [];

        for(var i = 0; i < this.whitelabelAppearanceMaterial.length; i++){
            if(!appearanceName.includes(this.whitelabelAppearanceMaterial[i].appearance.name)){
                appearanceName.push(this.whitelabelAppearanceMaterial[i].appearance.name);
                this.appearanceList.push({"label": this.whitelabelAppearanceMaterial[i].appearance.name, "value": this.whitelabelAppearanceMaterial[i].appearance.name});                    
            }
            if(!materialName.includes(this.whitelabelAppearanceMaterial[i].material.name)){
                materialName.push(this.whitelabelAppearanceMaterial[i].material.name);
                this.materialList.push({"label": this.whitelabelAppearanceMaterial[i].material.name, "value": this.whitelabelAppearanceMaterial[i].material.name});                    
            }
        }
    }

    private loadAllAppearanceMaterialsByAdmin() {
      let url = "whitelabelAppearanceMaterials/retail/" + this.whitelabel.id;
        this.appearanceMaterialService.getAll(url, true).then(whitelabelAppearanceMaterial => {
            this.rowsPerPageOptions = undefined;
            if(whitelabelAppearanceMaterial['result'].length > 10 && whitelabelAppearanceMaterial['result'].length < 25){
                this.rowsPerPageOptions = [10, 25];
            }
            else if(whitelabelAppearanceMaterial['result'].length > 25){
                this.rowsPerPageOptions = [10, 25, whitelabelAppearanceMaterial['result'].length];
            }
            this.whitelabelAppearanceMaterial = whitelabelAppearanceMaterial['result'];
            this.allWhitelabelAppearanceMaterial = whitelabelAppearanceMaterial['result'];
            this.loadFilters();
        });
    }


    edit(appearanceMaterialWhitelabel: Whitelabel) {
        this.appearanceMaterialService.update("whitelabelAppearanceMaterials", appearanceMaterialWhitelabel).then(() => {
            this.loadAllAppearanceMaterialsByAdmin();
        }, (error) => {
            console.error(error);
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536', 
                allowOutsideClick: false
            });
            this.loadAllAppearanceMaterialsByAdmin();
        }); 
    }
 
    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit"); 
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }


    aparencias = [];
    materiais = [];
    all = [];
    filterObject(event, dt) {
        this.whitelabelAppearanceMaterial = this.allWhitelabelAppearanceMaterial;
        let tempObj: AppearanceMaterial[] = [];
        switch (dt) {
            case 'appearance':
                this.whitelabelAppearanceMaterial.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.appearance) === JSON.stringify(element)) {
                            tempObj.push(val);

                        }
                    });
                });
                this.aparencias = tempObj;
                break;
            case 'material':
                this.whitelabelAppearanceMaterial.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.material) === JSON.stringify(element)) {
                            tempObj.push(val);
                        }
                    });
                });
                this.materiais = tempObj;
                break;
                case 'global':
                        let ev = event.toUpperCase();
                        this.whitelabelAppearanceMaterial.forEach(val =>{
                                if((val.material.name.toUpperCase().includes(ev)) || (val.appearance.name.toUpperCase().includes(ev))){
                                    tempObj.push(val);
                                }
                        });
                    this.all = tempObj;
                }
                
        if(this.all.length != 0){
            this.whitelabelAppearanceMaterial = this.all;
        } else {
            if ((this.aparencias.length != 0) && (this.materiais.length != 0)) {
                this.whitelabelAppearanceMaterial = this.aparencias.filter(value => -1 !== this.materiais.indexOf(value));
            } else {
                if (this.aparencias.length == 0) {
                    this.whitelabelAppearanceMaterial = this.materiais;
                    if (this.materiais.length == 0) {
                        this.whitelabelAppearanceMaterial = this.allWhitelabelAppearanceMaterial;
                    }
                } else if (this.materiais.length == 0) {
                    this.whitelabelAppearanceMaterial = this.aparencias;
                    if (this.aparencias.length == 0) {
                        this.whitelabelAppearanceMaterial = this.allWhitelabelAppearanceMaterial;
                    }
                }
            }
        }
    }
}