import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DialogService, SelectItem } from "primeng/api";
import { MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { AppliedProduct } from './appliedProduct';
import { ProductApplication } from '../productApplication/productApplication';
import { Product } from '../product/product';
import { AppliedProductAddModalComponent } from './appliedProductAdd.modal';
import { AppliedProductEditModalComponent } from './appliedProductEdit.modal';
import { AppliedProductViewModalComponent } from './appliedProductView.modal';

@Component({
    templateUrl: 'appliedProduct.component.html',
    styleUrls: ['appliedProduct.component.css'],
    providers: [DialogService]
})
export class AppliedProductComponent implements OnInit {
    appliedProducts: AppliedProduct[] = [];
    items: MenuItem[]; 
    allAppliedProducts = [];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedItens: AppliedProduct[] = [];
    status: SelectItem[];
    binary: SelectItem[];
    binaryWithNull: SelectItem[];
    editmode = false;
    optionsThreePoints: MenuItem[];
    preexistences: any[];
    productApplications: ProductApplication[];
    products: Product[];
    consumptionObservationsFilter = null;
    externalLocationFilter = null;
    commercialUseFilter = null;
    waterResistantFilter = null;
    nonSlipFilter = '';
    coldFilter = '';
    scratchResistantFilter = '';
    stainResistantFilter = '';
    hasInclusionCriteriaFilter = null;
    productFilter = null;
    productApplicationFilter = null;
    complementaryApplication1Filter = null;
    complementaryApplication2Filter = null;
    complementaryApplication3Filter = null;
    statusFilter = null;
    updateFilter = null;
    preexistencesFilter = null;
    preexistenceList = [];

    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };

    constructor(
        private apiService: ApiService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private dialogService: DialogService
    ) {
        this.apiService.getAll("preexistences").then(preexistences => {
            this.preexistences = preexistences['result'];
        });
        this.apiService.getAll("productApplications").then(productApplications => {
            this.productApplications = productApplications['result'];
            this.productApplications.forEach(element => {
                delete element.productCategory;
            });
        });
        this.apiService.getAll("products").then(products => {
            this.products = products['result'];

            this.products.forEach(element => {
                delete element.color;
                delete element.productCategory;
                delete element.manufacturer;
                delete element.brand;
                delete element.standard;
                delete element.productProductSpecifics;
                delete element['packagedProducts'];
                delete element['appliedProduct'];
                delete element['hasCompleted'];
            });
        })
    }

    ngOnInit() {
        this.loadAllItemsByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.cols = [
            { orderCol: 'products.name', field: 'products', header: 'Produto' },
            { orderCol: 'productApplications.name', field: 'productApplications', header: 'Aplicação' },
            { orderCol: 'complementaryApplication1.name', field: 'complementaryApplication1', header: 'Aplicação Complementar 1' },
            { orderCol: 'complementaryApplication2.name', field: 'complementaryApplication2', header: 'Aplicação Complementar 2' },
            { orderCol: 'complementaryApplication3.name', field: 'complementaryApplication3', header: 'Aplicação Complementar 3' },
            { orderCol: 'externalLocation', field: 'externalLocation', header: 'Localização Externa' },
            { orderCol: 'commercialUse', field: 'commercialUse', header: 'Uso Comercial' },            
            { orderCol: 'waterResistant', field: 'waterResistant', header: 'Resistência a água' },
            { orderCol: 'nonSlip', field: 'nonSlip', header: 'Antiderrapante' },
            { orderCol: 'cold', field: 'cold', header: 'Frio' },
            { orderCol: 'scratchResistant', field: 'scratchResistant', header: 'Resistência a risco' },
            { orderCol: 'stainResistant', field: 'stainResistant', header: 'Resistência a Abrasão' },
            { orderCol: 'hasInclusionCriteria', field: 'hasInclusionCriteria', header: 'Tem Critério de Inclusão' },
            { orderCol: 'consumptionObservations', field: 'consumptionObservations', header: 'Observações internas' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'orderPreexistences', field: 'preexistences', header: 'Restrição às Preexistências' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { orderCol: 'name', field: 'name', header: 'Nome' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'products.name', field: 'products', header: 'Produto' },
                { orderCol: 'productApplications.name', field: 'productApplications', header: 'Aplicação' },
                { orderCol: 'orderPreexistences', field: 'preexistences', header: 'Restrição às Preexistências' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.binary = [
            { label: 'Sim ou Indiferente', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.binaryWithNull = [
            { label: '', value: 'null' },
            { label: 'Sim ou Indiferente', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.selectedColumns = this.colsToggle;
    }

    onRowSelect(event, row) {
        this.selectedItens.push(row);
    }

    clearFilters(dt) {
        this.consumptionObservationsFilter = '';
        this.externalLocationFilter = '';
        this.commercialUseFilter = '';
        this.waterResistantFilter = '';
        this.nonSlipFilter = '';
        this.coldFilter = '';
        this.scratchResistantFilter = '';
        this.stainResistantFilter = '';
        this.hasInclusionCriteriaFilter = '';
        this.productFilter = '';
        this.productApplicationFilter = null;
        this.complementaryApplication1Filter = null;
        this.complementaryApplication2Filter = null;
        this.complementaryApplication3Filter = null;
        this.statusFilter = '';
        this.updateFilter = '';
        this.preexistencesFilter = null;
        this.appliedProducts = this.allAppliedProducts;
        dt.reset();
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.apiService.delete("appliedProducts", id).then(() => {
                    this.loadAllItemsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    productList;
    productApplicationList;
    complementaryApplication1List;
    complementaryApplication2List;
    complementaryApplication3List;
    loadFilters() {
        let preexistenceListName = [];
        this.preexistenceList = [];
        let productApplicationName = [];
        let productName = [];
        let complementaryApplication1Name = [];
        let complementaryApplication2Name = [];
        let complementaryApplication3Name = [];
        this.productList = [];
        this.productApplicationList = [];
        this.complementaryApplication1List = [];
        this.complementaryApplication2List = [];
        this.complementaryApplication3List = [];
        
        for (var i = 0; i < this.appliedProducts.length; i++) {
            
            preexistenceListName.push([]);
            if (this.appliedProducts[i].preexistences.length) {
                for (var j = 0; j < this.appliedProducts[i].preexistences.length; j++) {
                    delete this.appliedProducts[i].preexistences[j].AppliedProductPreexistence;

                    if (!preexistenceListName[i].includes(this.appliedProducts[i].preexistences[j].name)) {
                        preexistenceListName[i].push(this.appliedProducts[i].preexistences[j].name);
                    }
                }

                var abc = JSON.stringify(preexistenceListName[i]);
                abc = abc.replace(/[\[\"{}\"\]]/g, '');
                if (!this.preexistenceList.some(pList => pList["label"] === abc)) {
                    this.preexistenceList.push({ "label": abc, "value": preexistenceListName[i] });
                }
                this.appliedProducts[i]['orderPreexistences'] = preexistenceListName[i];
            }

            if(!productApplicationName.includes(this.appliedProducts[i]['productApplications'].name)){
                productApplicationName.push(this.appliedProducts[i]['productApplications'].name);
                this.productApplicationList.push({"label": this.appliedProducts[i]['productApplications'].name, "value": this.appliedProducts[i]['productApplications'].name});
            }
            if(!productName.includes(this.appliedProducts[i]['products'].name)){
                productName.push(this.appliedProducts[i]['products'].name);
                this.productList.push({"label": this.appliedProducts[i]['products'].name, "value": this.appliedProducts[i]['products'].name});
            }
            if(this.appliedProducts[i]['complementaryApplication1']){
                if(!complementaryApplication1Name.includes(this.appliedProducts[i]['complementaryApplication1'].name)){
                    complementaryApplication1Name.push(this.appliedProducts[i]['complementaryApplication1'].name);
                    this.complementaryApplication1List.push({"label": this.appliedProducts[i]['complementaryApplication1'].name, "value": this.appliedProducts[i]['complementaryApplication1'].name});
                }
            }
            if(this.appliedProducts[i]['complementaryApplication2']){
                if(!complementaryApplication2Name.includes(this.appliedProducts[i]['complementaryApplication2'].name)){
                    complementaryApplication2Name.push(this.appliedProducts[i]['complementaryApplication2'].name);
                    this.complementaryApplication2List.push({"label": this.appliedProducts[i]['complementaryApplication2'].name, "value": this.appliedProducts[i]['complementaryApplication2'].name});
                }
            }
            if(this.appliedProducts[i]['complementaryApplication3']){
                if(!complementaryApplication3Name.includes(this.appliedProducts[i]['complementaryApplication3'].name)){
                    complementaryApplication3Name.push(this.appliedProducts[i]['complementaryApplication3'].name);
                    this.complementaryApplication3List.push({"label": this.appliedProducts[i]['complementaryApplication3'].name, "value": this.appliedProducts[i]['complementaryApplication3'].name});
                }
            }
        }
        this.productList.forEach(element => {
            delete element.productCategory;
        });
    }

    all = [];
    filterObject(event) {
        this.appliedProducts = this.allAppliedProducts;
        let tempObj: AppliedProduct[] = [];
        let ev = event.toUpperCase();
        this.appliedProducts.forEach(val => {
            if ((val.products.name.toUpperCase().includes(ev)) || (val.productApplications.name.toUpperCase().includes(ev))) {
                tempObj.push(val);
            }
        });
        this.all = tempObj;


        if (this.all.length != 0) {
            this.appliedProducts = this.all;
        }
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }


    private loadAllItemsByAdmin() {
        this.apiService.getAll("appliedProducts", true).then(appliedProducts => {
            this.appliedProducts = appliedProducts['result'];
            this.allAppliedProducts = appliedProducts['result'];
            this.loadFilters()
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllItemsByAdmin();
    }

    showAdd() {
        this.dialogService.open(AppliedProductAddModalComponent, {
            header: 'Cadastrar Produto Aplicado',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    showEdit(appliedProduct: AppliedProduct) {
        this.dialogService.open(AppliedProductEditModalComponent, {
            data: appliedProduct,
            header: 'Editar Produto Aplicado',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    showView(appliedProduct: AppliedProduct) {
        this.dialogService.open(AppliedProductViewModalComponent, {
            data: appliedProduct,
            header: 'Visualizar Produto Aplicado',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllItemsByAdmin();
        });
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedItens.forEach(intervention => {
            ids.push(intervention.id);
        });
        this.apiService.deleteAll("appliedProducts", { "ids": ids }).then(() => {
            this.selectedItens.length = 0;
            this.loadAllItemsByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536'
        });
    }

    edit(appliedProduct: AppliedProduct) {

        var listId = [];
        appliedProduct.preexistences.forEach(e => {
            listId.push(e.id);
        });

        appliedProduct.preexistences = listId;
        appliedProduct.productId = appliedProduct.products.id;
        
        appliedProduct.productApplicationId = appliedProduct.productApplications.id;
        if(appliedProduct.complementaryApplication1) appliedProduct.complementaryApplication1Id = appliedProduct.complementaryApplication1.id;
        if(appliedProduct.complementaryApplication2) appliedProduct.complementaryApplication2Id = appliedProduct.complementaryApplication2.id;
        if(appliedProduct.complementaryApplication3) appliedProduct.complementaryApplication3Id = appliedProduct.complementaryApplication3.id;
        this.apiService.update("appliedProducts", appliedProduct).then((a) => {
            this.loadAllItemsByAdmin();
        }, (error) => {
            var msgs = error.error['messages'];
            var message = '';
            msgs.forEach((m: string, index: number) => {
                message += m;
                if (index < msgs.length - 1) message += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: message,
                type: 'warning',
                confirmButtonColor: '#D95536'
            });
            this.loadAllItemsByAdmin();
        });
    }

    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");

        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table-edit");
                inputTable[i].classList.add("form-pro-table");
            }
        }
    }

}