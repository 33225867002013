import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-forgotPassword',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  recoveryForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private emailService: AuthenticationService
  ) { }

  ngOnInit() {
    this.recoveryForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.email])]
  });
  }

  get f() { return this.recoveryForm.controls; }

  onSubmit() {
    this.emailService.passwordRecovery(this.recoveryForm.value).subscribe( (sucess) => {
      swal({
        title: "E-mail enviado",
        text: "Um link para recuperação de senha foi enviado com sucesso.",
        icon: "success",

      }).then(
        () => {
          this.router.navigate(['/']);
        }
      );
      },
      (error) => {
        swal({
          title: "E-mail inválido",
          text: "Nenhum usuário encontrado para o e-mail informado",
          icon: "warning",
        });
      } );



  }

}
