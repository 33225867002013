import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from "src/app/services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { Globals } from 'src/app/globals';
import { Specification } from './specification';
import { ProductCategory } from '../productCategory/productCategory';
import { ProductApplication } from '../productApplication/productApplication';
import { ProductCategorySpecification } from '../productCategorySpecification/productCategorySpecification';

@Component({
    selector: 'app-specification',
    templateUrl: './specification.component.html',
    styleUrls: ['specification.component.css'],
    providers: [DialogService]
})
export class SpecificationComponent implements OnInit {

    @Input() combination: any;
    allSpecifications: Specification[] = [];
    nameIt: string;
    listItems: Specification[] = [];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedListItems: Specification[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    registerForm: FormGroup;
    optionsThreePoints: MenuItem[];
    category_list: ProductCategory[];
    category: ProductCategory;
    productApplication_list: ProductApplication[];
    productApplication: ProductApplication;
    productCategorySpecific: ProductCategorySpecification;
    filterValue: any;
    specific_list: ProductCategorySpecification[];
    ops: SelectItem[];
    equality: any = "=";
    specificSelect: SelectItem[] = [];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    productCategoryFilter = '';
    productCategorySpecificationFilter = ''
    productApplicationFilter = '';
    filterValueFilter = '';
    statusFilter = '';
    updateFilter = '';
    boolBif: boolean;
    boolFilt: boolean;
    constructor(
        private apiService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.apiService.getAll("productCategories", true).then(cat => {
            this.category_list = cat['result']
            this.category_list.forEach(element => {
                delete element['specifics'];
                delete element['hasCompleted'];
            });
        });
        // this.apiService.getAll("productApplications").then(ap => {
        //     this.productApplication_list = ap['result']
        //     this.productApplication_list.forEach(element => {
        //         delete element['productCategory'];
        //     });
        // });

    }

    ngOnInit() {
        this.globals.obj_combination = this.combination;
        this.boolBif = this.combination.group.type == 'Bifurcação' || this.combination.group.type == 'Neutro';
        if (this.combination.group.type == 'Bifurcação' || this.combination.group.type == 'Neutro') {
            document.getElementById('filtValue')['disabled'] = "true";
            document.getElementById('filtValueNum')['disabled'] = "true";
            document.getElementById('filtValueDrop')['disabled'] = "true";
        }
        this.loadAllListItemsByAdmin();

        this.registerForm = this.formBuilder.group({
            productCategory: ['', Validators.required],
            productCategorySpecification: [''],
            productApplication: ['', Validators.required],
            filterValue: [''],
        });

        this.cols = [
            // { field: 'equality', header: 'Símbolo' },
            { orderCol: 'productCategory.name', field: 'productCategory', header: 'Categoria Produto' },
            { orderCol: 'productCategorySpecification.name', field: 'productCategorySpecification', header: 'Campo Específico' },
            { orderCol: 'productApplication.name', field: 'productApplication', header: 'Aplicação Produto' },
            { orderCol: 'valueFilter', field: 'valueFilter', header: 'Valor' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];


        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                // { field: 'equality', header: 'Símbolo' },
                { orderCol: 'productCategory.name', field: 'productCategory', header: 'Categoria Produto' },
                { orderCol: 'valueFilter', field: 'valueFilter', header: 'Valor' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
            ];
        }
        else {
            this.colsToggle = [
                // { field: 'equality', header: 'Símbolo' },
                { orderCol: 'productCategory.name', field: 'productCategory', header: 'Categoria Produto' },
                { orderCol: 'productCategorySpecification.name', field: 'productCategorySpecification', header: 'Campo Específico' },
                { orderCol: 'productApplication.name', field: 'productApplication', header: 'Aplicação Produto' },
                { orderCol: 'valueFilter', field: 'valueFilter', header: 'Valor' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
            ];
        }

        this.selectedColumns = this.colsToggle;

    }

    get f() {
        return this.registerForm.controls;
    }


    onRowSelect(event, row) {
        this.selectedListItems = row;
    }

    loadSpecifics(at) {
        this.specific_list = []; 
        this.productApplication_list = [];
        this.filterValue = null;
        this.specificSelect = null;
        if(at != 'notNull') this.productApplication = null;
        //Esta pegando a aplicação produto dado o ID da categoria. (é o certo, deve ser corrigido no BE o nome do endpoint)
        this.apiService.listChildByEntityId(this.category.id, 'getProductApplicationByProductCategoryId/').then(aps =>{
            this.productApplication_list = aps['result'];
        });
        this.apiService.listChildByEntityId(this.category.id, 'productCategories/specificsByCategoryId/').then(specifics => {
            this.specific_list = specifics['result'];
        });
    }

    loadFields() {
        this.specificSelect = [];
        if (this.productCategorySpecific.specificationType == "Lista") {
            this.productCategorySpecific['productCategorySpecificationList'].forEach(element => {
                this.specificSelect.push({ "label": element.name, "value": element.name });
            });
            document.getElementById('filtValue').style.display = "none";
            document.getElementById('filtValueNum').style.display = "none";
            document.getElementById('filtValueDrop').style.display = "initial"
        } else if(this.productCategorySpecific.specificationType == 'Texto'){
            document.getElementById('filtValue').style.display = "initial";
            document.getElementById('filtValueNum').style.display = "none";
            document.getElementById('filtValueDrop').style.display = "none"
        } else {
            document.getElementById('filtValue').style.display = "none";
            document.getElementById('filtValueNum').style.display = "initial";
            document.getElementById('filtValueDrop').style.display = "none"
        }
    }

    all = [];
    filterObject(event) {
        this.globals.specifications = this.allSpecifications;
        let tempObj: Specification[] = [];
        let ev = event.toUpperCase();
        this.globals.specifications.forEach(val => {
            if ((val.productCategory.name.toUpperCase().includes(ev)) || (val.productApplication.name.toUpperCase().includes(ev)) || ( val['valueFilter'] && val['valueFilter'].toUpperCase().includes(ev))   
            || ( val['productCategorySpecification'] && val['productCategorySpecification'].name.toUpperCase().includes(ev))) {
                tempObj.push(val);
            }
        });
        this.all = tempObj;


        if (this.all.length != 0) {
            this.globals.specifications = this.all;
        }
    }


    clearFilters(dt) {
        this.productCategoryFilter = '';
        this.productCategorySpecificationFilter = ''
        this.productApplicationFilter = '';
        this.filterValueFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        dt.reset();
    }

    productApplicationList;
    productCategoryList;
    loadFilters() {
        let productCategoryName = [];
        let productApplicationName = [];
        this.productCategoryList = [];
        this.productApplicationList = [];
        for (var i = 0; i < this.globals.specifications.length; i++) {
            if (!productApplicationName.includes(this.globals.specifications[i].productApplication.name)) {
                productApplicationName.push(this.globals.specifications[i].productApplication.name);
                this.productApplicationList.push({ "label": this.globals.specifications[i].productApplication.name, "value": this.globals.specifications[i].productApplication.name })
            }
            if (!productCategoryName.includes(this.globals.specifications[i].productCategory.name)) {
                productCategoryName.push(this.globals.specifications[i].productCategory.name);
                this.productCategoryList.push({ "label": this.globals.specifications[i].productCategory.name, "value": this.globals.specifications[i].productCategory.name })
            }
        }
    }

    options(row: any) {
        this.optionsThreePoints = [
            // { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.apiService.delete("specifications", id).then(() => {
                    this.loadAllListItemsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail(error);
                    });
            }
        })
    }

    closeModal() {
        this.ref.close();
        document.getElementById('filtValue').style.display = "initial";
        document.getElementById('filtValueNum').style.display = "none";
        document.getElementById('filtValueDrop').style.display = "none"
    }

    ;
    async checkFiltItem(){
        this.boolFilt = false;
        if(this.combination.group.type == 'Filtro'){
            for(let i = 0; i < this.globals.combination_answers.length; i++){
                await this.apiService.listChildByEntityId(this.globals.combination_answers[i].combinationId, 'specifications/getSpecificationByCombinationId/').then(item => {
                    if(item['result'].length > 0) {
                        this.boolFilt = true;
                        this.globals.specification = item['result'];
                    }
                });
            }
        }
        
        if(this.boolFilt){
            this.productApplication = this.globals.specification[0].productApplication;
            this.category = this.globals.specification[0].productCategory;
            this.loadSpecifics('notNull');
        } else {
            this.productApplication = null;
            this.category = null;
        }
    }

    async loadAllListItemsByAdmin() {
        this.apiService.listChildByEntityId(this.combination.combinationId, 'specifications/getSpecificationByCombinationId/').then(list => {
            this.globals.specifications = list['result'];
            this.allSpecifications = list['result'];
            this.loadFilters();


            // this.boolFilt = (this.globals.specifications.length > 0) && (this.combination.group.type == 'Filtro');
            this.checkFiltItem();
        });
    }

    showAdd() {
        document.getElementById('btn-add-specification').style.display = 'none'
    }

    registerListItem() {
        let objReg = this.registerForm.value;
        objReg.combinationId = this.combination.combinationId;
        objReg.productApplicationId = this.productApplication.id;
        if (this.filterValue)
            objReg.valueFilter = this.filterValue;
        if (this.productCategorySpecific)
            objReg.productCategorySpecificationId = this.productCategorySpecific.id;
        objReg.productCategoryId = this.category.id;
        this.apiService.register("specifications", objReg)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.registerForm.reset();
                            this.productApplication_list = [];
                            document.getElementById('filtValue').style.display = "initial";
                            document.getElementById('filtValueNum').style.display = "none";
                            document.getElementById('filtValueDrop').style.display = "none";
                            this.loadAllListItemsByAdmin();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                });
    }

    close() {
        this.nameIt = null;
        document.getElementById('btn-add-specification').style.display = 'initial';
        document.getElementById('filtValue').style.display = "initial";
        document.getElementById('filtValueNum').style.display = "none";
        document.getElementById('filtValueDrop').style.display = "none"
        this.productCategorySpecific = null;
        this.filterValue = null;
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedListItems.forEach(user => {
            ids.push(user.id);
        });
        this.apiService.deleteAll("specifications", { "ids": ids }).then(() => {
            this.selectedListItems.length = 0;
            this.loadAllListItemsByAdmin();
        }, (error) => {
            this.swalDeleteFail(error);
        });
    }

    swalDeleteFail(er) {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: er.error.messages,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: Specification) {
        this.apiService.register("inclusionConditions", { id: row['id'], "equality": this.makeIdToDuplicateRow(5) + row['equality'], "isVisible": false }).then(() => {
            this.loadAllListItemsByAdmin();
        },
            (er) => {
                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            }
        );
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllListItemsByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(listItem: Specification) {
        this.apiService.update("specifications", listItem).then(() => {
            this.loadAllListItemsByAdmin()
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllListItemsByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }
}
