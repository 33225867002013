import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

import { Ambience } from './ambience';
import { ApiService } from '../../services/api.service';

@Component({
  templateUrl: './ambienceView.modal.html',
})
export class AmbienceViewModalComponent implements OnInit {
  ambience: Ambience[];
  viewForm: FormGroup;
  loading = false;
  submitted = false;
  useTypeOptions: [{ label: 'Residencial', value: 0 }, { label: 'Comercial', value: 1 }];
  localTypeOptions = [{ label: 'Interna', value: 0 }, { label: 'Externa', value: 1 }];
  ambienceTypeOptions = [{ label: 'Seco', value: 0 }, { label: 'Molhado', value: 1 }];
  propertiesOptions = [{ label: 'Não', value: 0 }, { label: 'Sim', value: 1 }];

  constructor(
    private ambienceService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.ambience = this.config.data;
    this.viewForm = this.formBuilder.group({
      name: [{ value: null, disabled: true }],
      waterResistant: [{ value: null, disabled: true }],
      stainResistant: [{ value: null, disabled: true }],
      scratchResistant: [{ value: null, disabled: true }],
      nonSlip: [{ value: null, disabled: true }],
      cold: [{ value: null, disabled: true }],
      useType: [{ value: null, disabled: true }],
      ambienceType: [{ value: null, disabled: true }],
      localType: [{ value: null, disabled: true }]
    });
  }

  close() {
    this.ref.close();
  }

}
