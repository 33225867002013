import 'core-js/es6/reflect';
import 'core-js/es7/reflect';
import 'zone.js/dist/zone';

// aws
(window as any).global = window;


(window as any).process = {
  env: { DEBUG: undefined },
};
