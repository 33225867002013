import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api.service';
import { Question } from './question';

@Component({
  templateUrl: './questionEdit.modal.html'
})
export class QuestionEditModalComponent implements OnInit {
  question: Question[];
  updateForm: FormGroup;
  loading = false;
  optionsType: SelectItem[];
  submitted = false;

  constructor(
    private questionService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.question = this.config.data;
    this.updateForm = this.formBuilder.group({
      query: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
      interjectionQuestion: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])]
    });

    this.optionsType = [
      { label: 'Filtro', value: 'Filtro' },
      { label: 'Bifurcação', value: 'Bifurcação' }
    ]
  }
  get f() {
    return this.updateForm.controls;
  }

  onSubmit(question: Question) {
    this.loading = true;
    this.questionService.update("questions", question)
      .then(
        () => {
          Swal.fire({
            title: 'Edição realizada com sucesso!',
            type: 'success',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              this.ref.close();
            }
          })
        },
        (error) => {
          var errorMsg = error.error.messages.toString();
          errorMsg = errorMsg.replace(/,/g, '');
          Swal.fire({
            title: 'Edição Não Realizada!',
            text: errorMsg,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          });
          this.loading = false;
        });
  }

  close() {
    this.ref.close();
  }
}
