import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertFlag'
})
export class ConvertFlagPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log("value", value);
    if(value == 'floor') {
      return 'Piso';
    } else {
      return 'Parede';
    }
  }

}
