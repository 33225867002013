import { Component, OnInit } from '@angular/core';
import { ApiService } from "src/app/services/api.service";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { Whitelabel } from './whitelabel';
import { WhitelabelApperanceMaterialComponent } from '../whitelabel-apperance-material/whitelabel-apperance-material.component';
import { WhitelabelMaterialComponent } from '../whitelabel-material/whitelabel-material.component';
import { WhitelabelEnvironmentComponent } from '../whitelabel-environment/whitelabel-environment.component';
import { WhitelabelAddModalComponent } from './whitelabelAdd.modal';

@Component({
  selector: 'app-whitelabel',
  templateUrl: './whitelabel.component.html',
  styleUrls: ['./whitelabel.component.css'],
  providers: [DialogService]
})
export class WhitelabelComponent implements OnInit {


    whitelabels: Whitelabel[] = [];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedWhitelabels: Whitelabel[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    statusFilter = '';
    updateFilter = '';
    rowsPerPageOptions;

    constructor(
        private userService: ApiService,
        
        private whitelabelService: ApiService,
        
        private dialogService: DialogService
    ) {

    }

    ngOnInit() {
        this.userService.getById("users", localStorage.getItem('currentUserId')).then((cur) => {
                this.cr = cur['result'];
                this.loadAllWhitelabelsByAdmin();
            });

        this.cols = [
            { field: 'name', header: 'Nome' },
            { field: 'material', header: 'Material' },
            { field: 'appearanceMaterial', header: 'Aparência-Material' },
            { field: 'environment', header: 'Ambientação' },
            { field: 'isVisible', header: 'Status' }
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'material', header: 'Material' },
                { field: 'appearanceMaterial', header: 'Aparência-Material' },
                { field: 'environment', header: 'Ambientação' },
                { field: 'isVisible', header: 'Status' }
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    onRowSelect(event, row) {
        this.selectedWhitelabels = row;
    }


    clearFilters(dt) {
        this.nameFilter = '';
        this.statusFilter = '';
        dt.reset();
    }

    showAdd() {
        this.dialogService.open(WhitelabelAddModalComponent, {
            header: 'Cadastrar Whitelabel',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllWhitelabelsByAdmin();
        });
    }

    openRelation( col: string,whitelabel: any){
      if(col === 'appearanceMaterial'){
          this.dialogService.open(WhitelabelApperanceMaterialComponent, {
            data: whitelabel,
            header: `Whitelabel - Aparência-Material do varejista:${whitelabel.name}`,
            width: '95vw',
            contentStyle: { "max-height": "90vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllWhitelabelsByAdmin();
        });
      } else if (col === 'material'){
        this.dialogService.open(WhitelabelMaterialComponent, {
            data: whitelabel,
            header: `Whitelabel - Material do varejista:${whitelabel.name}`,
            width: '95vw',
            contentStyle: { "max-height": "90vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllWhitelabelsByAdmin();
        });
      } else if (col === 'environment'){
        this.dialogService.open(WhitelabelEnvironmentComponent, {
            data: whitelabel,
            header: `Whitelabel - Ambientação do varejista:${whitelabel.name}`,
            width: '95vw',
            contentStyle: { "max-height": "90vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllWhitelabelsByAdmin();
        });
      }
    }

    private loadAllWhitelabelsByAdmin() {
        this.whitelabelService.getAll("whitelabels", true).then(whitelabels => {
            this.rowsPerPageOptions = undefined;
            if(whitelabels['result'].length > 10 && whitelabels['result'].length < 25){
                this.rowsPerPageOptions = [10, 25];
            }
            else if(whitelabels['result'].length > 25){
                this.rowsPerPageOptions = [10, 25, whitelabels['result'].length];
            }
            whitelabels['result'].map(whitelabel => {
              whitelabel.name = "";
              whitelabel.retail.forEach(ret => {
                whitelabel.name += ret.name + ' - ';
              });
              return whitelabel;
            })
            this.whitelabels = whitelabels['result'];
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(whitelabel: Whitelabel) {
        this.whitelabelService.update("whitelabels", whitelabel).then(() => {
            this.loadAllWhitelabelsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllWhitelabelsByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }
}
