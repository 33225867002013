import { Component, OnInit } from '@angular/core';
import { ApiService } from "src/app/services/api.service";
import { DynamicDialogRef, MenuItem, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { DialogService } from "primeng/api";
import Swal from 'sweetalert2';
import { Whitelabel } from '../whitelabel/whitelabel';
import { Material } from '../material/material';

@Component({
  selector: 'app-whitelabel-material',
  templateUrl: './whitelabel-material.component.html',
  styleUrls: ['./whitelabel-material.component.css'],
    providers: [DialogService]
})
export class WhitelabelMaterialComponent implements OnInit {
  whitelabelMaterials: Material[] = [];
    cr: any;
    cols: any[];
    items: MenuItem[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedItens: Material[] = [];
    roles: SelectItem[];
    status: SelectItem[];
    floorWall: SelectItem[];
    binary: SelectItem[];
    envBinary: SelectItem[];
    usageBinary: SelectItem[];
    editmode = false;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    statusFilter = '';
    updateFilter = '';
    
    rowsPerPageOptions;
    whitelabel: Whitelabel;

    constructor(
      private materialService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
    ) {
      
    }

    ngOnInit() {
        this.whitelabel = this.config.data;
        
        this.loadAllItemsByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.cols = [
            { field: 'name', header: 'Nome' },
            { field: 'isVisible', header: 'Status' },
            { field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'isVisible', header: 'Status' },
                { field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    clearFilters(dt) {
        this.nameFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        
        dt.reset();
    }

    private loadAllItemsByAdmin() {
      let url = "whitelabelMaterials/retail/" + this.whitelabel.id;
        this.materialService.getAll(url, true).then(whitelabelMaterials => {
            this.rowsPerPageOptions = undefined;
            if(whitelabelMaterials['result'].length > 10 && whitelabelMaterials['result'].length < 25){
                this.rowsPerPageOptions = [10, 25];
            }
            else if(whitelabelMaterials['result'].length > 25){
                this.rowsPerPageOptions = [10, 25, whitelabelMaterials['result'].length];
            }
            this.whitelabelMaterials = whitelabelMaterials['result'];
        });
    }
    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllItemsByAdmin();
    }

    edit(material: Whitelabel) {
        this.materialService.update("whitelabelMaterials", material).then(() => {
            this.loadAllItemsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllItemsByAdmin();
        });
    }

    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");

        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table-edit");
                inputTable[i].classList.add("form-pro-table");
            }
        }
    }
}
