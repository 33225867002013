import { Component, OnInit } from '@angular/core';
import { DialogService, SelectItem, MenuItem, DynamicDialogConfig } from "primeng/api";
import { ApiService } from 'src/app/services/api.service';
import { Store } from 'src/app/entities/store/store';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { StoreEditModalComponent } from './storeEdit.modal';
import { StoreViewModalComponent } from './storeView.modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Retail } from '../retail/retail';


@Component({
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.css'],
    providers: [DialogService]
})
export class StoreComponent implements OnInit {
    [x: string]: any;
    states: SelectItem[];
    searchTypeOptions: SelectItem[];
    activated: boolean;
    stores: Store[] = [];
    store_pass: Store;
    name: string;
    searchType: string;
    cr: any;
    cols: any[];
    colsToggle: any[];
    cep;
    retail: Retail;
    registerForm: FormGroup;
    selectedColumns: any[];
    selectedStores: Store[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };


    constructor(
        private dialogService: DialogService,
        private storeService: ApiService,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder
    ) {

    }

    ngOnInit() {
        this.activated = false;
        this.retail = this.config.data;
        this.storeService.getById("users", localStorage.getItem('currentUserId')).then((cur) => {
                this.cr = cur['result'];
                this.loadAllStoresByAdmin();
            });

        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            cnpj: [null, Validators.compose([Validators.minLength(18), Validators.maxLength(18), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            municipalRegistration: ['', Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            stateRegistration: ['', Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            telephone: [null, Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            address: [null, Validators.compose([Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            site: [null, Validators.compose([Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            facebook: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            instagram: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            contactName: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            contactEmail: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.email, Validators.pattern(/^((?!\s{2,}).)*$/)])],
            contactTelephone: [null, Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            searchType: [null, Validators.required],
            state: [null],
            cep: [null]
        }); 

        this.cols = [
            { field: 'name', header: 'Nome' },
            { field: 'cnpj', header: 'Cnpj' },
            { field: 'stateRegistration', header: 'Inscrição Estadual' },
            { field: 'municipalRegistration', header: 'Inscrição Municipal' },
            { field: 'site', header: 'Site' },
            { field: 'facebook', header: 'Facebook' },
            { field: 'instagram', header: 'Intagram' },
            { field: 'address', header: 'Endereço' },
            { field: 'telephone', header: 'Telefone' },
            { field: 'contactName', header: 'Nome do Contato' },
            { field: 'contactEmail', header: 'Email do Contato' },
            { field: 'contactTelephone', header: 'Telefone do Contato' }, 
            { field: 'packagedProducts', header: 'Produtos Embalados' },
            { field: 'searchType', header: 'SearchType' },
            { field: 'isVisible', header: 'Status' },
            { field: 'state', header: 'Estado de atendimento'},
            { field: 'cep', header: 'CEP de atendimento'},
            { field: 'updatedAt', header: 'Modificado' }
        ];

        this.searchTypeOptions = [
            { label: 'N - Não buscar', value: 'N' },
            { label: 'C - Código de Barras', value: 'C' },
            { label: 'P - URL', value: 'P' }
        ]

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.states = [
            { label: '', value: null },
            {label: 'Acre', value:"AC"},
            {label: 'Alagoas', value:"AL"},
            {label: 'Amapá', value:"AP"},
            {label: 'Amazonas', value:"AM"},
            {label: 'Bahia', value:"BA"},
            {label: 'Ceará', value:"CE"},
            {label: 'Distrito Federal', value:"DF"},
            {label: 'Espirito Santo', value:"ES"},
            {label: 'Goiás', value:"GO"},
            {label: 'Maranhão', value:"MA"},
            {label: 'Mato Grosso', value:"MT"},
            {label: 'Mato Grosso do Sul', value:"MS"},
            {label: 'Minas Gerais', value:"MG"},
            {label: 'Pará', value:"PA"},
            {label: 'Paraíba', value:"PB"},
            {label: 'Paraná', value:"PR"},
            {label: 'Pernambuco', value:"PE"},
            {label: 'Piauí', value:"PI"},
            {label: 'Rio de Janeiro', value:"RJ"},
            {label: 'Rio Grande do Norte', value:"RN"},
            {label: 'Rio Grande do Sul', value:"RS"},
            {label: 'Rondônia', value:"RO"},
            {label: 'Roraima', value:"RR"},
            {label: 'Santa Catarina', value:"SC"},
            {label: 'São Paulo', value:"SP"},
            {label: 'Sergipe', value:"SE"},
            {label: 'Tocantins', value:"TO"},
            {label: 'Estrangeiro', value:"EX"}
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'cnpj', header: 'Cnpj' },
                { field: 'telephone', header: 'Telefone' }, 
                { field: 'packagedProducts', header: 'Produtos Embalados' },   
                { field: 'state', header: 'Estado de atendimento'},     
                { field: 'searchType', header: 'SearchType' },                   
                { field: 'isVisible', header: 'Status' },
                { field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    get f() {
        return this.registerForm.controls;
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    showAdd() {
        document.getElementById('btns-mng').style.display = 'none'
    }

    close() {
        this.name = null;
        this.cnpj = null;
        this.municipalRegistration = null;
        this.stateRegistration = null;
        this.telephone = null;
        this.address = null;
        this.site = null;
        this.facebook = null;
        this.instagram = null;
        this.contactName = null;
        this.contactEmail = null;
        this.contactTelephone = null;
        this.searchType = null;
        document.getElementById('btns-mng').style.display = 'initial';
    }

    showView(store: Store) {
        this.dialogService.open(StoreViewModalComponent, {
            data: store,
            header: 'Visualizar Loja',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllStoresByAdmin();
        });
    }

    showEdit(store: Store) {
        this.dialogService.open(StoreEditModalComponent, {
            data: store,
            header: 'Editar Loja',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllStoresByAdmin();
        });
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida! A deleção impactará no módulo de Loja!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.storeService.delete("stores", id).then(() => {
                    this.loadAllStoresByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedStores.forEach(user => {
            ids.push(user.id);
        });
        this.storeService.deleteAll("stores", { "ids": ids }).then(() => {
            this.selectedStores.length = 0;
            this.loadAllStoresByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    private loadAllStoresByAdmin() {
        this.storeService.listChildByEntityId(this.retail.id, '/retails/storesByRetail/').then((stores) => {
            this.stores = stores['result'];
        })
    }

    registerStore() {
        let objReg = this.registerForm.value;
        console.log("objReg", objReg);
        objReg.retailId = this.retail.id;
        this.storeService.register("stores", objReg)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.registerForm.reset();
                            //document.getElementById('site')['value'] = '';
                            document.getElementById('name')['value'] = '';
                            this.loadAllStoresByAdmin();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                });
    }

    edit(store: Store) {
        this.storeService.update("stores", store).then(() => {
            this.loadAllStoresByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllStoresByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllStoresByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    showPackagedProducts(row: any){
        document.getElementById('packProduc').style.display = 'initial';
        document.getElementById('spSt').style.display = 'initial';
        document.getElementById('st').style.display = 'initial';
        document.getElementById('stor').style.display = 'none';

        this.config.header = 'Produtos Embalados da Loja: ' + row.name;
        this.store_pass = row;
        this.activated = !this.activated;
    }

    backStore(){
        document.getElementById('packProduc').style.display = 'none';
        document.getElementById('spSt').style.display = 'none';
        document.getElementById('st').style.display = 'none';
        document.getElementById('stor').style.display = 'initial';
        this.config.header = 'Lojas do Varejo: ' + this.retail.name;
        this.loadAllStoresByAdmin();
        this.activated = !this.activated;
    }

}