import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from "src/app/services/api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { S3Service } from 'src/app/services/s3.service';
import { AppliedProduct } from './appliedProduct';
import { Product } from '../product/product';
import { ProductApplication } from '../productApplication/productApplication';
import { PreExistence } from '../preexistence/preexistence';
import { Globals } from 'src/app/globals';

@Component({
    templateUrl: './appliedProduct.modal.html',
    styleUrls: ['appliedProduct.component.css'],
    providers: [DialogService]
})
export class AppliedProductModalComponent implements OnInit {

    activated = false;
    activated_consumption = false;
    activated2 = false;
    appliedProduct_pass: AppliedProduct;
    product: Product;
    productApplication;
    externalLocation: any;
    selectedPreexistences: PreExistence[] = [];
    commercialUse: any;
    consumptionObservations: any;
    waterResistant: any;
    nonSlip: any;
    scratchResistant: any;
    stainResistant: any;
    cold: any;
    hasInclusionCriteria: any;
    complementaryApplication1: ProductApplication;
    complementaryApplication2: ProductApplication;
    complementaryApplication3: ProductApplication;
    appliedProducts: AppliedProduct[] = [];
    cr: any;
    ac: boolean = false;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedAppliedProducts: AppliedProduct[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    binary: SelectItem[];
    binaryWithoutNull: SelectItem[];
    registerForm: FormGroup;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    preexistences: PreExistence[];
    products: Product[];
    productApplications: ProductApplication[];
    productApplicationsComp: ProductApplication[];
    consumptionObservationsFilter = null;
    externalLocationFilter = null;
    commercialUseFilter = null;
    waterResistantFilter = null;
    nonSlipFilter = '';
    coldFilter = '';
    scratchResistantFilter = '';
    stainResistantFilter = '';
    hasInclusionCriteriaFilter = null;
    productFilter = null;
    productApplicationFilter = null;
    complementaryApplication1Filter = null;
    complementaryApplication2Filter = null;
    complementaryApplication3Filter = null;
    statusFilter = null;
    updateFilter = null;
    preexistencesFilter = null;
    tempUrl: any;
    allAppliedProducts = [];
    apComp = [];
    constructor(
        private apiService: ApiService,
        private router: Router,
        private dialogService: DialogService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private globals: Globals
    ) {
        this.apiService.getAll("preexistences").then(result => this.preexistences = result['result']);
        this.apiService.getAll("products", true).then(result => this.products = result['result']);
        // this.apiService.getAll("productApplications").then(productApplications => {
        //     this.productApplications = productApplications['result'];
        //     this.apComp = [];
        //     this.productApplications.forEach(element => {
        //         delete element.productCategory;
        //     });
        //     this.apComp.push({"name": null, "id": null});
        //     this.productApplications.forEach(el =>{
        //         this.apComp.push({"name": el.name, "id":el.id});
        //     })

        // });
    }

    ngOnInit() {
        this.product = this.config.data;

        this.apiService.listChildByEntityId(this.product.productCategoryId, 'getProductApplicationByProductCategoryId/').then(aps => {
            this.productApplications = aps['result'];
            this.apComp = [];
            this.productApplications.forEach(element => {
                delete element.productCategory;
            });
            // this.productApplications.forEach(el =>{
            //     this.apComp.push({"name": el.name, "id":el.id});
            // })
        });

        this.apiService.getAll("productApplications").then(result => {
            result['result'].splice(0, 0, { createdAt: null, id: null, isVisible: null, name: null, productCategoryId: null, updatedAt: null })

            this.productApplicationsComp = result['result'];
            this.productApplicationsComp.forEach(element => {
                delete element.productCategory;
            });
            this.apComp.push({ "name": null, "id": null });
            this.productApplicationsComp.forEach(el => {
                this.apComp.push({ "name": el.name, "id": el.id });
            });
            console.log("this.productApplicationsComp", this.productApplicationsComp);
        });

        this.globals.activated_crit = false;
        this.globals.activated_cond = false;
        this.globals.activated_crit_consumption = false;
        this.globals.activated_cond_consumption = false;
        this.activated = false;

        this.waterResistant = 'Não';
        this.externalLocation = 'Não';
        this.commercialUse = 'Não';
        this.hasInclusionCriteria = 'Sim';
        this.nonSlip = 'Não';
        this.scratchResistant = 'Não';
        this.stainResistant = 'Não'
        this.cold = 'Não';

        this.loadAllAppliedProductsByAdmin();

        this.registerForm = this.formBuilder.group({
            productApplication: ['', Validators.required],
            externalLocation: ['Não'],
            commercialUse: ['Não'],
            waterResistant: ['Não'],
            nonSlip: ['Não'],
            scratchResistant: ['Não'],
            stainResistant: ['Não'],
            cold:  ['Não'],
            hasInclusionCriteria: ['Sim'],
            consumptionObservations: ['', Validators.pattern(/^((?!\s{2,}).)*$/)],
            complementaryApplication1: [''],
            complementaryApplication2: [''],
            complementaryApplication3: [''],
            preexistences: ['']
        });
 
        this.cols = [
            { orderCol: 'productApplications.name', field: 'productApplications', header: 'Aplicação' },
            { orderCol: 'inclusionCriterias', field: 'inclusionCriterias', header: 'Critério de Inclusão' },
            { orderCol: 'consumptionCriterias', field: 'consumptionCriterias', header: 'Critério de Consumo' },
            { orderCol: 'complementaryApplication1.name', field: 'complementaryApplication1', header: 'Aplicação Produto Complementar 1' },
            { orderCol: 'complementaryApplication2.name', field: 'complementaryApplication2', header: 'Aplicação Produto Complementar 2' },
            { orderCol: 'complementaryApplication3.name', field: 'complementaryApplication3', header: 'Aplicação Produto Complementar 3' },
            { orderCol: 'externalLocation', field: 'externalLocation', header: 'Localização Externa' },
            { orderCol: 'commercialUse', field: 'commercialUse', header: 'Uso Comercial' },
            { orderCol: 'waterResistant', field: 'waterResistant', header: 'Resistência a água' },
            { orderCol: 'nonSlip', field: 'nonSlip', header: 'Antiderrapante' },
            { orderCol: 'cold', field: 'cold', header: 'Frio' },
            { orderCol: 'scratchResistant', field: 'scratchResistant', header: 'Resistência a risco' },
            { orderCol: 'stainResistant', field: 'stainResistant', header: 'Resistência a Abrasão' },
            { orderCol: 'hasInclusionCriteria', field: 'hasInclusionCriteria', header: 'Tem Critério de Inclusão' },
            { orderCol: 'consumptionObservations', field: 'consumptionObservations', header: 'Observações internas' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'orderPreexistences', field: 'preexistences', header: 'Restrição às Preexistências' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { orderCol: 'name', field: 'name', header: 'Nome' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'productApplications.name', field: 'productApplications', header: 'Aplicação' },
                { orderCol: 'inclusionCriterias', field: 'inclusionCriterias', header: 'Critério de Inclusão' },
                { orderCol: 'consumptionCriterias', field: 'consumptionCriterias', header: 'Critério de Consumo' },
                { orderCol: 'orderPreexistences', field: 'preexistences', header: 'Restrição às Preexistências' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.binary = [
            { label: '', value: null },
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.binaryWithoutNull = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];



        this.selectedColumns = this.colsToggle;
    }

    get f() {
        return this.registerForm.controls;
    }


    onRowSelect(event, row) {
        this.selectedAppliedProducts = row;
    }

    productList;
    productApplicationList;
    preexistenceList = [];
    complementaryApplication1List;
    complementaryApplication2List;
    complementaryApplication3List;
    loadFilters() {
        let preexistenceListName = [];
        this.preexistenceList = [];
        let productApplicationName = [];
        let productName = [];
        let complementaryApplication1Name = [];
        let complementaryApplication2Name = [];
        let complementaryApplication3Name = [];
        this.productList = [];
        this.productApplicationList = [];
        this.complementaryApplication1List = [];
        this.complementaryApplication2List = [];
        this.complementaryApplication3List = [];

        for (var i = 0; i < this.appliedProducts.length; i++) {

            preexistenceListName.push([]);
            if (this.appliedProducts[i].preexistences.length) {
                for (var j = 0; j < this.appliedProducts[i].preexistences.length; j++) {
                    delete this.appliedProducts[i].preexistences[j].AppliedProductPreexistence;

                    if (!preexistenceListName[i].includes(this.appliedProducts[i].preexistences[j].name)) {
                        preexistenceListName[i].push(this.appliedProducts[i].preexistences[j].name);
                    }
                }

                var abc = JSON.stringify(preexistenceListName[i]);
                abc = abc.replace(/[\[\"{}\"\]]/g, '');
                if (!this.preexistenceList.some(pList => pList["label"] === abc)) {
                    this.preexistenceList.push({ "label": abc, "value": preexistenceListName[i] });
                }
                this.appliedProducts[i]['orderPreexistences'] = preexistenceListName[i];
            }

            if (!productApplicationName.includes(this.appliedProducts[i]['productApplications'].name)) {
                productApplicationName.push(this.appliedProducts[i]['productApplications'].name);
                this.productApplicationList.push({ "label": this.appliedProducts[i]['productApplications'].name, "value": this.appliedProducts[i]['productApplications'].name });
            }
            if (!productName.includes(this.appliedProducts[i]['products'].name)) {
                productName.push(this.appliedProducts[i]['products'].name);
                this.productList.push({ "label": this.appliedProducts[i]['products'].name, "value": this.appliedProducts[i]['products'].name });
            }
            if (this.appliedProducts[i]['complementaryApplication1']) {
                if (!complementaryApplication1Name.includes(this.appliedProducts[i]['complementaryApplication1'].name)) {
                    complementaryApplication1Name.push(this.appliedProducts[i]['complementaryApplication1'].name);
                    this.complementaryApplication1List.push({ "label": this.appliedProducts[i]['complementaryApplication1'].name, "value": this.appliedProducts[i]['complementaryApplication1'].name });
                }
            }
            if (this.appliedProducts[i]['complementaryApplication2']) {
                if (!complementaryApplication2Name.includes(this.appliedProducts[i]['complementaryApplication2'].name)) {
                    complementaryApplication2Name.push(this.appliedProducts[i]['complementaryApplication2'].name);
                    this.complementaryApplication2List.push({ "label": this.appliedProducts[i]['complementaryApplication2'].name, "value": this.appliedProducts[i]['complementaryApplication2'].name });
                }
            }
            if (this.appliedProducts[i]['complementaryApplication3']) {
                if (!complementaryApplication3Name.includes(this.appliedProducts[i]['complementaryApplication3'].name)) {
                    complementaryApplication3Name.push(this.appliedProducts[i]['complementaryApplication3'].name);
                    this.complementaryApplication3List.push({ "label": this.appliedProducts[i]['complementaryApplication3'].name, "value": this.appliedProducts[i]['complementaryApplication3'].name });
                }
            }
        }
        this.productList.forEach(element => {
            delete element.productCategory;
        });
    }

    all = [];
    filterObject(event) {
        this.appliedProducts = this.allAppliedProducts;
        let tempObj: AppliedProduct[] = [];
        let ev = event.toUpperCase();
        this.appliedProducts.forEach(val => {
            if ((val.products.name.toUpperCase().includes(ev)) || (val.productApplications.name.toUpperCase().includes(ev))) {
                tempObj.push(val);
            }
        });
        this.all = tempObj;


        if (this.all.length != 0) {
            this.appliedProducts = this.all;
        }
    }

    clearFilters(dt) {
        this.consumptionObservationsFilter = '';
        this.externalLocationFilter = '';
        this.hasInclusionCriteriaFilter = '';
        this.commercialUseFilter = '';
        this.waterResistantFilter = '';
        this.nonSlipFilter = '';
        this.coldFilter = '';
        this.scratchResistantFilter = '';
        this.stainResistantFilter = '';
        this.productFilter = '';
        this.productApplicationFilter = null;
        this.statusFilter = '';
        this.updateFilter = '';
        this.preexistencesFilter = null;
        this.complementaryApplication1Filter = null;
        this.complementaryApplication2Filter = null;
        this.complementaryApplication3Filter = null;
        this.appliedProducts = this.allAppliedProducts;
        dt.reset();
    }

    showInclusionCriterias(row: any) {
        document.getElementById('prod_ap').style.display = "initial";
        document.getElementById('sp_field').style.display = "none";
        document.getElementById('crit_inclus').style.display = "initial";
        document.getElementById('br_disp').style.display = "initial";
        this.config.header = 'Critérios de Inclusão da Produto Aplicado: ' + row.products.name + ' x ' + row.productApplications.name;
        this.appliedProduct_pass = row;
        this.activated = !this.activated;
    }

    showConsumptionCriterias(row: any) {
        //mostrar criterio de consumo.
        document.getElementById('prod_ap').style.display = "initial";
        document.getElementById('sp_field').style.display = "none";
        document.getElementById('crit_consump').style.display = "initial";
        document.getElementById('br_disp').style.display = "initial";
        this.config.header = 'Critérios de Consumo da Produto Aplicado: ' + row.products.name + ' x ' + row.productApplications.name;
        this.appliedProduct_pass = row;
        this.activated_consumption = !this.activated_consumption;
    }


    backAppliedProduct() {
        document.getElementById('br_disp').style.display = "none";
        document.getElementById('br_disp2').style.display = "none";
        document.getElementById('br_disp3').style.display = "none";
        document.getElementById('expres').style.display = "none";
        document.getElementById('prod_ap').style.display = "none";
        document.getElementById('sp_field').style.display = "initial";
        this.config.header = 'Produto Aplicado do produto: ' + this.product.name;
        if (this.activated) {
            document.getElementById('crit_inclus').style.display = "none";
            document.getElementById('crit_inclus2').style.display = "none";
            document.getElementById('cond_inclus').style.display = "none";
            document.getElementById('cond_inclus2').style.display = "none";
            this.activated = !this.activated;
            this.globals.activated_crit = false;
            this.globals.activated_cond = false;
        } else if (this.activated_consumption) {
            document.getElementById('crit_consump').style.display = "none";
            document.getElementById('crit_consump2').style.display = "none";
            document.getElementById('cond_consump').style.display = "none";
            document.getElementById('cond_consump2').style.display = "none";
            this.activated_consumption = !this.activated_consumption;
            this.globals.activated_crit_consumption = false;
            this.globals.activated_cond_consumption = false;
        }
        this.loadAllAppliedProductsByAdmin();
    }

    backInclusionCriteria() {
        if (this.globals.activated_crit) {
            document.getElementById('crit_inclus').style.display = "initial";
            document.getElementById('crit_inclus2').style.display = "none";
            document.getElementById('cond_inclus').style.display = "none";
            document.getElementById('cond_inclus2').style.display = "none";
            document.getElementById('br_disp').style.display = "initial";
            document.getElementById('br_disp2').style.display = "none";
            document.getElementById('br_disp3').style.display = "none";
            document.getElementById('expres').style.display = "none";
            document.getElementById('sp_field2').style.display = "initial";
            this.config.header = 'Critérios de Inclusão da Produto Aplicado: ' + this.appliedProduct_pass.products.name + ' x ' + this.appliedProduct_pass.productApplications.name;
            this.apiService.listChildByEntityId(this.appliedProduct_pass.id, 'inclusionCriterias/getInclusionCriteriaByAppliedProductId/').then(list => {
                this.globals.inclusion_criterias = list['result'];
            });

            this.globals.activated_crit = false;
            this.globals.activated_cond = false;
        }
    }

    backConsumptionCriteria() {
        if (this.globals.activated_crit_consumption) {
            document.getElementById('crit_consump').style.display = "initial";
            document.getElementById('crit_consump2').style.display = "none";
            document.getElementById('cond_consump').style.display = "none";
            document.getElementById('cond_consump2').style.display = "none";
            document.getElementById('br_disp').style.display = "initial";
            document.getElementById('br_disp2').style.display = "none";
            document.getElementById('br_disp3').style.display = "none";
            document.getElementById('expres').style.display = "none";
            document.getElementById('sp_field5').style.display = "initial";
            this.config.header = 'Critérios de Consumo da Produto Aplicado: ' + this.appliedProduct_pass.products.name + ' x ' + this.appliedProduct_pass.productApplications.name;
            this.apiService.listChildByEntityId(this.appliedProduct_pass.id, 'consumptionCriterions/getConsumptionCriterionsByAppliedProductId/').then(list => {
                this.globals.consumption_criterias = list['result'];
            });

            this.globals.activated_crit_consumption = false;
            this.globals.activated_cond_consumption = false;
        }
    }

    backInclusionCondition() {
        if (this.globals.activated_cond) {
            document.getElementById('crit_inclus').style.display = "none";
            document.getElementById('crit_inclus2').style.display = "initial";
            document.getElementById('cond_inclus').style.display = "initial";
            document.getElementById('cond_inclus2').style.display = "none";
            document.getElementById('br_disp').style.display = "initial";
            document.getElementById('br_disp2').style.display = "initial";
            document.getElementById('br_disp3').style.display = "none";
            document.getElementById('expres').style.display = "none";
            document.getElementById('sp_field3').style.display = "initial";
            this.apiService.listChildByEntityId(this.globals.obj_inclusion_criteria.id, 'inclusionConditions/getInclusionConditionByInclusionCriteria/').then(list => {
                this.globals.inclusion_conditions = list['result'];
                this.config.header = 'Condições de Inclusão do critério: ' + this.globals.obj_inclusion_criteria.name;
            });
            this.loadAllAppliedProductsByAdmin();
            this.globals.activated_cond = false;
        }
    }

    backConsumptionCondition() {
        if (this.globals.activated_cond_consumption) {
            document.getElementById('crit_consump').style.display = "none";
            document.getElementById('crit_consump2').style.display = "initial";
            document.getElementById('cond_consump').style.display = "initial";
            document.getElementById('cond_consump2').style.display = "none";
            document.getElementById('br_disp').style.display = "initial";
            document.getElementById('br_disp2').style.display = "initial";
            document.getElementById('br_disp3').style.display = "none";
            document.getElementById('expres').style.display = "none";
            document.getElementById('sp_field6').style.display = "initial";
            this.apiService.listChildByEntityId(this.globals.obj_consumption_criteria.id, 'consumptionConditions/getConsumptionConditionByConsumptionCriterion/').then(list => {
                this.globals.consumption_conditions = list['result'];
                this.config.header = 'Condições de Consumo do critério: ' + this.globals.obj_consumption_criteria.name;
            });
            this.loadAllAppliedProductsByAdmin();
            this.globals.activated_cond_consumption = false;
        }
    }



    options(row: any) {
        this.optionsThreePoints = [
            // { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.apiService.delete("appliedProducts", id).then(() => {
                    this.loadAllAppliedProductsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    private loadAllAppliedProductsByAdmin() {
        this.apiService.listChildByEntityId(this.product.id, 'appliedProducts/getAppliedProductsByProductId/').then(ap => {
            this.appliedProducts = ap['result'];
            this.allAppliedProducts = ap['result'];
            console.log("aps", ap['result']);
            this.appliedProducts.forEach(element => {
                element.preexistences.forEach(el => {
                    delete el.AppliedProductPreexistence;
                });
                if (element.complementaryApplication1 != null) {
                    element.complementaryApplication1 = { "name": element.complementaryApplication1.name, "id": element.complementaryApplication1.id };
                }
                if (element.complementaryApplication2 != null) {
                    element.complementaryApplication2 = { "name": element.complementaryApplication2.name, "id": element.complementaryApplication2.id };
                }
                if (element.complementaryApplication3 != null) {
                    element.complementaryApplication3 = { "name": element.complementaryApplication3.name, "id": element.complementaryApplication3.id };
                }
                // delete element.preexistences['AppliedProductPreexistence'];
            });
            this.loadFilters()
        });

    }

    showAdd() {
        document.getElementById('btns-mng').style.display = 'none'
    }

    registerAppliedProduct() {
        var arrayPreexistences = [];
        if (this.registerForm.value.preexistences) {
            this.registerForm.value.preexistences.forEach(element => {
                arrayPreexistences.push(element.id);
            });
        }
        let ret = this.registerForm.value;
        console.log('rett', ret);
        
        ret.productId = this.product.id;
        ret.productApplicationId = this.registerForm.value.productApplication.id;
        if (this.registerForm.value.complementaryApplication1) ret.complementaryApplication1Id = this.registerForm.value.complementaryApplication1.id;
        if (this.registerForm.value.complementaryApplication2) ret.complementaryApplication2Id = this.registerForm.value.complementaryApplication2.id;
        if (this.registerForm.value.complementaryApplication3) ret.complementaryApplication3Id = this.registerForm.value.complementaryApplication3.id;
        ret.preexistences = arrayPreexistences;
        this.apiService.register("appliedProducts", ret)
            .then(
                () => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            // this.close();
                            this.productApplication = null;
                            this.selectedPreexistences = null;
                            this.waterResistant = 'Não';
                            this.nonSlip = 'Não';
                            this.scratchResistant = 'Não';
                            this.stainResistant = 'Não'
                            this.cold = 'Não';
                            this.externalLocation = 'Não';
                            this.hasInclusionCriteria = 'Sim';
                            this.commercialUse = 'Não';
                            this.consumptionObservations = null;
                            this.complementaryApplication1 = null;
                            this.complementaryApplication2 = null;
                            this.complementaryApplication3 = null;
                            this.loadAllAppliedProductsByAdmin();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                });
    }

    close() {
        this.productApplication = null;
        this.selectedPreexistences = null;
        this.waterResistant = 'Não';
        this.nonSlip = 'Não';
        this.scratchResistant = 'Não';
        this.stainResistant = 'Não'
        this.cold = 'Não';
        this.externalLocation = 'Não';
        this.hasInclusionCriteria = 'Sim';
        this.commercialUse = 'Não';
        this.consumptionObservations = null;
        this.complementaryApplication1 = null;
        this.complementaryApplication2 = null;
        this.complementaryApplication3 = null;
        document.getElementById('btns-mng').style.display = 'initial';
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedAppliedProducts.forEach(user => {
            ids.push(user.id);
        });
        this.apiService.deleteAll("appliedProducts", { "ids": ids }).then(() => {
            this.selectedAppliedProducts.length = 0;
            this.loadAllAppliedProductsByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllAppliedProductsByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(ap: AppliedProduct) {
        let listId = [];
        ap.preexistences.forEach(e => {
            listId.push(e.id);
        });
        ap.preexistences = listId;
        ap.productApplicationId = ap.productApplications.id;
        if (ap.complementaryApplication1) ap.complementaryApplication1Id = ap.complementaryApplication1.id;
        if (ap.complementaryApplication2) ap.complementaryApplication2Id = ap.complementaryApplication2.id;
        if (ap.complementaryApplication3) ap.complementaryApplication3Id = ap.complementaryApplication3.id;

        this.apiService.update("appliedProducts", ap).then(() => {
            this.loadAllAppliedProductsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllAppliedProductsByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    showIC(res: boolean) {
        this.ac = res;

        if (this.ac) {
            document.getElementById('sp_field3').style.display = "initial";
        }
    }

    hasInclusionCriteriaValidator(value: any) {
        if (value.hasInclusionCriteria == 'Sim') {
            return true;
        } else {
            return false;
        }
    }
}
