import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { config } from "../guards/config";
import { User } from '../entities/user/user';
import { Auth } from '../entities/auth/auth';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Auth>;
    public currentUser: Observable<Auth>;
    private url: string;
    constructor(private http: HttpClient, private router: Router, private ngxService: NgxUiLoaderService) {
        if (localStorage.getItem('currentUserId')) {
            this.currentUserSubject = new BehaviorSubject<Auth>(new Auth(localStorage.getItem('currentUserId'), localStorage.getItem('currentUserAccessToken')));
        }
        else {
            this.currentUserSubject = new BehaviorSubject<Auth>(null);
        }
        this.currentUser = this.currentUserSubject.asObservable();
        this.url = config.baseUrl;

    }

    public get currentUserValue(): Auth {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string, rota: string) {
        this.ngxService.start();
        this.http.get(this.url + 'auth', { headers: { email: email, password: password } }).subscribe(
            (r) => {
                this.currentUserSubject.next({ id: r["result"].id, token: r["result"].token });
                localStorage.setItem('currentUserId', r["result"].id);
                localStorage.setItem('currentUserAccessToken', r["result"].token);
                this.router.navigate([rota]);
                return true;
            },
            (erro) => {
                Swal.fire({
                    title: 'Não Autorizado!',
                    text: 'Verifique se o e-mail e senha estão corretos',
                    type: 'warning',
                    confirmButtonColor: '#D95536'
                });
                this.router.navigate(['/']);
                return false;
            }
        );
        this.ngxService.stop();
    }

    logout() {
        localStorage.removeItem('currentUserId');
        localStorage.removeItem('currentUserAccessToken');
        this.currentUserSubject.next(null);
    }

    passwordRecovery(email: string){
        this.ngxService.start();
        let response = this.http.post(this.url + "users/passwordRecovery", email);
        this.ngxService.stop();
        return response;
    }

    updatePassword(id: string, password: string){
        this.ngxService.start();
        let response = this.http.patch(this.url + "users/updatePassword/" + id, { "password": password });
        this.ngxService.stop();
        return response;
    }

    checkAccess(id: string, token: string){
        // this.ngxService.start();
        let response = this.http.get(this.url + "checkAccess", { headers: { id: id, token: token } });
        // this.ngxService.stop();
        return response;
    }
}