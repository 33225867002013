import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { PreExistence } from 'src/app/entities/preexistence/preexistence';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './preexistenceAdd.modal.html'
})
export class PreexistenceModalComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  flag: SelectItem[];

  constructor(
    private preExistenceService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.flag = [
      { label: 'Piso', value: 'floor'},
      { label: 'Parede', value: 'wall'},
    ]

    this.registerForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
      description: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
      flag: [null, Validators.required]
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.preExistenceService.register("preexistences", this.registerForm.value)
      .then(
        (success) => {
          Swal.fire({
            title: 'Cadastro realizado com sucesso!',
            type: 'success',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              this.ref.close();
            }
          })
        },
        (error) => {
          var errorMsg = error.error.messages.toString();
          errorMsg = errorMsg.replace(/,/g, '');
          Swal.fire({
            title: 'Adição Não Realizada!',
            text: errorMsg,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          });
          this.loading = false;
        });
  }

  close() {
    this.ref.close();
  }

}
