export class ProductCategorySpecification {
    id: string;
    name: string;
    specificationType: string;
    isVisible: boolean;

    constructor(id: string, name: string, specificationType: string, isVisible: boolean) {
        this.id = id;
        this.name = name;
        this.specificationType = specificationType;
        this.isVisible = isVisible;
    }
}