import { Component } from "@angular/core";
import { Appearance } from "../appearance/appearance";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { Environment } from "src/app/entities/environment/environment";
import { Style } from "src/app/entities/style/style";
import { S3Service } from '../../services/s3.service';
import Swal from 'sweetalert2';
import { async } from "@angular/core/testing";

@Component({
    templateUrl: './environmentAdd.modal.html',
})
export class EnvironmentAddModalComponent {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    selectedStyle: Style;
    selectedAppearance: Appearance;
    selectedFiles: FileList;
    tempUrl: any;
    styles: Style[];
    appearances: Appearance[];
    binary: SelectItem[];

    constructor(
        private appearanceService: ApiService,
        private environmentService: ApiService,
        private styleService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        appearanceService.getAll("appearances", true).then(appearances => this.appearances = appearances['result']);
        styleService.getAll("styles").then(style => this.styles = style['result']);
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            descriptionFloor: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            descriptionWall: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            appearance: [null, Validators.required],
            style: [null, Validators.required],
            appearanceWall: [null, Validators.required],
            // styleWall: [null, Validators.required],
            isExclusive: [null, Validators.required],
            displayOrder: ['', Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            imgUrl: ['', Validators.required],
            photoCredits: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            blogUrl: ['', Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])]
        });

        this.binary = [
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
          ];
    }

    get f() {
        return this.registerForm.controls;
    }


    onSubmit() {
        this.submitted = true;
        this.registerForm.value.imgUrl = this.tempUrl;
        this.loading = true;
        this.environmentService.register("environments", <Environment>{
            "descriptionFloor": this.registerForm.value.descriptionFloor,
            "descriptionWall": this.registerForm.value.descriptionWall,
            "appearanceId": this.registerForm.value.appearance.id,
            "styleId": this.registerForm.value.style.id,
            "appearanceWallId": this.registerForm.value.appearanceWall.id,
            // "styleWallId": this.registerForm.value.styleWall.id,
            "isExclusive": this.registerForm.value.isExclusive,
            "imgUrl": this.registerForm.value.imgUrl,
            "displayOrder": this.registerForm.value.displayOrder,
            "blogUrl": this.registerForm.value.blogUrl,
            "photoCredits": this.registerForm.value.photoCredits
         })
            .then(
                () => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    deleteImg() {
        this.tempUrl = null;
        this.registerForm.value.imgUrl1 = null;
        document.getElementById("imgUrl")['value'] = "";
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }
}
