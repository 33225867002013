import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';

import { Ambience } from './ambience';
import { ApiService } from '../../services/api.service';

@Component({
  templateUrl: './ambienceEdit.modal.html'
})
export class AmbienceEditModalComponent implements OnInit {
  ambience: Ambience[];
  updateForm: FormGroup;
  loading = false;
  submitted = false;
  binary: SelectItem[];
  useType: SelectItem[];
  localType: SelectItem[];
  ambienceType: SelectItem[];

  constructor(
    private ambienceService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private router: Router
  ) {

    this.updateForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
      waterResistant: ['', Validators.required],
      nonSlip: ['', Validators.required],
      scratchResistant: ['', Validators.required],
      stainResistant: ['', Validators.required],
      cold: ['', Validators.required],
      useType: ['', Validators.required],
      localType: ['', Validators.required],
      ambienceType: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.ambience = this.config.data;

    this.binary = [
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' }
    ];

    this.ambienceType = [
      { label: 'Molhado', value: 'Molhado' },
      { label: 'Seco', value: 'Seco' }
    ];

    this.localType = [
      { label: 'Interna', value: 'Interna' },
      { label: 'Externa', value: 'Externa' }
    ];

    this.useType = [
      { label: 'Residencial', value: 'Residencial' },
      { label: 'Comercial', value: 'Comercial' }
    ];

  }

  get f() {
    return this.updateForm.controls;
  }

  onSubmit(ambience: Ambience) {
    this.loading = true;
    this.ambienceService.update("ambiences", ambience)
      .then(
        () => {
          Swal.fire({
            title: 'Edição realizada com sucesso!',
            type: 'success',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              this.ref.close();
            }
          })
        },
        (error) => {
          var errorMsg = error.error.messages.toString();
          errorMsg = errorMsg.replace(/,/g, '');
          Swal.fire({
            title: 'Edição Não Realizada!',
            text: errorMsg,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          });
          this.loading = false;
        });
  }

  close() {
    this.ref.close();
  }
}
