import { Component } from "@angular/core";
import { User } from "../user";
import { ApiService } from "../../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from "@angular/router";
import { S3Service } from '../../../services/s3.service';
import swal from 'sweetalert';
import { AuthenticationService } from "src/app/services/authentication.service";
import Swal from "sweetalert2";

@Component({
    templateUrl: './editPerfil.component.html',
})
export class EditPerfilComponent {
    user: any;
    currentUser;
    updateForm: FormGroup;
    passwordForm: FormGroup;
    newPasswordForm: FormGroup;
    loading = false;
    submitted = false;
    tempUrl: any;
    editmode = false;
    constructor(
        private userService: ApiService,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router,
        private authenticationService: AuthenticationService,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        this.getUserProfile();
        this.updateForm = this.formBuilder.group({
            name: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            email: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.email])],
            role: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.maxLength(100)])],
            imgUrl: [{ value: '', disabled: true }],
            socialName: [{ value: '', disabled: true }, Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            cnpj: [{ value: '', disabled: true }, Validators.compose([Validators.maxLength(100), Validators.minLength(14), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            address: [{ value: '', disabled: true }, Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            telephone: [{ value: '', disabled: true }, Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])]
        });

        this.passwordForm = this.formBuilder.group({
            password: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(6), Validators.maxLength(100), Validators.pattern(/(?=.*[a-zA-Z])(?=.*[0-9]+).*/)]],
        });

        this.newPasswordForm = this.formBuilder.group({
            password: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(6), Validators.maxLength(100), Validators.pattern(/(?=.*[a-zA-Z])(?=.*[0-9]+).*/)]],
            passwordConfirm: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(6), Validators.maxLength(100), Validators.pattern(/(?=.*[a-zA-Z])(?=.*[0-9]+).*/)]],
        })
    }

    get f() {
        return this.updateForm.controls;
    }

    get f2() {
        return this.passwordForm.controls;
    }

    get f3() {
        return this.newPasswordForm.controls;
    }

    getUserProfile() {
        this.apiService.getById("users", localStorage.getItem('currentUserId')).then((cur) => {
            this.user = cur['result'];
            this.tempUrl = this.user['imgUrl'];
        });
    }

    onSubmit(user: User) {
        this.loading = true;
        user['imgUrl'] = this.tempUrl;
        this.userService.update("users", user)
            .then(
                () => {
                    swal({
                        title: "Edição realizada com sucesso!",
                        icon: "success",
                    });
                    this.router.navigate(['/edituser']);
                },
                (error) => {
                    swal({
                        title: "Tente Novamente",
                        text: "Edição Não Realizada",
                        icon: "warning",
                    });
                    this.loading = false;
                });
    }

    close() {
        this.router.navigate(['/']);
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    if (imgUrl) {
                        this.tempUrl = imgUrl.toString();
                        // this.icons.style.display = 'initial';
                    }
                });
            } else {
                this.tempUrl = null;
                this.updateForm.value.imgUrl = null;
                document.getElementById("imgUrl")['value'] = "";
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.tempUrl = null;
            this.updateForm.value.imgUrl = null;
            document.getElementById("imgUrl")['value'] = "";
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }

    checkPassword() {
        // Chamar endpoint para verificar se senha esta correta
        this.userService.changePswd(this.passwordForm.value).then((res) => {
            
            document.getElementById('newPass')["disabled"] = false;
            document.getElementById('newPassConfirm')["disabled"] = false;
            document.getElementById('btn-save-pswd')["disabled"] = false;
            document.getElementById('currentPass')['disabled'] = true;
        },
            (err) => {
                Swal.fire({
                    title: 'Senha incorreta!',
                    text: 'A senha fornecida não corresponde à cadastrada em nossos registros. Verifique-a e tente novamente.',
                    type: 'error',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                })
            });

    }

    edit(user: User, pass) {
        if (pass) {
            if (this.newPasswordForm.value.password == this.newPasswordForm.value.passwordConfirm) {
                this.userService.update("users", { "id": localStorage.getItem('currentUserId'), "password": this.newPasswordForm.value.passwordConfirm }).then(
                    (res) => {
                        Swal.fire({
                            title: 'Senha atualizada com sucesso!',
                            type: 'success',
                            confirmButtonColor: '#D95536',
                            allowOutsideClick: false
                        }).then(() => {
                            document.getElementById('newPass')["value"] = "";
                            document.getElementById('newPass')["disabled"] = true;
                            document.getElementById('newPassConfirm')["value"] = "";
                            document.getElementById('newPassConfirm')["disabled"] = true;
                            document.getElementById('btn-save-pswd')["disabled"] = true;
                            document.getElementById('currentPass')['value'] = "";
                            document.getElementById('currentPass')['disabled'] = false;
                        });
                    },
                    (err) => {
                        Swal.fire({
                            title: 'Erro na atualização da senha!',
                            text: err.error.messages,
                            type: 'error',
                            confirmButtonColor: '#D95536',
                            allowOutsideClick: false
                        })
                    }
                )

            } else {
                Swal.fire({
                    title: 'Atualização de senha não realizada!',
                    text: 'As senhas não conferem',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                })

            }
        } else {
            //Remove o password criptografado do usuario para que nao haja troca de senha
            delete user.password;
            this.userService.update("users", user).then(() => {
                this.getUserProfile();
            }, (error) => {
                var msgs = error.error['messages'];
                var message = '';
                msgs.forEach((m: string, index: number) => {
                    message += m;
                    if (index < msgs.length - 1) message += ', ';
                });
                Swal.fire({
                    title: 'Edição Não Realizada!',
                    text: message,
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                })
                this.router.navigate(['/edituser']);
                this.getUserProfile();
            });
        }
    }

    disablededitmode(user: User) {
        var inputs = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputs.length; i++) {
                inputs[i]["disabled"] = false;
                inputs[i].classList.add("form-pro-table-edit");
            }
            document.getElementById("editDisplay").style.display = 'unset';
            if (this.tempUrl != null)
                document.getElementById("iconsImg").style.display = 'inherit';
        }
        else {
            this.editmode = false
            for (let i = 0; i < inputs.length; i++) {
                inputs[i]['disabled'] = true;
                inputs[i].classList.remove("form-pro-table-edit");
            }
            document.getElementById("editDisplay").style.display = 'none';
            if (this.tempUrl != null)
                document.getElementById("iconsImg").style.display = 'none';
        }
    }

    saveImg(user: any) {
        this.user['imgUrl'] = this.tempUrl;
        this.userService.update("users", user).then(() => {
            Swal.fire({
                title: 'Foto atualizada com sucesso!',
                type: 'success',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            }).then(() => { this.router.navigate(['/edituser']); });
        }, (error) => {
            var msgs = error.error['messages'];
            var message = '';
            msgs.forEach((m: string, index: number) => {
                message += m;
                if (index < msgs.length - 1) message += ', ';
            });
            Swal.fire({
                title: 'Falha na atualização da foto!',
                text: message,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            })
            this.router.navigate(['/edituser']);
        });
    }

    deleteImg() {
        this.tempUrl = null;
        this.updateForm.value.imgUrl = null;
        document.getElementById("imgUrl")['value'] = "";
        this.userService.update("users", { id: localStorage.getItem('currentUserId'), imgUrl: this.updateForm.value.imgUrl })
            .then(
                () => {
                    Swal.fire({
                        title: 'Imagem deletada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    })
                },
                (error) => {
                    Swal.fire({
                        title: 'Deleção Não Realizada!',
                        text: "Tente Novamente",
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

}