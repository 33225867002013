import { Component } from "@angular/core";
import { Intervention } from "./intervention";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { Question } from "../question/question";

@Component({
    templateUrl: './interventionEdit.modal.html',
})
export class InterventionEditModalComponent {
    intervention: Intervention[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    binary: SelectItem[];
    optionsType: SelectItem[];
    questions: Question[];
    selectedQuestions: Question[] = [];

    constructor(
        private interventionService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { 
        this.interventionService.getAll("questions", true).then(result => {
            this.questions = result['result'];

            this.questions.forEach(e =>{
                if(this.config.data['questions'].some(elem => elem['id'] == e['id'])){
                    this.selectedQuestions.push(e);
                }
            });
            this.config.data['questions'] = this.selectedQuestions;
        });
    }

    ngOnInit() {
        this.intervention = this.config.data;
        this.updateForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            noise: [null],
            smell: [null],
            dust: [null],
            residue: [null],
            interventionType: [null, Validators.required],
            questions: [null]
        });

        this.binary = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.optionsType = [
            {label: 'Base', value: 'Base'},
            {label: 'Acabamento', value: 'Acabamento'}
        ]
    }

    get f() {
        return this.updateForm.controls;
    }

    onSubmit(intervention: Intervention) {
        this.loading = true;
        intervention['questions'] = [];
        this.selectedQuestions.forEach(e=> {
            intervention['questions'].push(e.id);
        })
        this.interventionService.update("interventions", intervention)
            .then(
                () => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }
}