import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FormsModule } from "@angular/forms";
import { CurrencyMaskModule } from "ng2-currency-mask";

import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { Globals } from './globals';

import { UserComponent } from './entities/user/user.component';
import { LoginComponent } from './entities/auth/login/login.component';
import { RegisterComponent } from './entities/user/register/register.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { PanelMenuModule } from "primeng/panelmenu";
import { SidebarModule } from "ng-sidebar";
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {CalendarModule} from 'primeng/calendar';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrMaskerModule } from 'br-mask';

import { ColorComponent } from './entities/color/color.component';
import { MaterialComponent } from './entities/material/material.component';
import { InterventionComponent } from './entities/intervention/intervention.component';
import { StandardComponent } from './entities/standard/standard.component';
import { StyleComponent } from './entities/style/style.component';
import { UserAddModalComponent } from './entities/user/userAdd.modal';
import { UserEditModalComponent } from './entities/user/userEdit.modal';
import { UserViewModalComponent } from './entities/user/userView.modal';
import { QuestionComponent } from "./entities/question/question.component";
import { ProductCategoryComponent } from "./entities/productCategory/productCategory.component";
import { ProductCategoryAddModalComponent } from "./entities/productCategory/productCategoryAdd.modal";
import { ProductCategoryEditModalComponent } from "./entities/productCategory/productCategoryEdit.modal";
import { ProductCategoryViewModalComponent } from './entities/productCategory/productCategoryView.modal';

import { StyleAddModalComponent } from "./entities/style/styleAdd.modal";
import { StyleEditModalComponent } from "./entities/style/styleEdit.modal";
import { StyleViewModalComponent } from "./entities/style/styleView.modal";
import { MaterialAddModalComponent } from './entities/material/materialAdd.modal';
import { MaterialEditModalComponent } from './entities/material/materialEdit.modal';
import { MaterialViewModalComponent } from './entities/material/materialView.modal';
import { InterventionAddModalComponent } from './entities/intervention/interventionAdd.modal';
import { InterventionEditModalComponent } from './entities/intervention/interventionEdit.modal';
import { InterventionViewModalComponent } from './entities/intervention/interventionView.modal';
import { ColorAddModalComponent } from './entities/color/colorAdd.modal';
import { StandardAddModalComponent } from "./entities/standard/standardAdd.modal";
import { StandardEditModalComponent } from "./entities/standard/standardEdit.modal";
import { StandardViewModalComponent } from "./entities/standard/standardView.modal";

import { EnvironmentAddModalComponent } from "./entities/environment/environmentAdd.modal";
import { EnvironmentEditModalComponent } from "./entities/environment/environmentEdit.modal";
import { EnvironmentViewModalComponent } from "./entities/environment/environmentView.modal";
import { AppearanceComponent } from './entities/appearance/appearance.component';
import { AppearanceMaterialComponent } from "./entities/appearanceMaterial/appearanceMaterial.component";
import { EnvironmentComponent } from './entities/environment/environment.component';
import { PreexistenceComponent } from './entities/preexistence/preexistence.component';
import { PreexistenceModalComponent } from './entities/preexistence/preexistenceAdd.modal';
import { AmbienceComponent } from './entities/ambience/ambience.component';
import { AmbienceModalComponent } from './entities/ambience/ambienceAdd.modal';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { PossibleActionComponent } from './entities/possibleAction/possibleAction.component';
import { PossibleActionModalComponent } from './entities/possibleAction/possibleActionAdd.modal';
import { AuthGuard } from './guards/auth.guard';
import { ColorViewModalComponent } from './entities/color/colorView.modal';
import { ColorEditModalComponent } from './entities/color/colorEdit.modal';

import { AmbienceEditModalComponent } from './entities/ambience/ambienceEdit.modal';
import { AmbienceViewModalComponent } from './entities/ambience/ambienceView.modal';
import { PreexistenceEditModalComponent } from './entities/preexistence/preexistenceEdit.modal';
import { PreexistenceViewModalComponent } from './entities/preexistence/preexistenceView.modal';
import { AppearanceAddModalComponent } from './entities/appearance/appearanceAdd.modal';
import { AppearanceEditModalComponent } from './entities/appearance/appearanceEdit.modal';
import { AppearanceViewModalComponent } from './entities/appearance/appearanceView.modal';
import { AppearanceMaterialAddModalComponent } from "./entities/appearanceMaterial/appearanceMaterialAdd.modal";
import { AppearanceMaterialEditModalComponent } from "./entities/appearanceMaterial/appearanceMaterialEdit.modal";
import { AppearanceMaterialViewModalComponent } from "./entities/appearanceMaterial/appearanceMaterialView.modal";
import { PossibleActionViewModalComponent } from './entities/possibleAction/possibleActionView.modal.component';
import { PossibleActionEditModalComponent } from './entities/possibleAction/possibleActionEdit.modal';
import { ForgotPasswordComponent } from './entities/user/forgotPassword/forgotPassword.component';
import { RegisterPasswordComponent } from './entities/user/registerPassword/registerPassword.component';
import { EditPerfilComponent } from './entities/user/editPerfil/editPerfil.component';

import {AutofocusDirective} from './directives/autofocus.directive';
import { QuestionAddModalComponent } from './entities/question/questionAdd.modal';
import { QuestionEditModalComponent } from './entities/question/questionEdit.modal';
import { QuestionViewModalComponent } from "./entities/question/questionView.modal";
import { AnswerComponent } from './entities/answer/answer.component';
import { ProductCategorySpecificationComponent } from './entities/productCategorySpecification/productCategorySpecification.component';
import { ProductCategorySpecificationListComponent } from "./entities/ProductCategorySpecificationList/productCategorySpecificationList.component";
import { ManufacturerAddModalComponent } from './entities/manufacturer/manufacturerAdd.modal';
import { ManufacturerEditModalComponent } from "./entities/manufacturer/manufacturerEdit.modal";
import { ManufacturerViewModalComponent } from "./entities/manufacturer/manufacturerView.modal";
import { ManufacturerComponent } from './entities/manufacturer/manufacturer.component';
import { BrandComponent } from './entities/brand/brand.component';
import { ProductComponent } from './entities/product/product.component';
import { ProductAddModalComponent } from './entities/product/productAdd.modal';
import { ProductEditModalComponent } from './entities/product/productEdit.modal';
import { ProductViewModalComponent } from './entities/product/productView.modal';
import { RetailComponent } from './entities/retail/retail.component';
import { RetailAddModalComponent } from './entities/retail/retailAdd.modal';
import { RetailEditModalComponent } from './entities/retail/retailEdit.modal';
import { RetailViewModalComponent } from './entities/retail/retailView.modal';
import { ProductApplicationComponent } from './entities/productApplication/productApplication.component';
import { ProductApplicationAddModalComponent } from './entities/productApplication/productApplicationAdd.modal';
import { ProductApplicationEditModalComponent } from './entities/productApplication/productApplicationEdit.modal';
import { ProductApplicationViewModalComponent } from './entities/productApplication/productApplicationView.modal';
import { PackagedProductComponent } from './entities/packagedProduct/packagedProduct.component';
import { StoreComponent } from './entities/store/store.component';
import { StoreEditModalComponent } from './entities/store/storeEdit.modal';
import { StoreViewModalComponent } from './entities/store/storeView.modal';
import { PackagedProductStoreComponent } from './entities/packagedProductStore/packagedProductStore.component';
import { AppliedProductComponent } from './entities/appliedProduct/appliedProduct.component';
import { AppliedProductAddModalComponent } from './entities/appliedProduct/appliedProductAdd.modal';
import { AppliedProductEditModalComponent } from './entities/appliedProduct/appliedProductEdit.modal';
import { AppliedProductViewModalComponent } from './entities/appliedProduct/appliedProductView.modal';
import { AppliedProductModalComponent } from './entities/appliedProduct/appliedProduct.modal';
import { InclusionCriteriaComponent } from './entities/inclusionCriteria/inclusionCriteria.component';
import { InclusionConditionComponent } from './entities/inclusionCondition/inclusionCondition.component';
import { InclusionConditionExpressionComponent } from './entities/inclusionConditionExpression/inclusionConditionExpression.component';
import { ConsumptionCriteriaComponent } from './entities/consumptionCriteria/consumptionCriteria.component';
import { ConsumptionConditionComponent } from './entities/consumptionCondition/consumptionCondition.component';
import { ConsumptionConditionExpressionComponent } from './entities/consumptionConditionExpression/consumptionConditionExpression.component';
import { LeadComponent } from './entities/lead/lead.component';
import { GroupSpecificationComponent } from './entities/groupSpecification/groupSpecification.component';
import { CombinationAnswerComponent } from './entities/combinationAnswer/combinationAnswer.component';
import { SpecificationComponent } from './entities/specification/specification.component';
import { StorePackagedProductComponent } from './entities/storePackagedProduct/storePackagedProduct.component';
import { ConvertFlagPipe } from './pipes/convert-flag.pipe';
import { EnvironmentProductComponent } from './entities/environment-product/environment-product.component';
import { WhitelabelComponent } from './entities/whitelabel/whitelabel.component';
import { WhitelabelMaterialComponent } from './entities/whitelabel-material/whitelabel-material.component';
import { WhitelabelApperanceMaterialComponent } from './entities/whitelabel-apperance-material/whitelabel-apperance-material.component';
import { WhitelabelEnvironmentComponent } from './entities/whitelabel-environment/whitelabel-environment.component';
import { WhitelabelAddModalComponent } from './entities/whitelabel/whitelabelAdd.modal';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    fgsColor: '#D95536', // foreground spinner color
    fgsPosition: POSITION.centerCenter, // foreground spinner position
    fgsSize: 100, // foreground spinner size
    fgsType: SPINNER.circle, // foreground spinner type
    pbColor: '#D95536', // progess bar color
    pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
    pbThickness: 0, // progress bar thickness
};

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        TableModule,
        SidebarModule.forRoot(),
        ButtonModule,
        BrowserAnimationsModule,
        DialogModule,
        DynamicDialogModule,
        MultiSelectModule,
        AutoCompleteModule,
        CalendarModule,
        FormsModule,
        DropdownModule,
        CurrencyMaskModule,
        PanelMenuModule,
        InputSwitchModule,
        SplitButtonModule,
        // SweetAlert2Module.forRoot()
        SweetAlert2Module.forRoot({
            buttonsStyling: false,
            customClass: 'modal-content',
            confirmButtonClass: 'btn btn-pro-alert',
            cancelButtonClass: 'btn'
        }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        BrMaskerModule
    ],
    declarations: [
        AppComponent,
        UserComponent,
        LoginComponent,
        RegisterComponent,
        ColorComponent,
        MaterialComponent,
        InterventionComponent,
        StandardComponent,
        StyleComponent,
        UserAddModalComponent,
        UserEditModalComponent,
        UserViewModalComponent,
        StyleAddModalComponent,
        StyleEditModalComponent,
        StyleViewModalComponent,
        MaterialAddModalComponent,
        MaterialEditModalComponent,
        MaterialViewModalComponent,
        InterventionAddModalComponent,
        InterventionEditModalComponent,
        InterventionViewModalComponent,
        ColorAddModalComponent,
        WhitelabelAddModalComponent,
        StandardAddModalComponent,
        StandardEditModalComponent,
        StandardViewModalComponent,
        AppearanceComponent,
        AppearanceAddModalComponent,
        AppearanceEditModalComponent,
        AppearanceViewModalComponent,
        EnvironmentComponent,
        EnvironmentAddModalComponent,
        EnvironmentEditModalComponent,
        EnvironmentViewModalComponent,
        PreexistenceComponent,
        PreexistenceModalComponent,
        AmbienceComponent,
        AmbienceModalComponent,
        SidebarComponent,
        PossibleActionComponent,
        PossibleActionModalComponent,
        ColorViewModalComponent,
        ColorEditModalComponent,
        AmbienceEditModalComponent,
        AppearanceMaterialComponent,
        AppearanceMaterialAddModalComponent,
        AppearanceMaterialEditModalComponent,
        AppearanceMaterialViewModalComponent,
        AmbienceViewModalComponent,
        PreexistenceEditModalComponent,
        PreexistenceViewModalComponent,
        PossibleActionEditModalComponent,
        PossibleActionViewModalComponent,
        ForgotPasswordComponent,
        RegisterPasswordComponent,
        EditPerfilComponent,
        AutofocusDirective,
        QuestionComponent,
        QuestionAddModalComponent,
        QuestionEditModalComponent,
        QuestionViewModalComponent,
        AnswerComponent,
        ProductCategoryComponent,
        ProductCategoryAddModalComponent,
        ProductCategoryEditModalComponent,
        ProductCategoryViewModalComponent,
        ProductCategorySpecificationComponent,
        ProductCategorySpecificationListComponent,
        ManufacturerComponent,
        ManufacturerAddModalComponent,
        ManufacturerEditModalComponent,
        ManufacturerViewModalComponent,
        BrandComponent,
        ProductComponent,
        ProductAddModalComponent,
        ProductEditModalComponent,
        ProductViewModalComponent,
        RetailComponent,
        RetailAddModalComponent,
        RetailEditModalComponent,
        RetailViewModalComponent,
        ProductApplicationComponent,
        ProductApplicationAddModalComponent,
        ProductApplicationEditModalComponent,
        ProductApplicationViewModalComponent,
        PackagedProductComponent,
        StoreComponent,
        StoreEditModalComponent,
        StoreViewModalComponent,
        PackagedProductStoreComponent,
        AppliedProductComponent,
        AppliedProductAddModalComponent,
        AppliedProductEditModalComponent,
        AppliedProductViewModalComponent,
        AppliedProductModalComponent,
        InclusionCriteriaComponent,
        InclusionConditionComponent,
        InclusionConditionExpressionComponent,
        ConsumptionCriteriaComponent,
        ConsumptionConditionComponent,
        ConsumptionConditionExpressionComponent,
        LeadComponent,
        GroupSpecificationComponent,
        CombinationAnswerComponent,
        SpecificationComponent,
        StorePackagedProductComponent,
        ConvertFlagPipe,
        EnvironmentProductComponent,
        WhitelabelComponent,
        WhitelabelMaterialComponent,
        WhitelabelApperanceMaterialComponent,
        WhitelabelEnvironmentComponent
    ],
    entryComponents: [
        UserAddModalComponent,
        UserEditModalComponent,
        UserViewModalComponent,
        ColorAddModalComponent,
        WhitelabelAddModalComponent,
        MaterialAddModalComponent,
        MaterialEditModalComponent,
        MaterialViewModalComponent,
        InterventionAddModalComponent,
        InterventionEditModalComponent,
        InterventionViewModalComponent,
        StyleAddModalComponent,
        StyleEditModalComponent,
        StyleViewModalComponent,
        StandardAddModalComponent,
        StandardEditModalComponent,
        StandardViewModalComponent,
        AppearanceAddModalComponent,
        AppearanceEditModalComponent,
        AppearanceViewModalComponent,
        EnvironmentAddModalComponent,
        EnvironmentEditModalComponent,
        EnvironmentViewModalComponent,
        PreexistenceModalComponent,
        AmbienceModalComponent,
        PossibleActionModalComponent,
        ColorViewModalComponent,
        ColorEditModalComponent,
        AmbienceEditModalComponent,
        AppearanceMaterialAddModalComponent,
        AppearanceMaterialEditModalComponent,
        AppearanceMaterialViewModalComponent,
        AmbienceViewModalComponent,
        PreexistenceEditModalComponent,
        PreexistenceViewModalComponent,
        PossibleActionEditModalComponent,
        PossibleActionViewModalComponent,
        QuestionAddModalComponent,
        QuestionEditModalComponent,
        QuestionViewModalComponent,
        AnswerComponent,
        ProductCategoryAddModalComponent,
        ProductCategoryEditModalComponent,
        ProductCategoryViewModalComponent,
        ProductCategorySpecificationComponent,
        ProductCategorySpecificationListComponent,
        ManufacturerAddModalComponent,
        ManufacturerEditModalComponent,
        ManufacturerViewModalComponent,
        BrandComponent,
        ProductAddModalComponent,
        ProductEditModalComponent,
        ProductViewModalComponent,
        RetailAddModalComponent,
        RetailEditModalComponent,
        RetailViewModalComponent,
        ProductApplicationAddModalComponent,
        ProductApplicationEditModalComponent,
        ProductApplicationViewModalComponent,
        PackagedProductComponent,
        StoreComponent,
        StoreEditModalComponent,
        StoreViewModalComponent,
        PackagedProductStoreComponent,
        AppliedProductAddModalComponent,
        AppliedProductEditModalComponent,
        AppliedProductViewModalComponent,
        AppliedProductModalComponent,
        InclusionCriteriaComponent,
        InclusionConditionComponent,
        InclusionConditionExpressionComponent,
        ConsumptionCriteriaComponent,
        ConsumptionConditionComponent,
        ConsumptionConditionExpressionComponent,
        GroupSpecificationComponent,
        CombinationAnswerComponent,
        SpecificationComponent,
        StorePackagedProductComponent,
        EnvironmentProductComponent,
        WhitelabelApperanceMaterialComponent,
        WhitelabelMaterialComponent,
        WhitelabelEnvironmentComponent
    ],
    providers: [AuthGuard, Globals],
    bootstrap: [AppComponent]
})

export class AppModule { }
