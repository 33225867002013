import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Environment } from "./environment";
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import { EnvironmentAddModalComponent } from 'src/app/entities/environment/environmentAdd.modal';
import { EnvironmentEditModalComponent } from 'src/app/entities/environment/environmentEdit.modal';
import { EnvironmentViewModalComponent } from 'src/app/entities/environment/environmentView.modal';
import Swal from 'sweetalert2';
import { EnvironmentProductComponent } from '../environment-product/environment-product.component';

@Component({
    selector: 'app-environments',
    templateUrl: './environment.component.html',
    styleUrls: ['./environment.component.css'],
    providers: [DialogService]
})
export class EnvironmentComponent implements OnInit {
    environments: Environment[] = [];
    allEnvironments;
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedItens: Environment[] = [];
    status: SelectItem[];
    appearances: any[];
    appearanceList: SelectItem[] = [];
    appearanceWallList: SelectItem[] = [];
    styles: any[];
    styleList: SelectItem[] = [];
    editmode = false;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    descriptionFloorFilter = '';
    descriptionWallFilter = '';
    photoCreditsFilter = '';
    appearanceFilter = null;
    appearanceWallFilter = null;
    styleFilter = null;
    displayFilter = '';
    blogFilter = '';
    statusFilter = '';
    isExclusiveFilter = '';
    updateFilter = '';
    rowsPerPageOptions;

    constructor(
        private userService: ApiService,
        private authenticationService: AuthenticationService,
        private environmentsService: ApiService,
        private appearanceService: ApiService,
        private styleService: ApiService,
        private router: Router,
        private dialogService: DialogService
    ) {
        this.appearanceService.getAll("appearances").then(appearances => {
            this.appearances = appearances['result'];
        });
        this.styleService.getAll("styles").then(styles => {
            this.styles = styles['result'];
        });
    }
    ngOnInit() {
        this.loadAllEnvironmentsByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.cols = [
            { orderCol: 'descriptionFloor', field: 'descriptionFloor', header: 'Descrição Piso' },
            { orderCol: 'descriptionWall', field: 'descriptionWall', header: 'Descrição Parede' },
            { orderCol: 'appearance.name', field: 'appearance', header: 'Aparência Piso' },
            { orderCol: 'appearanceWall.name', field: 'appearanceWall', header: 'Aparência Parede' },
            { orderCol: 'style.name', field: 'style', header: 'Estilo' },
            // {  orderCol: 'styleWall.name', field: 'styleWall', header: 'Estilo Parede' },
            { orderCol: 'imgUrl', field: 'imgUrl', header: 'Imagem' },
            { orderCol: 'blogUrl', field: 'blogUrl', header: 'Blog' },
            { orderCol: 'displayOrder', field: 'displayOrder', header: 'Ordem de exibição' },
            { orderCol: 'photoCredits', field: 'photoCredits', header: 'Crédito da Imagem' },
            { orderCol: 'isExclusive', field: 'isExclusive', header: 'Exclusiva' },
            { orderCol: 'productExclusive', field: 'productExclusive', header: 'Produtos Exclusivos' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { orderCol: 'descriptionFloor', field: 'descriptionFloor', header: 'Descrição Piso' },
                { orderCol: 'descriptionWall', field: 'descriptionWall', header: 'Descrição Parede' },
                { orderCol: 'isVisible',field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'descriptionFloor', field: 'descriptionFloor', header: 'Descrição Piso' },
                { orderCol: 'descriptionWall', field: 'descriptionWall', header: 'Descrição Parede' },
                { orderCol: 'appearance.name', field: 'appearance', header: 'Aparência Piso' },
                { orderCol: 'style.name', field: 'style', header: 'Estilo' },
                { orderCol: 'appearanceWall.name', field: 'appearanceWall', header: 'Aparência Parede' },
                // {  orderCol: 'styleWall.name', field: 'styleWall', header: 'Estilo Parede' },
                { orderCol: 'isExclusive', field: 'isExclusive', header: 'Exclusiva' },
                { orderCol: 'productExclusive', field: 'productExclusive', header: 'Produtos Exclusivos' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.selectedColumns = this.colsToggle;
    }


    clearFilters(dt) {
        this.descriptionFloorFilter = '';
        this.descriptionWallFilter = '';
        this.photoCreditsFilter = '';
        this.appearanceFilter = null;
        this.appearanceWallFilter = null;
        this.styleFilter = null;
        this.displayFilter = '';
        this.blogFilter = '';
        this.statusFilter = '';
        this.isExclusiveFilter = '';
        this.updateFilter = '';
        this.environments = this.allEnvironments;
        dt.reset();
    }


    onRowSelect(event, row) {
        this.selectedItens = row;
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllEnvironmentsByAdmin();
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.environmentsService.delete("environments", id).then(() => {
                    this.loadAllEnvironmentsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })



    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: Environment) {
        this.environmentsService.register("environments", {
            "descriptionFloor": this.makeIdToDuplicateRow(5) + row['descriptionFloor'],
            "descriptionWall": this.makeIdToDuplicateRow(5) + row['descriptionWall'],
            "appearanceId": row['appearanceId'],
            "appearanceWallId": row['appearanceWallId'],
            "styleId": row['styleId'],
            "displayOrder": row['displayOrder'],
            "imgUrl": row['imgUrl'],
            "blogUrl": row['blogUrl'],
            "isVisible": false,
            "photoCredits": row['photoCredits']
        }).then(() => {
            this.loadAllEnvironmentsByAdmin();
        },
            (er) => {

                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            });
    }

    loadFilters() {
        let appearanceName = [];
        let appearanceWallName = [];
        let styleName = [];
        this.appearanceList = [];
        this.appearanceWallList = [];
        this.styleList = [];

        for (var i = 0; i < this.environments.length; i++) {
            if (!appearanceName.includes(this.environments[i].appearance.name)) {
                appearanceName.push(this.environments[i].appearance.name);
                this.appearanceList.push({ "label": this.environments[i].appearance.name, "value": this.environments[i].appearance.name });
            }
            if (!appearanceWallName.includes(this.environments[i].appearanceWall.name)) {
                appearanceWallName.push(this.environments[i].appearanceWall.name);
                this.appearanceWallList.push({ "label": this.environments[i].appearanceWall.name, "value": this.environments[i].appearanceWall.name });
            }
            if (!styleName.includes(this.environments[i].style.name)) {
                styleName.push(this.environments[i].style.name);
                this.styleList.push({ "label": this.environments[i].style.name, "value": this.environments[i].style.name });
            }
        }
    }

    private loadAllEnvironmentsByAdmin() {
        this.environmentsService.getAll("environments", true).then(environments => {
            this.rowsPerPageOptions = undefined;
            if (environments['result'].length > 10 && environments['result'].length < 25) {
                this.rowsPerPageOptions = [10, 25];
            }
            else if (environments['result'].length > 25) {
                this.rowsPerPageOptions = [10, 25, environments['result'].length];
            }
            this.environments = environments['result'];
            this.allEnvironments = environments['result'];
            this.loadFilters();
        });
    }

    showAdd() {
        this.dialogService.open(EnvironmentAddModalComponent, {
            header: 'Cadastrar Ambientação',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllEnvironmentsByAdmin();
        });
    }

    showEdit(environment: Environment) {
        this.dialogService.open(EnvironmentEditModalComponent, {
            data: environment,
            header: 'Editar Ambientação',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllEnvironmentsByAdmin();
        });
    }

    showView(environment: Environment) {
        this.dialogService.open(EnvironmentViewModalComponent, {
            data: environment,
            header: 'Visualizar Ambientação',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllEnvironmentsByAdmin();
        });
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedItens.forEach(user => {
            ids.push(user.id);
        });
        this.environmentsService.deleteAll("environments", { "ids": ids }).then(() => {
            this.selectedItens.length = 0;
            this.loadAllEnvironmentsByAdmin();
        },
            (error) => {
                this.swalDeleteFail();
            });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(environment: Environment) {
        environment.appearanceId = environment.appearance.id;
        environment.styleId = environment.style.id;
        environment.appearanceWallId = environment.appearanceWall.id;
        // environment.styleWallId = environment.styleWall.id;
        this.environmentsService.update("environments", environment).then(() => {
            this.loadAllEnvironmentsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllEnvironmentsByAdmin();
        });
    }

    editStatus(environment: Environment) {
        if(environment.isVisible === true){
            this.edit(environment);
        } else {
            environment.appearanceId = environment.appearance.id;
            environment.styleId = environment.style.id;
            environment.appearanceWallId = environment.appearanceWall.id;
            Swal.fire({
                title: 'Você tem certeza que deseja realizar a desativação?',
                text: "Esta ação fará com que desative a ambientação equivalente em todos os whitelabels",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D95536',
                confirmButtonText: 'Desativar',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: false
              }).then((result) => {
                console.log(result);
                if (result.value) {
                    this.environmentsService.update("environments", environment).then(() => {
                        this.loadAllEnvironmentsByAdmin();
                    }, (error) => {
                        var text = '';
                        error.error['messages'].forEach((item, index) => {
                            text += item;
                            if (index < error.error['messages'].length - 1) text += ', ';
                        });
                        Swal.fire({
                            title: 'Edição Não Realizada!',
                            text: text,
                            type: 'warning',
                            confirmButtonColor: '#D95536',
                            allowOutsideClick: false
                        });
                        this.loadAllEnvironmentsByAdmin();
                    });
                } else if(result.dismiss){
                    environment.isVisible = true;
                }
              })
        }
    }

    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }


    aparencias = [];
    estilos = [];
    all = [];
    filterObject(event, dt) {
        this.environments = this.allEnvironments;
        let tempObj: Environment[] = [];
        switch (dt) {
            case 'appearance':
                this.environments.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.appearance) === JSON.stringify(element)) {
                            tempObj.push(val);

                        }
                    });
                });
                this.aparencias = tempObj;
                break;
            case 'style':
                this.environments.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.style) === JSON.stringify(element)) {
                            tempObj.push(val);
                        }
                    });
                });
                this.estilos = tempObj;
                break;
            case 'global':
                let ev = event.toUpperCase();
                this.environments.forEach(val => {
                    if ((val.appearance.name.toUpperCase().includes(ev)) || (val.style.name.toUpperCase().includes(ev)) || (val.descriptionFloor.toUpperCase().includes(ev)) || (val.descriptionWall.toUpperCase().includes(ev))) {
                        tempObj.push(val);
                    }
                });
                this.all = tempObj;
        }

        if (this.all.length != 0) {
            this.environments = this.all;
        } else {
            if ((this.aparencias.length != 0) && (this.estilos.length != 0)) {
                this.environments = this.aparencias.filter(value => -1 !== this.estilos.indexOf(value));
            } else {
                if (this.aparencias.length == 0) {
                    this.environments = this.estilos;
                    if (this.estilos.length == 0) {
                        this.environments = this.allEnvironments;
                    }
                } else if (this.estilos.length == 0) {
                    this.environments = this.aparencias;
                    if (this.aparencias.length == 0) {
                        this.environments = this.allEnvironments;
                    }
                }
            }
        }
    }

    showProductExclusive(environment: Environment){
        this.dialogService.open(EnvironmentProductComponent, {
            data: environment,
            header: 'Produtos exclusivos da ambientação cuja descrição piso é : '+ environment.descriptionFloor,
            width: '80%',
            contentStyle: { "max-height": "80vh", "overflow-y": "auto", "background-color": "#FAFAFA", "min-height": "30vh"}
        }).onClose.subscribe(() => {
            this.loadAllEnvironmentsByAdmin();
        });
    }
}
