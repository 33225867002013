import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: ApiService
    ) {
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            email: ['', Validators.required],
            role: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(4)]]
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        this.userService.register("users",this.registerForm.value)
            .then(
                () => {
                    this.router.navigate(['/home']);
                },
                () => {
                    this.loading = false;
                });
    }
}
