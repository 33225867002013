import { Component } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

import { PreExistence } from "../preexistence/preexistence";
import { Product } from "../product/product";
import { ProductApplication } from "../productApplication/productApplication";
import { AppliedProduct } from "./appliedProduct";

@Component({
    templateUrl: './appliedProductView.modal.html',
})
export class AppliedProductViewModalComponent {
    viewForm: FormGroup;
    loading = false;
    submitted = false;
    binary: SelectItem[];
    optionsType: SelectItem[];
    preexistences: PreExistence[];
    products: Product[];
    productApplications: ProductApplication[];
    appliedProduct: AppliedProduct;
    selectedPreexistences: PreExistence[] = [];
    constructor(
        private apiService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        this.apiService.getAll("preexistences").then(result => {
            this.preexistences = result['result']
            this.preexistences.forEach(e =>{
                if(this.config.data['preexistences'].some(elem => elem['id'] == e['id'])){
                    this.selectedPreexistences.push(e);
                }
            });
        });
        this.apiService.getAll("products", true).then(result =>{
            this.products = result['result'];
            this.products.forEach(element => {
                delete element.color;
                delete element.productCategory;
                delete element.manufacturer;
                delete element.brand;
                delete element.standard;
                delete element.productProductSpecifics;
                delete element['packagedProducts'];
                delete element['appliedProduct'];
                delete element['hasCompleted'];
            });
        });
        this.apiService.getAll("productApplications").then(result => {
            this.productApplications = result['result']
            this.productApplications.forEach(element => {
                delete element.productCategory;
            });
        });
     }

    ngOnInit() {
        this.appliedProduct = this.config.data;
        
        this.viewForm = this.formBuilder.group({
            product: [{value: '', disabled: true}],
            productApplication: [{value: '', disabled: true}],
            externalLocation: [{value: '', disabled: true}],
            commercialUse: [{value: '', disabled: true}],
            waterResistant: [{value: '', disabled: true}],
            hasInclusionCriteria: [{value: '', disabled: true}],            
            consumptionObservations: [{value: '', disabled: true}],
            complementaryApplication1: [{value: '', disabled: true}],
            complementaryApplication2: [{value: '', disabled: true}],
            complementaryApplication3: [{value: '', disabled: true}],
            preexistences: [{value: '', disabled: true}]
        });

        this.binary = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

    }

    get f() {
        return this.viewForm.controls;
    }

    close() {
        this.ref.close();
    }
}