import {Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
    selector: '[autofocusproref]'
})

//https://www.codementor.io/yomateo/auto-focus-with-angular-7-the-directive-osfcl7rrv
//https://stackoverflow.com/questions/41873893/angular2-autofocus-input-element

export class AutofocusDirective implements AfterViewInit {
    constructor(private element: ElementRef){

    }

    ngAfterViewInit(){
        this.element.nativeElement.focus();
    }
}