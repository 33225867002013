import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component } from "@angular/core";
import { DynamicDialogRef } from "primeng/api";
import { S3Service } from "src/app/services/s3.service";
import Swal from 'sweetalert2';
import { ApiService } from "src/app/services/api.service";
import { first } from "rxjs/operators";

@Component({
    templateUrl: './retailAdd.modal.html',
})
export class RetailAddModalComponent {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    tempUrl: any;

    constructor(
        public ref: DynamicDialogRef,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private retailService: ApiService
    ) {

    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            cnpj: [null, Validators.compose([Validators.minLength(18), Validators.maxLength(18), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            municipalRegistration: ['', Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            stateRegistration: ['', Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            telephone: [null, Validators.compose([Validators.minLength(14), Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            address: [null, Validators.compose([Validators.maxLength(300), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            site: [null, Validators.compose([Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            email: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.email, Validators.pattern(/^((?!\s{2,}).)*$/)])],
            facebook: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            instagram: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            contactName: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            contactEmail: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.email, Validators.pattern(/^((?!\s{2,}).)*$/)])],
            contactCell: [null, Validators.compose([Validators.minLength(14), Validators.maxLength(18), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            logoUrl: ['']
        });
    }

    onSubmit() {
        this.submitted = true;
        this.registerForm.value.logoUrl = this.tempUrl;
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;
        this.retailService.register("retails", this.registerForm.value)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    get f() {
        return this.registerForm.controls;
    }

    deleteImg() {
        this.tempUrl = null;
        this.registerForm.value.imgUrl1 = null;
        document.getElementById("logoUrl")['value'] = "";
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }

    close() {
        this.ref.close();
    }

}