export const config = {
    // baseUrl: process.env.API_URL || 'https://api.pro-reforma.net/api/'
    // baseUrl: 'http://localhost:80/api/'
    //baseUrl: process.env.API_URL || 'http://13.67.229.50:3000/api/'
    baseUrl: process.env.API_URL || 'https://api.pro-reforma.net/api/'

};







