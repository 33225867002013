import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DialogService, MenuItem, SelectItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from 'src/app/services/api.service';
import { Ambience } from 'src/app/entities/ambience/ambience';
import { AmbienceModalComponent } from 'src/app/entities/ambience/ambienceAdd.modal';
import { AmbienceEditModalComponent } from 'src/app/entities/ambience/ambienceEdit.modal';
import { AmbienceViewModalComponent } from 'src/app/entities/ambience/ambienceView.modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ambience',
  templateUrl: './ambience.component.html',
  providers: [DialogService],
  styleUrls: ['./ambience.component.css']
})
export class AmbienceComponent implements OnInit {
  ambiences: Ambience[]
  items: MenuItem[];
  cr: any;
  cols: any[];
  colsToggle: any[];
  selectedColumns: any[];
  selectedItens: Ambience[] = [];
  status: SelectItem[];
  binary: SelectItem[];
  useType: SelectItem[];
  localType: SelectItem[];
  ambienceType: SelectItem[];
  optionsThreePoints: MenuItem[];
  useTypeOptions = [{ label: 'Residencial', value: 0 }, { label: 'Comercial', value: 1 }];
  localTypeOptions = [{ label: 'Interna', value: 0 }, { label: 'Externa', value: 1 }];
  ambienceTypeOptions = [{ label: 'Residencial', value: 0 }, { label: 'Comercial', value: 1 }];
  nameFilter = '';
  slipFilter = '';
  coldFilter = '';
  waterFilter = '';
  scratchFilter = '';
  stainFilter = '';
  visibleFilter = '';
  useFilter = '';
  localFilter = '';
  ambienceFilter = '';
  updateFilter = '';
  rowsPerPageOptions;

  ptBr = {
    firstDayOfWeek: 0,
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
  };

  constructor(
    private userService: ApiService,
    private ambienceService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialogService: DialogService) {
  }

  ngOnInit() {
    this.userService.getById("users", localStorage.getItem('currentUserId'))
      .then((cur) => {
        this.cr = cur['result'];
        this.loadAdminAllAmbiences();
      });

    this.cols = [
      { field: 'name', header: 'Nome' },
      { field: 'waterResistant', header: 'Resistência a água' },
      { field: 'nonSlip', header: 'Antidederrapante' },
      { field: 'scratchResistant', header: 'Resistência a riscos' },
      { field: 'stainResistant', header: 'Resistência a abrasão' },
      { field: 'cold', header: 'Piso frio' },
      { field: 'useType', header: 'Tipo de uso' },
      { field: 'localType', header: 'Tipo de local' },
      { field: 'ambienceType', header: 'Tipo de ambiente' },
      { field: 'isVisible', header: 'Status' },
      { field: 'updatedAt', header: 'Modificado' },];

    //Check responsiveness to adjust columns to display on mobile
    var width = window.innerWidth;
    if (width <= 500) {
      this.colsToggle = [
        { field: 'name', header: 'Nome' },
        { field: 'isVisible', header: 'Status' }
      ];
    }
    else {
      this.colsToggle = [
        { field: 'name', header: 'Nome' },
        { field: 'useType', header: 'Tipo de uso' },
        { field: 'localType', header: 'Tipo de local' },
        { field: 'isVisible', header: 'Status' },
        { field: 'updatedAt', header: 'Modificado' }
      ];
    }

    this.status = [
      { label: 'Ativo', value: true },
      { label: 'Inativo', value: false }
    ];

    this.binary = [
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' }
    ];

    this.ambienceType = [
      { label: 'Molhado', value: 'Molhado' },
      { label: 'Seco', value: 'Seco' }
    ];

    this.localType = [
      { label: 'Interna', value: 'Interna' },
      { label: 'Externa', value: 'Externa' }
    ];

    this.useType = [
      { label: 'Residencial', value: 'Residencial' },
      { label: 'Comercial', value: 'Comercial' }
    ];

    this.selectedColumns = this.colsToggle;
  }

  private loadAdminAllAmbiences() {
    this.ambienceService.getAll("ambiences", true).then((ambiences) => {
      this.rowsPerPageOptions = undefined;
      if(ambiences['result'].length > 10 && ambiences['result'].length < 25){
          this.rowsPerPageOptions = [10, 25];
      }
      else if(ambiences['result'].length > 25){
          this.rowsPerPageOptions = [10, 25, ambiences['result'].length];
      }
      this.ambiences = ambiences['result'];
    });
  }

  clearFilters(dt){
    this.nameFilter = '';
    this.slipFilter = '';
    this.coldFilter = '';
    this.waterFilter = '';
    this.scratchFilter = '';
    this.stainFilter = '';
    this.visibleFilter = '';
    this.useFilter = '';
    this.localFilter = '';
    this.ambienceFilter = '';
    this.updateFilter = '';
    dt.reset();
  }

  filterUpdatedAtOnTable(value, dt) {
    value = value.getTime();
    dt.value.forEach(val => {
      if (typeof val.updatedAt === "string") {
        val.updatedAt = new Date(val.updatedAt).getTime();
      }
    });
    dt.filter(value, 'updatedAt', 'lte');
  }

  options(row: any) {
    this.optionsThreePoints = [
      { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
      { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
      { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
      { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
    ]
    return this.optionsThreePoints;
  }

  delete(id: string) {
    Swal.fire({
      title: 'Você tem certeza que deseja realizar a deleção?',
      text: "Esta ação não poderá ser revertida!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#D95536',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.ambienceService.delete("ambiences", id).then(() => {
          this.loadAdminAllAmbiences();
        },
          (error) => {
            Swal.fire({
              title: 'Deleção Não Realizada!',
              text: "Verifique se há relação com outro objeto!",
              type: 'warning',
              confirmButtonColor: '#D95536',
              allowOutsideClick: false,
            })
          });
      }
    })
  }

  showAdd() {
    this.dialogService.open(AmbienceModalComponent, {
      header: 'Cadastrar Ambiente',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAdminAllAmbiences();
    });
  }

  deleteAll() {
    let ids: string[] = [];
    this.selectedItens.forEach(ambience => {
      ids.push(ambience.id);
    });
    this.ambienceService.deleteAll("ambiences", { "ids": ids }).then(() => {
      this.selectedItens.length = 0;
      this.loadAdminAllAmbiences();
    }, (error) => {
      Swal.fire({
        title: 'Deleção Não Realizada!',
        text: "Verifique se há relação com outro objeto!",
        type: 'warning',
        confirmButtonColor: '#D95536',
        allowOutsideClick: false
      });
    });
  }

  showEdit(ambience: Ambience) {
    this.dialogService.open(AmbienceEditModalComponent, {
      data: ambience,
      header: 'Editar Ambiente',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAdminAllAmbiences();
    });
  }

  showView(ambience: Ambience) {
    this.dialogService.open(AmbienceViewModalComponent, {
      data: ambience,
      header: 'Visualizar Ambiente',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAdminAllAmbiences();
    });
  }
  makeIdToDuplicateRow(length) {
    let result = '';
    let characters = '123456790';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result = "copy_" + result + "_";
    return result;
  }

  duplicateRow(row: Ambience) {
    this.ambienceService.register("ambiences", <Ambience>{
      "name": this.makeIdToDuplicateRow(5) + row['name'],
      "nonSlip": row['nonSlip'],
      "ambienceType": row['ambienceType'],
      "useType": row['useType'],
      "localType": row['localType'],
      "scratchResistant": row['scratchResistant'],
      "stainResistant": row['stainResistant'],
      "waterResistant": row['waterResistant'],
      "cold": row['cold']
    }).then(() => {
      this.loadAdminAllAmbiences();
    },
    (er)=> {
        
      if(er.status === 422){
          Swal.fire({
              title: 'Duplicação não realizada!',
              text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
              type: 'error',
              confirmButtonColor: '#D95536',
              allowOutsideClick: false
          });
      }
  });
  }

  edit(ambience: Ambience) {
    this.ambienceService.update("ambiences", ambience).then(() => {
      this.loadAdminAllAmbiences();
    }, (error) => {
      var text = '';
      error.error['messages'].forEach((item, index) => {
        text += item;
        if (index < error.error['messages'].length - 1) text += ', ';
      });
      Swal.fire({
        title: 'Edição Não Realizada!',
        text: text,
        type: 'warning',
        confirmButtonColor: '#D95536',
        allowOutsideClick: false
      });
      this.loadAdminAllAmbiences();
    });
  }

  editmode = false;
  disabledEditTable() {
    var inputTable = document.getElementsByClassName("disabledInput");
    if (!this.editmode) {
      this.editmode = true;
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table");
        inputTable[i].classList.add("form-pro-table-edit");
      }
    }
    else {
      this.editmode = false
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table-edit");
        inputTable[i].classList.add("form-pro-table");
      }
    }
  }
}
