import { Component } from "@angular/core";
import { User } from "./user";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { S3Service } from '../../services/s3.service';
import Swal from 'sweetalert2';

@Component({
    templateUrl: './userAdd.modal.html',
})
export class UserAddModalComponent {
    user: User[];
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    tempUrl: any;

    constructor(
        private userService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            email: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100), Validators.email])],
            role: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(100), Validators.pattern(/(?=.*[a-zA-Z])(?=.*[0-9]+).*/)]],
            imgUrl: [''],
            socialName: [null, Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            cnpj: [null, Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            address: [null, Validators.compose([Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            telephone: [null, Validators.compose([Validators.maxLength(20), Validators.pattern(/^((?!\s{2,}).)*$/)])]
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        this.registerForm.value.imgUrl = this.tempUrl;
        this.userService.register("users", this.registerForm.value)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    deleteImg() {
        this.tempUrl = null;
        this.registerForm.value.imgUrl1 = null;
        document.getElementById("imgUrl")['value'] = "";
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }
}