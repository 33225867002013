import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

import { ApiService } from 'src/app/services/api.service';

import { S3Service } from '../../services/s3.service';

import Swal from 'sweetalert2';
import { Standard } from '../standard/standard';
import { Manufacturer } from '../manufacturer/manufacturer';
import { ProductCategory } from '../productCategory/productCategory';
import { Color } from '../color/color';
import { Brand } from '../brand/brand';
import { ProductCategorySpecification } from '../productCategorySpecification/productCategorySpecification';
import { Product } from './product';

@Component({
    templateUrl: './productView.modal.html',
    styleUrls: ['./productAdd.modal.css'],
})
export class ProductViewModalComponent implements OnInit {
    product: Product;
    viewForm: FormGroup;
    loading = false;
    submitted = false;
    standards: Standard[];
    brand: Brand;
    manufacturers: Manufacturer[];
    brands: Brand[];
    categories: ProductCategory[];
    colors: Color[];
    binary: SelectItem[];
    binaryRequired: SelectItem[];
    specifics: ProductCategorySpecification[];
    resultSpecificsProduct: any[];
    selectedColors: Color[] = [];
    selectedStandards: Standard[] = [];
    productSpecificsData = [];
    categoryFixedId: string;
    type: SelectItem[];
    isService: any;

    constructor(
        private productService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        this.productService.getAll("standards", true).then(st => this.standards = st['result']);
        this.productService.getAll("manufacturers").then(manu => {
            this.manufacturers = manu['result']
            this.manufacturers.forEach(element => {
                delete element['brands'];
            });
        });
        this.productService.getAll("productCategories").then(cat => {
            this.categories = cat['result']
            this.categories.forEach(element => {
                delete element['hasCompleted'];
            });
        });
        this.productService.getAll("colors").then(color => {
            this.colors = color['result'];
            this.colors.forEach(e => {
                if (this.config.data['color'].some(elem => elem['id'] == e['id'])) {
                    this.selectedColors.push(e);
                }
            });
            this.config.data['color'] = this.selectedColors;
        });
        this.productService.getAll("standards").then(standard => {
            this.standards = standard['result'];
            this.standards.forEach(e => {
                if (this.config.data['standard'].some(elem => elem['id'] == e['id'])) {
                    this.selectedStandards.push(e);
                }
            });
            this.config.data['standard'] = this.selectedStandards;
        });
        this.resultSpecificsProduct = [];
    }

    ngOnInit() {
        this.campo = [];
        this.prodEsp = [];
        this.resultSpecificsProduct = [];
        this.product = this.config.data;
        this.categoryFixedId = this.product.productCategoryId;

        this.loadSpecifics();

        this.viewForm = this.formBuilder.group({
            name: [{value: '', disabled: true}],
            model: [{value: '', disabled: true}],
            manufacturerCode: [{value: '', disabled: true}],
            manufacturerLink: [{value: '', disabled: true}],
            manualPdf: [{value: '', disabled: true}],
            impactAppearance: [{value: '', disabled: true}],
            commercialColor: [{value: '', disabled: true}],
            benefits: [{value: '', disabled: true}],
            technicalObservations: [{value: '', disabled: true}],
            productCategory: [{value: '', disabled: true}],
            manufacturer: [{value: '', disabled: true}],
            standard: [{value: '', disabled: true}],
            color: [{value: '', disabled: true}],
            brand: [{value: '', disabled: true}],
            isService: [{value: '', disabled: true}],
            allColorsSelected: [{value: '', disabled: true}],
            allStandardsSelected: [{value: '', disabled: true}]
        });

        this.binary = [
            { label: '', value: '' },
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.type = [
            { label: 'Produto', value: false},
            { label: 'Serviço', value: true}
        ];



        this.loadBrands();
    }

    get f() {
        return this.viewForm.controls;
    }

    createItem(): FormGroup {
        return this.formBuilder.group({
            value: ''
        });
    }

    bool: boolean;

    addItem(ev: any) {
        let self = this;
        self.bool = true;
        for (let i = 0; i < this.resultSpecificsProduct.length; i++) {            
            if (this.resultSpecificsProduct[i].name == ev.name) {
                this.resultSpecificsProduct[i].value = ev.value;
                this.resultSpecificsProduct[i].idSpecification = ev.idSpecification;
                self.bool = false;
            }
        }
        if (self.bool) {
            self.resultSpecificsProduct.push({ name: ev.name, id: ev.id, value: ev.value, idSpecification: ev.idSpecification });
        } else {
            self.bool = true;
        }
    }

    specificsSwalError() {
        for (let i = 0; i < this.campo.length; i++) {
            if (this.campo[i].value == null || this.campo[i].value == "") return true;
        }
        return false;
    }

    close() {
        this.ref.close();
    }

    loadBrands() {
        this.productService.listChildByEntityId(this.product.manufacturer.id, '/manufacturers/brandsByManufacturer/').then((brands) => {
            this.brands = brands['result'];
        })
    }

    makeId(length) {
        let result = '';
        let characters = '123456790-qwertyuiopasdfghjklçzxcvbnmQWERTYUIOPASDFGHJKLÇZXCVBNM';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    tempSpecifics(specifics, prodEsp, val) {
        let optList = [];
        if (specifics.specificationType == 'Lista') {
            specifics['productCategorySpecificationList'].forEach(el => {
                optList.push({ name: specifics.name, id: prodEsp.id, value: el.name, optionsList: [], idSpecification: specifics.id });
            });
            optList.forEach(elm => {
                elm.optionsList = optList;
            });
            this.campo.push({ name: specifics.name, id: prodEsp.id, value: val, optionsList: optList, idSpecification: specifics.id });
        } else {
            this.campo.push({ name: specifics.name, id: prodEsp.id, value: val, optionsList: [], idSpecification: specifics.id })
        }
    }
    campo = [];
    prodEsp = [];
    loadSpecifics() {
        let checkNewFields = false;
        this.campo = [];
        this.productService.listChildByEntityId(this.product.productCategory.id, 'productCategories/specificsByCategoryId/').then(specifics => {
            this.specifics = specifics['result'];

            this.productService.listChildByEntityId(this.product.id, 'productProductCategorySpecifications/getAllByProdId/').then(prodE => {
                this.prodEsp = prodE['result'];
                for (let i = 0; i < this.specifics.length; i++) {
                    if (this.categoryFixedId == this.product.productCategory.id) {
                        for (let j = 0; j < this.prodEsp.length; j++) {
                            if (this.specifics[i].id === this.prodEsp[j].productCategorySpecificationId) {
                                this.tempSpecifics(this.specifics[i], this.prodEsp[j], this.prodEsp[j].value);
                            }
                        }
                    } else {
                        checkNewFields = true;
                        let optList = [];
                        if (this.specifics[i].specificationType == 'Lista') {
                            this.specifics[i]['productCategorySpecificationList'].forEach(el => {
                                optList.push({ name: this.specifics[i].name, id: this.makeId(5), value: el.name, optionsList: [], idSpecification: this.specifics[i].id });
                            });
                            optList.forEach(elm => {
                                elm.optionsList = optList;
                            });
                            this.campo.push({ name: this.specifics[i].name, id: optList[0].id, value: optList[0].value, optionsList: optList, idSpecification: this.specifics[i].id });
                        } else {
                            let val;
                            if (this.specifics[i].specificationType == 'Número') val = '0';
                            if (this.specifics[i].specificationType == 'Texto') val = 'vazio';
                            this.campo.push({ name: this.specifics[i].name, id: this.makeId(5), value: val, optionsList: [], idSpecification: this.specifics[i].id })
                        }
                    }
                }
                if (checkNewFields) {
                    this.campo.forEach(el => this.addItem(el));
                } else if (this.campo.length == 0) {
                    for (let i = 0; i < this.specifics.length; i++) {
                        for (let j = 0; j < this.prodEsp.length; j++) {
                            this.tempSpecifics(this.specifics[i], this.prodEsp[j], null);
                        }
                    }
                }
            })
        })
    }
}