import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import { EnvironmentAddModalComponent } from 'src/app/entities/environment/environmentAdd.modal';
import { EnvironmentEditModalComponent } from 'src/app/entities/environment/environmentEdit.modal';
import { EnvironmentViewModalComponent } from 'src/app/entities/environment/environmentView.modal';
import Swal from 'sweetalert2';
import { ProductApplication } from './productApplication';
import { ProductApplicationAddModalComponent } from './productApplicationAdd.modal';
import { ProductApplicationEditModalComponent } from './productApplicationEdit.modal';
import { ProductApplicationViewModalComponent } from './productApplicationView.modal';

@Component({
    templateUrl: './productApplication.component.html',
    providers: [DialogService]
})
export class ProductApplicationComponent implements OnInit {
    productApplications: ProductApplication[] = [];
    allProductApplications;
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedItens: ProductApplication[] = [];
    status: SelectItem[];
    productCategories: any[];
    productCategoryList: SelectItem[] = [];
    editmode = false;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    productCategoryFilter = null;
    statusFilter = '';
    updateFilter = '';
    rowsPerPageOptions;

    constructor(
        private productApplicationService: ApiService,
        private authenticationService: AuthenticationService,
        private environmentsService: ApiService,
        private router: Router,
        private dialogService: DialogService
    ) {
        this.productApplicationService.getAll("productCategories").then(productCategories => {
            this.productCategories = productCategories['result'];
            this.productCategories.forEach(el => {
                delete el.specifics;
                delete el.hasCompleted;
            });
        });
    }
    ngOnInit() {
        this.loadAllProductApplicationsByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.cols = [
            { orderCol: 'name', field: 'name', header: 'Nome' },
            { orderCol: 'productCategory.name', field: 'productCategory', header: 'Categoria Produto' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'name', field: 'name', header: 'Nome' },
                { orderCol: 'productCategory.name', field: 'productCategory', header: 'Categoria Produto' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
            ];
        }

        this.selectedColumns = this.colsToggle;
    }


    clearFilters(dt) {
        this.nameFilter = '';

        this.productCategoryFilter = null;
        this.statusFilter = '';
        this.updateFilter = '';
        this.productApplications = this.allProductApplications;
        dt.reset();
    }


    onRowSelect(event, row) {
        this.selectedItens = row;
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllProductApplicationsByAdmin();
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.productApplicationService.delete("productApplications", id).then(() => {
                    this.loadAllProductApplicationsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail(error);
                    });
            }
        })



    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: ProductApplication) {
        this.environmentsService.register("productApplications", {
            "name": this.makeIdToDuplicateRow(5) + row['name'],
            "productCategoryId": row['productCategoryId'],
            "isVisible": false,
        }).then(() => {
            this.loadAllProductApplicationsByAdmin();
        },
            (er) => {

                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            });
    }

    loadFilters() {
        let productCategoryName = [];
        this.productCategoryList = [];

        for (var i = 0; i < this.productApplications.length; i++) {
            if (!productCategoryName.includes(this.productApplications[i].productCategory.name)) {
                productCategoryName.push(this.productApplications[i].productCategory.name);
                this.productCategoryList.push({ "label": this.productApplications[i].productCategory.name, "value": this.productApplications[i].productCategory.name });
            }
        }
    }

    private loadAllProductApplicationsByAdmin() {
        this.productApplicationService.getAll("productApplications", true).then(productApplications => {
            
            this.rowsPerPageOptions = undefined;
            if (productApplications['result'].length > 10 && productApplications['result'].length < 25) {
              this.rowsPerPageOptions = [10, 25];
            }
            else if (productApplications['result'].length > 25) {
              this.rowsPerPageOptions = [10, 25, productApplications['result'].length];
            }
            this.productApplications = productApplications['result'];

            this.allProductApplications = productApplications['result'];
            this.loadFilters();
        });
    }

    showAdd() {
        this.dialogService.open(ProductApplicationAddModalComponent, {
            header: 'Cadastrar Aplicação',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllProductApplicationsByAdmin();
        });
    }

    showEdit(productApplication: ProductApplication) {
        this.dialogService.open(ProductApplicationEditModalComponent, {
            data: productApplication,
            header: 'Editar Aplicação',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllProductApplicationsByAdmin();
        });
    }

    showView(productApplication: ProductApplication) {
        this.dialogService.open(ProductApplicationViewModalComponent, {
            data: productApplication,
            header: 'Visualizar Aplicação',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllProductApplicationsByAdmin();
        });
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedItens.forEach(user => {
            ids.push(user.id);
        });
        this.productApplicationService.deleteAll("productApplications", { "ids": ids }).then(() => {
            this.selectedItens.length = 0;
            this.loadAllProductApplicationsByAdmin();
        },
            (error) => {
                this.swalDeleteFail(error);
            });
    }

    swalDeleteFail(er) {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: er,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(productApplication: ProductApplication) {
        productApplication.productCategoryId = productApplication.productCategory.id;
        this.environmentsService.update("productApplications", productApplication).then(() => {
            this.loadAllProductApplicationsByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllProductApplicationsByAdmin();
        });
    }

    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    all = [];
    filterObject(event) {
        this.productApplications = this.allProductApplications;
        let tempObj: ProductApplication[] = [];
        let ev = event.toUpperCase();
        this.productApplications.forEach(val => {
            if ((val.productCategory.name.toUpperCase().includes(ev)) || (val.name.toUpperCase().includes(ev))) {
                tempObj.push(val);
            }
        });
        this.all = tempObj;


        if (this.all.length != 0) {
            this.productApplications = this.all;
        }
    }
}
