import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from "src/app/services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { GroupSpecification } from './groupSpecification';
import { Globals } from 'src/app/globals';
import { Question } from '../question/question';

@Component({
    selector: 'app-group-specification',
    templateUrl: './groupSpecification.component.html',
    styleUrls: ['groupSpecification.component.css'],
    providers: [DialogService]
})
export class GroupSpecificationComponent implements OnInit {

    activated2 = false;
    groupSpecification_pass: GroupSpecification;
    nameIt: string;
    listItems: GroupSpecification[] = [];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedListItems: GroupSpecification[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    registerForm: FormGroup;
    optionsThreePoints: MenuItem[];
    ops: SelectItem[];
    questions: Question[] = [];
    selectedQuestions: Question[] = [];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    questionFilter = null;
    typeFilter = null;
    statusFilter = '';
    updateFilter = '';
    intervention: any;
    type: string;

    constructor(
        private apiService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private globals: Globals,
        private dialogService: DialogService,
    ) {
    }

    ngOnInit() {
        this.intervention = this.config.data;
        this.globals.activated_group = false;
        this.globals.activated_comb_answ = false;
        this.loadAllListItemsByAdmin();

        this.ops = [
            { label: 'Filtro', value: 'Filtro' },
            { label: 'Bifurcação', value: 'Bifurcação' },
            { label: 'Neutro', value: 'Neutro' }
        ];

        this.apiService.listChildByEntityId( this.intervention.id, "getQuestionsByInterventionId/").then( result => {

            for (var i = 0; i < result['result'].questions.length; i++) {
                delete result['result'].questions[i].InterventionQuestion;
            }
            this.questions = result['result'].questions;
            if(this.questions.length == 0){
                this.type = 'Neutro';
                this.ops = [{ label: 'Neutro', value: 'Neutro' }];
            } else {
                this.ops = [
                    { label: 'Filtro', value: 'Filtro' },
                    { label: 'Bifurcação', value: 'Bifurcação' },
                    { label: 'Neutro', value: 'Neutro' }
                ];
            }
            
        })

        this.registerForm = this.formBuilder.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            questions: [''],
            type: ['', Validators.required]
        });

        this.cols = [
            { field: 'name', header: 'Nome' },
            { field: 'type', header: 'Tipo' },
            { field: 'questions', header: 'Perguntas' },
            { field: 'combinationAnswer', header: 'Combinação Resposta' },
            { field: 'isVisible', header: 'Status' },
            { field: 'updatedAt', header: 'Modificado' },
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'combinationAnswer', header: 'Combinação Resposta' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'name', header: 'Nome' },
                { field: 'type', header: 'Tipo' },
                { field: 'questions', header: 'Perguntas' },
                { field: 'combinationAnswer', header: 'Combinação Resposta' },
                { field: 'isVisible', header: 'Status' },
                { field: 'updatedAt', header: 'Modificado' }
            ];
        }


        this.selectedColumns = this.colsToggle;

    }

    get f() {
        return this.registerForm.controls;
    }


    onRowSelect(event, row) {
        this.selectedListItems = row;
    }


    clearFilters(dt) {
        this.nameFilter = '';
        this.typeFilter = null;
        this.questionFilter = null;
        this.statusFilter = '';
        this.updateFilter = '';
        dt.reset();
    }

    options(row: any) {
        this.optionsThreePoints = [
            // { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.apiService.delete("groups", id).then(() => {
                    this.loadAllListItemsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail(error);
                    });
            }
        })
    }

    closeModal() {
        this.ref.close();
    }

    loadAllListItemsByAdmin() {
        this.apiService.listChildByEntityId(this.intervention.id, 'groups/getGroupsByInterventionId/').then(list => {
            // this.apiService.getAll('groups').then(list => {
            console.log("groupa", list['result']);
            
            this.globals.groups = list['result'];


        });
    }

    showAdd() {
        document.getElementById('btns-item-group').style.display = 'none'
    }

    registerListItem() {
        let objReg = this.registerForm.value;
        objReg.interventionId = this.intervention.id;
        let arrayQuestions = [];
        if(objReg.questions){
            objReg.questions.forEach(element => {
                arrayQuestions.push(element.id);
            });
        }

        objReg.questionsIds = arrayQuestions;

        this.apiService.register("groups", objReg)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.registerForm.reset();
                            this.nameIt = null
                            this.loadAllListItemsByAdmin();
                            // if(success['result'].type == 'Neutro'){

                            // }
                            // this.showCombinationAnswer(success['result']);
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                });
    }

    close() {
        this.nameIt = null;
        document.getElementById('btns-item-group').style.display = 'initial';
        this.registerForm.reset();
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedListItems.forEach(user => {
            ids.push(user.id);
        });
        this.apiService.deleteAll("groups", { "ids": ids }).then(() => {
            this.selectedListItems.length = 0;
            this.loadAllListItemsByAdmin();
        }, (error) => {
            this.swalDeleteFail(error);
        });
    }

    swalDeleteFail(er) {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: er.error.messages,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: GroupSpecification) {
        this.apiService.register("groups", { id: row['id'], "name": this.makeIdToDuplicateRow(5) + row['name'], type: row['type'], "isVisible": row['isVisible'] }).then(() => {
            this.loadAllListItemsByAdmin();
        },
            (er) => {
                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            }
        );
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllListItemsByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(listItem: GroupSpecification) {

        this.apiService.update("groups", listItem).then(() => {
            this.loadAllListItemsByAdmin()
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllListItemsByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    showCombinationAnswer(row: any) {
        document.getElementById('grp').style.display = 'initial';
        document.getElementById('grp_spec').style.display = "none";
        document.getElementById('comb_answ').style.display = "initial";
        document.getElementById('space').style.display = "initial";
        this.config.header = 'Combinações de Respostas do grupo: ' + row.name;
        this.groupSpecification_pass = row;
        if(row.type == 'Neutro') this.groupSpecification_pass['neutral'] = true;
        this.globals.activated_group = !this.globals.activated_group;
    }


    backGroup() {

        document.getElementById('space').style.display = "none";
        document.getElementById('space2').style.display = "none";
        document.getElementById('grp').style.display = "none";
        document.getElementById('comb_answ').style.display = "none";
        document.getElementById('comb_answ2').style.display = "none";
        document.getElementById('grp_spec').style.display = "initial";
        document.getElementById('specification').style.display = "none";
        this.config.header = 'Grupos da intervenção: ' + this.intervention.name;
        if (this.globals.activated_group) {
            this.globals.activated_group = !this.globals.activated_group;
            this.globals.activated_comb_answ = false;
        }
        this.loadAllListItemsByAdmin();
    }

    backCombinationAnswers() {
        if (this.globals.activated_comb_answ) {
            document.getElementById('grp').style.display = 'initial';
            document.getElementById('comb_answ').style.display = "initial";
            document.getElementById('comb_answ2').style.display = "none";
            document.getElementById('space').style.display = "initial";
            document.getElementById('space2').style.display = "none";
            document.getElementById('specification').style.display = "none";
            document.getElementById('specific').style.display = "none"
            document.getElementById('combination_answer').style.display = "initial";
            this.apiService.listChildByEntityId(this.groupSpecification_pass.id, 'groups/getCombinationsByGroupId/').then(list => {

                let combInd = 1;
                this.globals.combination_answers = [];
                list['result'].combinations.forEach(element => {
                    for (let i = 0; i < element.length; i++) {
                        let combina = "Combinação " + combInd;
                        this.globals.combination_answers.push({ "name": combina, "questionId": element[i].questionId, "answerId": element[i].answerId, "question": element[i].question, "answer": element[i].answer, "id": element[i].combinationId, "combinationId": element[i].combinationId, "specifications": element[i].specifications, "isVisible": element[i].isVisible })
                    }
                    combInd++;
                });

                this.config.header = "Combinações Respostas do grupo: " + this.groupSpecification_pass.name;
            });
            this.loadAllListItemsByAdmin();
            this.globals.activated_comb_answ = false;

        }
    }

}
