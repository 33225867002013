import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Appearance } from './appearance';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DialogService, SelectItem } from "primeng/api";
import { AppearanceAddModalComponent } from "./appearanceAdd.modal";
import { AppearanceEditModalComponent } from "./appearanceEdit.modal";
import { AppearanceViewModalComponent } from "./appearanceView.modal";
import { MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';

@Component({
    templateUrl: 'appearance.component.html',
    styleUrls: ['./appearance.component.css'],
    providers: [DialogService]
})
export class AppearanceComponent implements OnInit {
    appearances: Appearance[] = [];
    allAppearances: Appearance[];
    items: MenuItem[];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedAppearances: Appearance[] = [];
    status: SelectItem[];
    colors: any[];
    colorList: SelectItem[] = [];
    standards: any[];
    standardList: SelectItem[] = [];
    optionsThreePoints: MenuItem[];
    countFilters;
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    statusFilter = '';
    updateFilter = '';
    colorFilter = null;
    standardFilter = null;
    rowsPerPageOptions;

    constructor(
        private appearanceService: ApiService,
        private authenticationService: AuthenticationService,
        private colorService: ApiService,
        private standardService: ApiService,
        private router: Router,
        private dialogService: DialogService
    ) {
        this.colorService.getAll("colors").then(colors => {
            this.colors = colors['result'];
        });
        this.standardService.getAll("standards").then(standards => {
            this.standards = standards['result'];
        });
        this.countFilters = 0;
    }

    ngOnInit() {
        this.loadAllAppearancesByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.cols = [
            { orderCol: 'name', field: 'name', header: 'Nome' },
            { orderCol: 'color.name', field: 'color', header: 'Cor' },
            { orderCol: 'standard.name', field: 'standard', header: 'Padrão' },
            { orderCol: 'imgUrl', field: 'imgUrl', header: 'Imagem' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status'}
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'name', header: 'Nome' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'name', field: 'name', header: 'Nome' },
                { orderCol: 'color.name', field: 'color', header: 'Cor' },
                { orderCol: 'standard.name', field: 'standard', header: 'Padrão' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    onRowSelect(event, row) {
        this.selectedAppearances.push(row);
    }

    clearFilters(dt) {
        this.nameFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        this.colorFilter = null;
        this.standardFilter = null;
        this.appearances = this.allAppearances;
        dt.reset();
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            // { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.appearanceService.delete("appearances", id).then(() => {
                    this.loadAllAppearancesByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        })
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllAppearancesByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: Appearance) {
        this.appearanceService.register("appearances", <Appearance>{
            "name": this.makeIdToDuplicateRow(5) + row['name'], 
            "imgUrl": row['imgUrl'], 
            "colorId": row['colorId'], 
            "standardId": row['standardId'] }).then(() => {
            this.loadAllAppearancesByAdmin();
        },
            (er) => {

                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            });
    }

    loadFilters() {
        let colorName = [];
        let standardName = [];
        this.colorList = [];
        this.standardList = [];
        for (var i = 0; i < this.appearances.length; i++) {
            if (!colorName.includes(this.appearances[i].color.name)) {
                colorName.push(this.appearances[i].color.name);
                this.colorList.push({ "label": this.appearances[i].color.name, "value": this.appearances[i].color.name })
            }
            if (!standardName.includes(this.appearances[i].standard.name)) {
                standardName.push(this.appearances[i].standard.name);
                this.standardList.push({ "label": this.appearances[i].standard.name, "value": this.appearances[i].standard.name });
            }
        }
    }

    private loadAllAppearancesByAdmin() {

        this.appearanceService.getAll("appearances", true).then(appearances => {
            this.rowsPerPageOptions = undefined;
            if(appearances['result'].length > 10 && appearances['result'].length < 25){
                this.rowsPerPageOptions = [10, 25];
            }
            else if(appearances['result'].length > 25){
                this.rowsPerPageOptions = [10, 25, appearances['result'].length];
            }
            this.appearances = appearances['result'];
            this.allAppearances = appearances['result'];
            this.loadFilters();
            this.countFilters++;
        });
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }

    showAdd() {
        this.dialogService.open(AppearanceAddModalComponent, {
            header: 'Cadastrar Aparência',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllAppearancesByAdmin();
        });
    }

    showEdit(appearance: Appearance) {
        this.dialogService.open(AppearanceEditModalComponent, {
            data: appearance,
            header: 'Editar Aparência',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllAppearancesByAdmin();
        });
    }

    showView(appearance: Appearance) {
        this.dialogService.open(AppearanceViewModalComponent, {
            data: appearance,
            header: 'Visualizar Aparência',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllAppearancesByAdmin();
        });
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedAppearances.forEach(appearance => {
            ids.push(appearance.id);
        });
        this.appearanceService.deleteAll("appearances", { "ids": ids }).then(() => {
            this.selectedAppearances.length = 0;
            this.loadAllAppearancesByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    edit(appearance: Appearance) {
        appearance.colorId = appearance.color.id;
        appearance.standardId = appearance.standard.id;
        this.appearanceService.update("appearances", appearance).then((res) => {
            this.loadAllAppearancesByAdmin();
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllAppearancesByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    cores = [];
    padroes = [];
    all = [];
    filterObject(event, dt) {
        this.appearances = this.allAppearances;
        let tempObj: Appearance[] = [];
        switch (dt) {
            case 'color':
                this.appearances.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.color) === JSON.stringify(element)) {
                            tempObj.push(val);

                        }
                    });
                });
                this.cores = tempObj;
                break;
            case 'standard':
                this.appearances.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.standard) === JSON.stringify(element)) {
                            tempObj.push(val);
                        }
                    });
                });
                this.padroes = tempObj;
                break;
            case 'global':
                let ev = event.toUpperCase();
                this.appearances.forEach(val => {
                    if ((val.color.name.toUpperCase().includes(ev)) || (val.standard.name.toUpperCase().includes(ev)) || (val.name.toUpperCase().includes(ev))) {
                        tempObj.push(val);
                    }
                });
                this.all = tempObj;
        }

        if (this.all.length != 0) {
            this.appearances = this.all;
        } else {
            if ((this.cores.length != 0) && (this.padroes.length != 0)) {
                this.appearances = this.cores.filter(value => -1 !== this.padroes.indexOf(value));
            } else {
                if (this.cores.length == 0) {
                    this.appearances = this.padroes;
                    if (this.padroes.length == 0) {
                        this.appearances = this.allAppearances;
                    }
                } else if (this.padroes.length == 0) {
                    this.appearances = this.cores;
                    if (this.cores.length == 0) {
                        this.appearances = this.allAppearances;
                    }
                }
            }
        }
    }

}