import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

import { PossibleAction } from 'src/app/entities/possibleAction/possibleAction';
import { PreExistence } from 'src/app/entities/preexistence/preexistence';
import { Material } from 'src/app/entities/material/material';
import { Action } from 'src/app/entities/action/action';
import { ApiService } from 'src/app/services/api.service';

import { S3Service } from '../../services/s3.service';
import { Intervention } from 'src/app/entities/intervention/intervention';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-possible-action-modal',
    templateUrl: './possibleActionAdd.modal.html'
})
export class PossibleActionModalComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    selectedAction: Action;
    selectedMaterial: Material;
    selectedPreexistence: PreExistence;
    actions: Action[];
    materials: Material[];
    preexistences: PreExistence[];
    interventions: Intervention[];
    selectedFiles: FileList;
    tempUrl: any;

    constructor(
        private possibleActionService: ApiService,
        private actionService: ApiService,
        private materialService: ApiService,
        private preexistenceService: ApiService,
        private interventionService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        this.materialService.getAll("materials").then(material => this.materials = material['result']);
        this.actionService.getAll("actions").then(action => this.actions = action['result']);
        this.preexistenceService.getAll("preexistences").then(preexistence => this.preexistences = preexistence['result']);
        this.interventionService.getAll("interventions", true).then(result => this.interventions = result['result']);
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            action: ['', Validators.required],
            material: ['', Validators.required],
            preexistence: ['', Validators.required],
            interventions: ['', Validators.required],
            imgUrl: ['']
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    deleteImg() {
        this.tempUrl = null;
        this.registerForm.value.imgUrl1 = null;
        document.getElementById("imgUrl")['value'] = "";
    }
    onSubmit() {
        this.submitted = true;
        this.registerForm.value.imgUrl = this.tempUrl;
        this.loading = true;
        var arrayInterventions = [];
        this.registerForm.value.interventions.forEach(obj => {
            arrayInterventions.push(obj.id);
        });
        this.possibleActionService.register("possibleActions", <PossibleAction>{ "name": this.registerForm.value.name, "actionId": this.registerForm.value.action.id, "materialId": this.registerForm.value.material.id, "preexistenceId": this.registerForm.value.preexistence.id, "imgUrl": this.registerForm.value.imgUrl, "interventions": arrayInterventions })
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }
}