import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from "src/app/services/api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { CombinationAnswer } from "./combinationsAnswer";
import { ProductCategorySpecification } from "../productCategorySpecification/productCategorySpecification";
import { AppliedProduct } from '../appliedProduct/appliedProduct';
import { Globals } from 'src/app/globals';
import { GroupSpecification } from '../groupSpecification/groupSpecification';
import { Question } from '../question/question';
import { Answer } from '../answer/answer';

@Component({
    selector: 'app-combination-answer',
    templateUrl: './combinationAnswer.component.html',
    providers: [DialogService]
})
export class CombinationAnswerComponent implements OnInit {

    activated2 = false;
    combinationAnswer_pass: CombinationAnswer;
    @Input() groupSpecification: GroupSpecification;
    nameIt: string;
    listItems: CombinationAnswer[] = [];
    cr: any;
    cols: any[];
    rowGroupMetadata: any;
    colsToggle: any[];
    selectedColumns: any[];
    tempColumns: any[];
    selectedListItems: CombinationAnswer[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    registerForm: FormGroup;
    optionsThreePoints: MenuItem[];
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    questionFilter = '';
    answerFilter = '';
    statusFilter = '';
    updateFilter = '';
    questions: Question[];
    answers: Answer[];

    constructor(
        private apiService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {

    }

    ngOnInit() {
        this.globals.combination_answers = [];
        this.apiService.getAll("questions").then(result => {
            this.questions = result['result'];
            this.questions.forEach(element => {
                delete element['answers'];
            });
        });
        this.apiService.getAll("answers", true).then(result => {
            this.answers = result['result'];
            this.answers.forEach(element => {
                delete element['question'];
            });
        });

        this.loadAllListItemsByAdmin();

        this.cols = [
            { orderCol: 'name', field: 'name', header: 'Nome' },
            { orderCol: 'question.query', field: 'question', header: 'Perguntas' },
            { orderCol: 'answer.name', field: 'answer', header: 'Respostas' },
            { orderCol: 'specifications', field: 'specifications', header: 'Especificações' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' }
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { orderCol: 'name', field: 'name', header: 'Nome' },
                { orderCol: 'question.query', field: 'question', header: 'Perguntas' },
                { orderCol: 'answer.name', field: 'answer', header: 'Respostas' },
                { orderCol: 'specifications', field: 'specifications', header: 'Especificações' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'question.query', field: 'question', header: 'Perguntas' },
                { orderCol: 'answer.name', field: 'answer', header: 'Respostas' },
                { orderCol: 'name', field: 'name', header: 'Nome' },
                { orderCol: 'specifications', field: 'specifications', header: 'Especificações' },
                { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            ];
        }

        this.tempColumns =  [
            { orderCol: 'question.query', field: 'question', header: 'Perguntas' },
            { orderCol: 'answer.name', field: 'answer', header: 'Respostas' },
        ];

        this.selectedColumns = this.colsToggle;

    }

    get f() {
        return this.registerForm.controls;
    }


    onRowSelect(event, row) {
        this.selectedListItems = row;
    }

    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};
        if (this.globals.combination_answers) {
          for (let i = 0; i < this.globals.combination_answers.length; i++) {
            let rowData = this.globals.combination_answers[i];
            let name = rowData.name;
            let specifications = rowData.specifications;
            
            if (i == 0) {
              this.rowGroupMetadata[name] = { index: 0, size: 1 };
              this.rowGroupMetadata[specifications] = { index: 0, size: 1 };
            }
            else {
              let previousRowData = this.globals.combination_answers[i - 1];
              let previousRowGroup = previousRowData.name;
              if (name === previousRowGroup){
                  this.rowGroupMetadata[name].size++;
                  this.rowGroupMetadata[specifications].size++;
              }
              else{
                  this.rowGroupMetadata[name] = { index: i, size: 1 };
                  this.rowGroupMetadata[specifications] = { index: i, size: 1 };
              }
            }
          }
        }
      }

    clearFilters(dt) {
        this.nameFilter = '';
        this.questionFilter = '';
        this.answerFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        dt.reset();
    }

    options(row: any) {
        this.optionsThreePoints = [
            // { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            // { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    closeModal() {
        this.ref.close();
    }

    loadAllListItemsByAdmin() {
        this.apiService.listChildByEntityId(this.groupSpecification.id, 'groups/getCombinationsByGroupId/').then(list => {
            let combInd = 1;
            this.globals.combination_answers = [];
            
            list['result'].combinations.forEach(element => {
                for (let i = 0; i < element.length; i++) {
                    let combina = "Combinação " + combInd;
                    this.globals.combination_answers.push({ "name": combina, "questionId": element[i].questionId, "answerId": element[i].answerId, "question": element[i].question, "answer": element[i].answer, "id": element[i].combinationId, "combinationId": element[i].combinationId, "specifications": element[i].specifications, "isVisible": element[i].isVisible })
                }
                combInd++;
            });
            this.updateRowGroupMetaData();
            
            if(this.globals.combination_answers.length == 1 && this.groupSpecification['neutral'] == true) this.showSpecification(this.globals.combination_answers[0], 'neutral'); 
            
        });

        // this.globals.combination_answers = [{"name": "Combinação 1", "question": [], "answer": []}];
    }

    close() {
        this.nameIt = null;
        document.getElementById('btns-item-mng4').style.display = 'initial';
        this.registerForm.reset();
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllListItemsByAdmin();
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

    edit(listItem: CombinationAnswer) {
        this.apiService.update("/combinationQuestionAnswers", listItem).then(() => {
            this.loadAllListItemsByAdmin();
        }, (error) => {
            var text = '';   
            console.log("error",error);
            
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllListItemsByAdmin();
        });
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    showSpecification(row: any, typ) {
        
        document.getElementById('combination_answer').style.display = "none";
        if(typ != 'neutral'){
            document.getElementById('comb_answ').style.display = "none";
            document.getElementById('comb_answ2').style.display = "initial";
        } else{
            document.getElementById('comb_answ').style.display = "initial";
            document.getElementById('comb_answ2').style.display = "none";
        }
        document.getElementById('space2').style.display = "initial";
        document.getElementById('specification').style.display = "initial";
        this.config.header = 'Especificaçao da combinação resposta: ' + row.name;
        this.combinationAnswer_pass = row;
        this.combinationAnswer_pass['group'] = this.groupSpecification;
        this.globals.activated_comb_answ = !this.globals.activated_comb_answ;
    }

}
