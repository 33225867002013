import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from "../guards/config";

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({ providedIn: 'root' })
export class ApiService {
    private url: string;
    constructor(private http: HttpClient, private ngxService: NgxUiLoaderService) {
        this.url = config.baseUrl;
    }

    stopLoading(){
        this.ngxService.stop();
    }

    startLoading(){
        this.ngxService.start();
    }

    async getAll(entity: string, main?: boolean) {
        if(main){
            this.startLoading();
        }
        let response = await this.http.get<any[]>(this.url + `admin/` + entity, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            if(main){
                this.stopLoading();
            }
            return res;
        }, (er) => {
            if(main){
                this.stopLoading();
            }
            return Promise.reject(er);
        });
        return response;
    }

    async getEntity(entity: string) {
        // this.ngxService.start();
        let response = await this.http.get<any[]>(this.url + `/` + entity, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            // this.ngxService.stop(); 
            return Promise.reject(er);
        });
        return response;
    }


    async getById(entity: string, id: string) {
        // this.ngxService.start();
        let response = await this.http.get(this.url + entity + `/` + id, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            // this.ngxService.stop();
            return res;
        }, (er) => {
            // this.ngxService.stop(); 
            return Promise.reject(er);
        });
        return response;
    }

    async register(entity: string, obj: any) {
        this.ngxService.start();
        let response = await this.http.post(this.url + entity, obj, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            this.ngxService.stop(); return Promise.reject(er);
        });
        return response;
    }

    async update(entity: string, obj: any) {
        this.ngxService.start();
        let response = await this.http.patch(this.url + entity + `/` + obj.id, obj, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            this.ngxService.stop(); return Promise.reject(er);
        });
        return response;
    }

    async delete(entity: string, id: string) {
        this.ngxService.start();
        let response = await this.http.delete(this.url + entity + `/` + id, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            this.ngxService.stop(); return Promise.reject(er);
        });
        return response;
    }

    async deleteAll(entity: string, ids: Object) {
        this.ngxService.start();
        let response = await this.http.post(this.url + entity + '/deleteGroup', ids, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            this.ngxService.stop(); return Promise.reject(er);
        });
        return response;
    }

    async changePswd(pass: Object) {
        this.ngxService.start();
        let response = await this.http.post(this.url + '/users/changePswd', pass, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            this.ngxService.stop(); return Promise.reject(er);
        });
        return response;
    }

    async listChildByEntityId(id: string, address: string) {
        this.ngxService.start();
        let response = await this.http.get(this.url + address + id, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken') } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            this.ngxService.stop(); return Promise.reject(er);
        });
        return response;
    }

    async listExpressionsBySideAndCondition(id: string, side: string, address: string) {
        this.ngxService.start();
        let response = await this.http.get(this.url + address + id, { headers: { id: localStorage.getItem('currentUserId'), token: localStorage.getItem('currentUserAccessToken'), side: side } }).toPromise().then((res) => {
            this.ngxService.stop();
            return res;
        }, (er) => {
            this.ngxService.stop(); return Promise.reject(er);
        });
        return response;
    }

}