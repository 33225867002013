import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";

import { Question } from './question';

@Component({
  templateUrl: './questionView.modal.html'
})
export class QuestionViewModalComponent implements OnInit {
  question: Question[];
  viewForm: FormGroup;
  loading: false;
  submitted: false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.question = this.config.data;

    this.viewForm = this.formBuilder.group({
      query: [{ value: null, disabled: true }],
      interjectionQuestion: [{ value: null, disabled: true }]
    });
  }

  close() {
    this.ref.close();
  }

}
