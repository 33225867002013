import { Component } from "@angular/core";
import { Intervention } from "./intervention";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

@Component({
    templateUrl: './interventionView.modal.html',
})
export class InterventionViewModalComponent {
    intervention: Intervention[];
    viewForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private interventionService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        
        this.intervention = this.config.data;
        this.viewForm = this.formBuilder.group({
            name: [{ value: null, disabled: true }],
            noise: [{ value: null, disabled: true }],
            smell: [{ value: null, disabled: true }],
            dust: [{ value: null, disabled: true }],
            residue: [{ value: null, disabled: true }],
            interventionType: [{ value: null, disabled: true }],
            question: [{value: null, disabled: true}]
        });
    }

    close() {
        this.ref.close();
    }
}