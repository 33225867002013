import { Component } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

import { PreExistence } from "../preexistence/preexistence";
import { Product } from "../product/product";
import { ProductApplication } from "../productApplication/productApplication";

@Component({
    templateUrl: './appliedProductAdd.modal.html',
})
export class AppliedProductAddModalComponent {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    binary: SelectItem[];
    optionsType: SelectItem[];
    preexistences: PreExistence[];
    products: Product[];
    productApplications: ProductApplication[];
    waterResistant: string;
    externalLocation: string;
    commercialUse: string;
    hasInclusionCriteria: string;
    constructor(
        private apiService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        this.apiService.getAll("preexistences").then(result => this.preexistences = result['result']);
        this.apiService.getAll("products", true).then(result => this.products = result['result']);
        this.apiService.getAll("productApplications").then(result => this.productApplications = result['result']);
     }

    ngOnInit() {
        this.waterResistant = 'Não';
        this.externalLocation = 'Não';
        this.commercialUse = 'Não';
        this.hasInclusionCriteria = 'Não';
        this.registerForm = this.formBuilder.group({
            product: ['', Validators.required],
            productApplication: ['', Validators.required],
            externalLocation: [null],
            commercialUse: [null],
            waterResistant: [null],
            hasInclusionCriteria: [null],
            consumptionObservations: ['', Validators.pattern(/^((?!\s{2,}).)*$/)],
            complementaryApplication1: [''],
            complementaryApplication2: [''],
            complementaryApplication3: [''],
            preexistences: ['']
        });

        this.binary = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        var arrayPreexistences = [];
        if(this.registerForm.value.preexistences){
            this.registerForm.value.preexistences.forEach(element => {
                arrayPreexistences.push(element.id);
            });
        }
        let ret = this.registerForm.value;
        ret.productId = this.registerForm.value.product.id;
        ret.productApplicationId = this.registerForm.value.productApplication.id;
        if(this.registerForm.value.complementaryApplication1) ret.complementaryApplication1Id = this.registerForm.value.complementaryApplication1.id;
        if(this.registerForm.value.complementaryApplication2) ret.complementaryApplication2Id = this.registerForm.value.complementaryApplication2.id;
        if(this.registerForm.value.complementaryApplication3) ret.complementaryApplication3Id = this.registerForm.value.complementaryApplication3.id;
        ret.preexistences = arrayPreexistences;
        this.apiService.register("appliedProducts", ret)
            .then(
                () => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }
}