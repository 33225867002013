import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig, SelectItem } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { Whitelabel } from '../whitelabel/whitelabel';

@Component({
  selector: 'app-whitelabel-environment',
  templateUrl: './whitelabel-environment.component.html',
  styleUrls: ['./whitelabel-environment.component.css']
})
export class WhitelabelEnvironmentComponent implements OnInit {
  whitelabelEnvironments = [];
  allWhitelabelEnvironments;
  cr: any;
  cols: any[];
  colsToggle: any[];
  selectedColumns: any[];
  selectedItens = [];
  status: SelectItem[];
  appearances: any[];
  appearanceList: SelectItem[] = [];
  appearanceWallList: SelectItem[] = [];
  styles: any[];
  editmode = false;
  ptBr = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
      dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
      monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Sem'
  };
  descriptionFloorFilter = '';
  descriptionWallFilter = '';
  photoCreditsFilter = '';
  appearanceFilter = null;
  appearanceWallFilter = null;
  styleFilter = null;
  displayFilter = '';
  blogFilter = '';
  statusFilter = '';
  isExclusiveFilter = '';
  updateFilter = '';
  rowsPerPageOptions;
  whitelabel: Whitelabel;
  constructor(
    private environmentsService: ApiService,
    private appearanceService: ApiService,
    public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
) {
    this.appearanceService.getAll("appearances").then(appearances => {
        this.appearances = appearances['result'].map((appearance) => {
          let obj = {
            name: appearance.name
          }
          
          return obj;
        });
    });
}
ngOnInit() {
    this.whitelabel = this.config.data;
    this.loadAllEnvironmentsByAdmin();

    this.status = [
        { label: 'Ativo', value: true },
        { label: 'Inativo', value: false }
    ];

    this.cols = [
        { orderCol: 'descriptionFloor', field: 'descriptionFloor', header: 'Descrição Piso' },
        { orderCol: 'descriptionWall', field: 'descriptionWall', header: 'Descrição Parede' },
        { orderCol: 'appearance.name', field: 'appearance', header: 'Aparência Piso' },
        { orderCol: 'appearanceWall.name', field: 'appearanceWall', header: 'Aparência Parede' },
        // {  orderCol: 'styleWall.name', field: 'styleWall', header: 'Estilo Parede' },
        { orderCol: 'imgUrl', field: 'imgUrl', header: 'Imagem' },
        { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
        { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
    ];

    //Check responsiveness to adjust columns to display on mobile
    var width = window.innerWidth;
    if (width <= 500) {
        this.colsToggle = [
            { orderCol: 'descriptionFloor', field: 'descriptionFloor', header: 'Descrição Piso' },
            { orderCol: 'descriptionWall', field: 'descriptionWall', header: 'Descrição Parede' },
            { field: 'isVisible', header: 'Status' }
        ];
    }
    else {
        this.colsToggle = [
            { orderCol: 'descriptionFloor', field: 'descriptionFloor', header: 'Descrição Piso' },
            { orderCol: 'descriptionWall', field: 'descriptionWall', header: 'Descrição Parede' },
            { orderCol: 'appearance.name', field: 'appearance', header: 'Aparência Piso' },
            { orderCol: 'appearanceWall.name', field: 'appearanceWall', header: 'Aparência Parede' },
            // {  orderCol: 'styleWall.name', field: 'styleWall', header: 'Estilo Parede' },
            { orderCol: 'imgUrl', field: 'imgUrl', header: 'Imagem' },
            { orderCol: 'isExclusive', field: 'isExclusive', header: 'Exclusiva' },
            { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
            { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];
    }

    this.selectedColumns = this.colsToggle;
}


clearFilters(dt) {
    this.descriptionFloorFilter = '';
    this.descriptionWallFilter = '';
    this.photoCreditsFilter = '';
    this.appearanceFilter = null;
    this.appearanceWallFilter = null;
    this.blogFilter = '';
    this.statusFilter = '';
    this.isExclusiveFilter = '';
    this.updateFilter = '';
    this.whitelabelEnvironments = this.allWhitelabelEnvironments;
    dt.reset();
}


onRowSelect(event, row) {
    this.selectedItens = row;
}

testExportCSV(dt) {
    dt.value.forEach(v => {
        for (let key in v) {
            if (typeof v[key] === "object" && v[key] != null) {
                if (key != 'imgUrl') v[key] = v[key].name;
            }
            if (key == 'updatedAt') {
                v[key] = new Date(v[key]).toLocaleString();
            }
        }
    });
    dt.exportCSV();
    this.loadAllEnvironmentsByAdmin();
}

loadFilters() {
    let appearanceName = [];
    let appearanceWallName = [];
    this.appearanceList = [];
    this.appearanceWallList = [];

    for (var i = 0; i < this.whitelabelEnvironments.length; i++) {
        if (!appearanceName.includes(this.whitelabelEnvironments[i].appearance.name)) {
            appearanceName.push(this.whitelabelEnvironments[i].appearance.name);
            this.appearanceList.push({ "label": this.whitelabelEnvironments[i].appearance.name, "value": this.whitelabelEnvironments[i].appearance.name });
        }
        if (!appearanceWallName.includes(this.whitelabelEnvironments[i].appearanceWall.name)) {
            appearanceWallName.push(this.whitelabelEnvironments[i].appearanceWall.name);
            this.appearanceWallList.push({ "label": this.whitelabelEnvironments[i].appearanceWall.name, "value": this.whitelabelEnvironments[i].appearanceWall.name });
        }
    }
}

private loadAllEnvironmentsByAdmin() {
  let url = "whitelabelEnvironments/retail/" + this.whitelabel.id;
    this.environmentsService.getAll(url, true).then(whitelabelEnvironment => {
        this.rowsPerPageOptions = undefined;
        if (whitelabelEnvironment['result'].length > 10 && whitelabelEnvironment['result'].length < 25) {
            this.rowsPerPageOptions = [10, 25];
        }
        else if (whitelabelEnvironment['result'].length > 25) {
            this.rowsPerPageOptions = [10, 25, whitelabelEnvironment['result'].length];
        }
        this.whitelabelEnvironments = whitelabelEnvironment['result'];
        this.allWhitelabelEnvironments = whitelabelEnvironment['result'];
        this.loadFilters();
    });
}

filterUpdatedAtOnTable(value, dt) {
    value = value.getTime();
    dt.value.forEach(val => {
        if (typeof val.updatedAt === "string") {
            val.updatedAt = new Date(val.updatedAt).getTime();
        }
    });
    dt.filter(value, 'updatedAt', 'lte');
}

edit(environmentWhitelabel) {
    this.environmentsService.update("whitelabelEnvironments", environmentWhitelabel).then(() => {
        this.loadAllEnvironmentsByAdmin();
    }, (error) => {
        var text = '';
        error.error['messages'].forEach((item, index) => {
            text += item;
            if (index < error.error['messages'].length - 1) text += ', ';
        });
        Swal.fire({
            title: 'Edição Não Realizada!',
            text: text,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
        this.loadAllEnvironmentsByAdmin();
    });
}

disabledEditTable() {
    var inputTable = document.getElementsByClassName("disabledInput");
    if (!this.editmode) {
        this.editmode = true;
        for (let i = 0; i < inputTable.length; i++) {
            inputTable[i].classList.remove("form-pro-table");
            inputTable[i].classList.add("form-pro-table-edit");
        }
    }
    else {
        this.editmode = false
        for (let j = 0; j < inputTable.length; j++) {
            inputTable[j].classList.remove("form-pro-table-edit");
            inputTable[j].classList.add("form-pro-table");
        }
    }
}


aparencias = [];
all = [];
filterObject(event, dt) {
    this.whitelabelEnvironments = this.allWhitelabelEnvironments;
    let tempObj = [];
    switch (dt) {
        case 'appearance':
            this.whitelabelEnvironments.forEach(val => {
                event.value.forEach(element => {
                    if (JSON.stringify(val.appearance) === JSON.stringify(element)) {
                        tempObj.push(val);

                    }
                });
            });
            this.aparencias = tempObj;
            break;
        case 'global':
            let ev = event.toUpperCase();
            this.whitelabelEnvironments.forEach(val => {
                if ((val.appearance.name.toUpperCase().includes(ev)) || (val.description.toUpperCase().includes(ev))) {
                    tempObj.push(val);
                }
            });
            this.all = tempObj;
    }

    if (this.all.length != 0) {
        this.whitelabelEnvironments = this.all;
    } else { 
        if ((this.aparencias.length != 0)) {
            this.whitelabelEnvironments = this.aparencias;
        } else {
          this.whitelabelEnvironments = this.allWhitelabelEnvironments;
        }
    }
}
}
