import { Component } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

import { PreExistence } from "../preexistence/preexistence";
import { Product } from "../product/product";
import { ProductApplication } from "../productApplication/productApplication";
import { AppliedProduct } from "./appliedProduct";

@Component({
    templateUrl: './appliedProductEdit.modal.html',
})
export class AppliedProductEditModalComponent {
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    binary: SelectItem[];
    optionsType: SelectItem[];
    preexistences: PreExistence[];
    products: Product[];
    productApplications: ProductApplication[];
    appliedProduct: AppliedProduct;
    selectedPreexistences: PreExistence[] = [];
    constructor(
        private apiService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        this.apiService.getAll("preexistences").then(result => {
            this.preexistences = result['result']
            this.preexistences.forEach(e =>{
                if(this.config.data['preexistences'].some(elem => elem['id'] == e['id'])){
                    this.selectedPreexistences.push(e);
                }
            });
        });
        this.apiService.getAll("products", true).then(result =>{
            this.products = result['result'];
            this.products.forEach(element => {
                delete element.color;
                delete element.productCategory;
                delete element.manufacturer;
                delete element.brand;
                delete element.standard;
                delete element.productProductSpecifics;
                delete element['packagedProducts'];
                delete element['appliedProduct'];
                delete element['hasCompleted'];
            });
        });
        this.apiService.getAll("productApplications").then(result => {
            this.productApplications = result['result']
            this.productApplications.forEach(element => {
                delete element.productCategory;
            });
        });
     }

    ngOnInit() {
        this.appliedProduct = this.config.data;
        
        this.updateForm = this.formBuilder.group({
            product: ['', Validators.required],
            productApplication: ['', Validators.required],
            externalLocation: [''],
            commercialUse: [''],
            waterResistant: [''],
            hasInclusionCriteria: [''],
            consumptionObservations: ['', Validators.pattern(/^((?!\s{2,}).)*$/)],
            complementaryApplication1: [''],
            complementaryApplication2: [''],
            complementaryApplication3: [''],
            preexistences: ['']
        });

        this.binary = [
            { label: 'Sim ou Indiferente', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

    }

    get f() {
        return this.updateForm.controls;
    }

    onSubmit(appliedProduct: AppliedProduct) {
        this.submitted = true;
        this.loading = true;
        appliedProduct['preexistences'] = [];
        this.selectedPreexistences.forEach(e =>{
            appliedProduct['preexistences'].push(e.id);
        });
        if (appliedProduct.complementaryApplication1) appliedProduct.complementaryApplication1Id = appliedProduct.complementaryApplication1.id;
        if (appliedProduct.complementaryApplication2) appliedProduct.complementaryApplication2Id = appliedProduct.complementaryApplication2.id;
        if (appliedProduct.complementaryApplication3) appliedProduct.complementaryApplication3Id = appliedProduct.complementaryApplication3.id;
        appliedProduct.productId = appliedProduct.products.id;
        appliedProduct.productApplicationId = appliedProduct.productApplications.id;
        this.apiService.update("appliedProducts", appliedProduct)
            .then(
                () => {
                    Swal.fire({
                        title: 'Produto Aplicado editada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }
}