import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationStart, Scroll } from '@angular/router';

import { AuthenticationService } from './services/authentication.service';
import { User } from './entities/user/user';
import { ApiService } from "./services/api.service";
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit{
    currentUser;
    cr;
    visibleSidebar1;
    show: boolean = false;
    private _opened: boolean = true;
    mode = 'push';
    //locale para seleção de data hora
    ptBr: any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: ApiService
    ) {
        console.log('starting...')
        router.events.forEach((event) => {  
            if (event instanceof NavigationStart) {
                var verifyUserAuth = this.authenticationService.currentUserValue;
                if (verifyUserAuth == null) {
                    this.show = false;
                    this._opened = false;
                    document.getElementById('ngsidebar').style.visibility = "hidden";
                    //this.logout();
                } else {
                    this.authenticationService.checkAccess(localStorage.getItem('currentUserId'), localStorage.getItem('currentUserAccessToken')).pipe(first()).subscribe((res)=>{
                            if(!res['result'].authorized){
                                Swal.fire({
                                    title: 'Sua sessão expirou',
                                    text: 'você será redirecionado para a tela de login',
                                    type: 'warning',
                                    confirmButtonColor: '#D95536'
                                  }).then((result) => {
                                    if (result.value) {
                                        this.logout();
                                    }
                                  })
                            }

                    },(er) =>{
                        this.logout();
                    });
                
                    userService.getById("users",localStorage.getItem('currentUserId')).then((cur) => { this.cr = cur['result'] });
                    this.show = true;
                    this._opened = true;
                    document.getElementById('ngsidebar').style.visibility = "visible";
                }                
            }
        })

    }

    ngOnInit(){
        var width = window.innerWidth;
        if(width <= 500){
            this.mode = 'over';
        } else {
            this.mode = 'push';
        }

        if(!this.authenticationService.currentUserValue){
            this.show = false;
            this._opened = false;
            this.logout();
        }
    }

    private toggleSidebar() {
        this._opened = !this._opened;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }


}