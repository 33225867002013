import { Intervention } from "../intervention/intervention";

export class GroupSpecification {
    id: string;
    interventionId: string;
    intervention: Intervention;
    name: string;
    type: string;
    questions: any[];
    isVisible: boolean;
}