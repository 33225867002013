import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ApiService } from "src/app/services/api.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import Swal from 'sweetalert2';
import { InclusionConditionExpression } from './inclusionConditionExpression';
import { InclusionCondition } from '../inclusionCondition/inclusionCondition';

@Component({
    selector: 'app-inclusion-condition-expression',
    templateUrl: './inclusionConditionExpression.component.html',
    providers: [DialogService]
})
export class InclusionConditionExpressionComponent implements OnInit {

    @Input() inclusionCondition: InclusionCondition;
    @Output() resp = new EventEmitter<boolean>();
    nameIt: string;
    type: string;
    field: any;
    integerField: string;
    integerFieldLevel: string;
    operation: any;
    operationLevel: any;
    transformationLevel: any;
    transformation: string;
    expressionCharacters: string;
    listItems: any[] = [];
    cr: any;
    cols: any[];
    colsToggle: any[];
    category: any;
    linkType: string;
    selectedColumns: any[];
    selectedListItems: InclusionConditionExpression[] = [];
    visibleSidebar1 = false;
    status: SelectItem[];
    registerForm: FormGroup;
    optionsThreePoints: MenuItem[];
    ops: SelectItem[];
    binary: SelectItem[];
    specific_field_list: any[];
    linkType_ops: SelectItem[];
    transformationType_ops: SelectItem[];
    multiLevels_ops: SelectItem[];
    levels_ops: SelectItem[];
    operation_ops: SelectItem[];
    general_list: SelectItem[];
    category_list;
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };
    nameFilter = '';
    statusFilter = '';
    updateFilter = '';

    constructor(
        private apiService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
    ) {
        this.type = '';
        this.linkType = '';
        this.apiService.getAll("productCategories", true).then((res) => {
            this.category_list = res['result'];
            this.category_list.forEach(element => {
                delete element.hasCompleted;
            });
        });
    }

    ngOnInit() {

        this.loadAllListItemsByAdmin();

        this.registerForm = this.formBuilder.group({
            type: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            linkType: [''],
            field: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            fieldId: [''],
            transformation: [''],
            transformationLevel: [''],
            integerField: [''],
            integerFieldLevel: [''],
            operation: [''],
            operationLevel: [''],
            expressionCharacters: [{ value: '', disabled: true }],
        });

        this.cols = [
            { field: 'order', header: 'Ordem' },
            { field: 'operation', header: 'Operação' },
            { field: 'operationLevel', header: 'Nível da Operação' },
            { field: 'transformation', header: 'Transformação' },
            { field: 'transformationLevel', header: 'Nível da Transformação' },
            { field: 'integerField', header: 'Inteiro' },
            { field: 'integerFieldLevel', header: 'Nível do Inteiro' },
            { field: 'expressionCharacters', header: 'Caracteres da expressão' },
            { field: 'type', header: 'Tipo do Campo' },
            { field: 'linkType', header: 'Tipo do Link' },
            { field: 'productCategoryName', header: 'Categoria'},
            { field: 'field', header: 'Campo' },
            { field: 'isVisible', header: 'Status' },
            { field: 'updatedAt', header: 'Modificado' },
        ];

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.ops = [
            { label: 'Link', value: 'Link' },
            { label: 'Número', value: 'Número' },
            { label: 'Texto', value: 'Texto' }
        ];


        this.binary = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.linkType_ops = [
            { label: 'Geral', value: 'Geral' },
            { label: 'Categoria', value: 'Categoria' }
        ];

        this.integerField = "Não"
        this.transformation = "Direta"

        this.transformationType_ops = [
            { label: 'Direta', value: 'Direta' },
            { label: 'Inversa', value: 'Inversa' },
            { label: 'Raiz Quadrada', value: 'RaizQuadrada' }
        ];

        if (this.listItems.length > 0) {
            this.multiLevels_ops = [
                { label: 'Nível 0', value: 'Nível 0' },
                { label: 'Nível 1', value: 'Nível 1' },
                { label: 'Nível 2', value: 'Nível 2' },
                { label: 'Nível 0 e Nível 1', value: 'Nível 0 e Nível 1' },
                { label: 'Nível 0 e Nível 2', value: 'Nível 0 e Nível 2' },
                { label: 'Nível 1 e Nível 2', value: 'Nível 1 e Nível 2' },
                { label: 'Nível 0, Nível 1 e Nível 2', value: 'Nível 0, Nível 1 e Nível 2' }
            ];
        } else {
            this.multiLevels_ops = [
                { label: 'Nível 0', value: 'Nível 0' },
                { label: 'Nível 1', value: 'Nível 1' },
                { label: 'Nível 2', value: 'Nível 2' }
            ];
            this.transformationLevel = null;
        }

        this.levels_ops = [
            { label: 'Nível 0', value: 'Nível 0' },
            { label: 'Nível 1', value: 'Nível 1' },
            { label: 'Nível 2', value: 'Nível 2' }
        ];

        this.operation_ops = [
            { label: 'Soma (+)', value: 'Soma' },
            { label: 'Subtração (-)', value: 'Subtração' },
            { label: 'Multiplicação (×)', value: 'Multiplicação' },
            { label: 'Divisão (÷)', value: 'Divisão' }
        ];

        this.general_list = [
            { label: 'Área', value: 'Área' },
            { label: 'Cor', value: 'Cor' },
            { label: 'Fabricante', value: 'Fabricante' },
            { label: 'Marca', value: 'Marca' },
            { label: 'Modelo', value: 'Modelo' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'type', header: 'Lado da Expressão' },
                { field: 'isVisible', header: 'Status' }
            ];
        }
        else {
            this.colsToggle = [
                { field: 'order', header: 'Ordem' },
                { field: 'operation', header: 'Operação' },
                { field: 'operationLevel', header: 'Nível da Operação' },
                { field: 'transformation', header: 'Transformação' },
                { field: 'transformationLevel', header: 'Nível da Transformação' },
                { field: 'integerField', header: 'Inteiro' },
                { field: 'integerFieldLevel', header: 'Nível do Inteiro' },
                { field: 'expressionCharacters', header: 'Caracteres da expressão' },
                { field: 'type', header: 'Tipo do Campo' },
                { field: 'linkType', header: 'Tipo do Link' },
                { field: 'productCategoryName', header: 'Categoria'},
                { field: 'field', header: 'Campo' },
            ];
        }

        this.selectedColumns = this.colsToggle;

    }

    get f() {
        return this.registerForm.controls;
    }


    onRowSelect(event, row) {
        this.selectedListItems = row;
    }


    clearFilters(dt) {
        this.nameFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        dt.reset();
    }

    changeLink() {
        this.field = null;
        this.specific_field_list = [];
        this.category = null;
    }

    changeField() {
        this.linkType = null;
        this.category = null;
        // this.operation = null;
        // this.operationLevel = null;
        // this.transformation = null;
        this.transformationLevel = null;
        // this.integerField = null;
        this.integerFieldLevel = null;
        this.field = null;
        // this.expressionCharacters = null;
    }

    loadexpressionCharacters(lev) {

        if (lev == 'lev') {
            this.transformationLevel = null;
            this.integerFieldLevel = null;
            if (this.operationLevel == 'Nível 2') {
                this.multiLevels_ops = [
                    { label: 'Nível 0', value: 'Nível 0' },
                    { label: 'Nível 1', value: 'Nível 1' },
                    { label: 'Nível 2', value: 'Nível 2' },
                    { label: 'Nível 0 e Nível 1', value: 'Nível 0 e Nível 1' },
                    { label: 'Nível 0 e Nível 2', value: 'Nível 0 e Nível 2' },
                    { label: 'Nível 1 e Nível 2', value: 'Nível 1 e Nível 2' },
                    { label: 'Nível 0, Nível 1 e Nível 2', value: 'Nível 0, Nível 1 e Nível 2' }
                ];
            } else if (this.operationLevel == 'Nível 1') {
                this.multiLevels_ops = [
                    { label: 'Nível 0', value: 'Nível 0' },
                    { label: 'Nível 1', value: 'Nível 1' },
                    { label: 'Nível 0 e Nível 1', value: 'Nível 0 e Nível 1' },
                ];
            } else if (this.operationLevel == 'Nível 0') {
                this.multiLevels_ops = [
                    { label: 'Nível 0', value: 'Nível 0' }
                ];
            }
        }

        let oper;
        let transfLevel2 = '', transfLevel1 = '', transfLevel0 = '';

        let transf = '';

        if (this.transformation == 'RaizQuadrada') {
            transf = '√';
        } else if (this.transformation == 'Direta') {
            transf = '';
        } else if (this.transformation == 'Inversa') {
            transf = '1/';
        }

        if (this.operation == 'Soma') {
            oper = '+';
        } else if (this.operation == 'Subtração') {
            oper = '-';
        } else if (this.operation == 'Multiplicação') {
            oper = '×';
        } else if (this.operation == 'Divisão') {
            oper = '÷';
        } else oper = '_';


        if (this.transformationLevel) {
            if (this.transformationLevel.includes("1")) {
                transfLevel1 = transf;
            }
            if (this.transformationLevel.includes("2")) {
                transfLevel2 = transf;
            }
            if (this.transformationLevel.includes("0")) {
                transfLevel0 = transf;
            }
        }

        if (this.listItems.length > 1) {
            if (this.operationLevel == 'Nível 0') {
                this.expressionCharacters = oper + transfLevel0;
            } else if (this.operationLevel == 'Nível 1') {
                this.expressionCharacters = ')' + oper + transfLevel1 + '(' + transfLevel0;
            } else if (this.operationLevel == 'Nível 2') {
                this.expressionCharacters = '))' + oper + transfLevel2 + '(' + transfLevel1 + '(' + transfLevel0;
            }
        } else {
            if (transf)
                this.expressionCharacters = transfLevel2 + "(" + transfLevel1 + "(" + transfLevel0;
            else this.expressionCharacters = "((";
        }
    }

    loadSpecifics() {
        this.apiService.listChildByEntityId(this.category.id, "productCategories/specificsByCategoryId/").then((res) => {
            this.specific_field_list = res['result'];
            this.field = null;
        });
    }

    options(row: any) {
        this.optionsThreePoints = [
            // { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            // { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            // { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.apiService.delete("inclusionConditionExpressions", id).then(() => {
                    this.loadAllListItemsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail(error);
                    });
            }
        })
    }

    closeModal() {
        this.ref.close();
    }

    loadAllListItemsByAdmin() {
        //Corrigir para listar apenas do lado direito ou esquerdo
        this.apiService.listExpressionsBySideAndCondition(this.inclusionCondition.id, this.inclusionCondition['type'], 'inclusionConditionExpressions/getExpressionByInclusionConditionIdAndSide/').then(list => {
            let lista = [];

            for (let i = 0; i < list['result'].length; i++) {
                if (!list['result'][i].hasOwnProperty("expression")) {
                    lista.push(list['result'][i])
                } else {
                    document.getElementById('expression').innerText = list['result'][i].expression;
                }
            }
            this.listItems = lista
            console.log("this.listItem", this.listItems);
            if (this.listItems.length > 0 && (this.listItems[0].type != 'Texto' && this.listItems[0].field != 'Fabricante'
                && this.listItems[0].field != 'Cor' && this.listItems[0].field != 'Marca' && this.listItems[0].field != 'Modelo')) {
                let item = {};
                item['order'] = this.listItems.length + 1;
                item['expressionCharacters'] = '))';
                this.listItems.push(item);

                this.ops = [
                    { label: 'Link', value: 'Link' },
                    { label: 'Número', value: 'Número' }
                ];

                this.general_list = [
                    { label: 'Área', value: 'Área' },
                ];

                if (document.getElementById('collapsePassItem3').style.display == "none" && document.getElementById('btns-item-mng3').style.display == 'none')
                    document.getElementById('collapsePassItem3').style.display = "initial";

            } else if (this.listItems.length > 0 && (this.listItems[0].type == 'Texto' || (this.listItems[0].linkType == 'Geral' && this.listItems[0].field != 'Área'))) {
                this.ops = [
                    // { label: 'Link', value: 'Link' },
                    // { label: 'Número', value: 'Número' },
                    // { label: 'Texto', value: 'Texto' }
                ];

                document.getElementById('collapsePassItem3').style.display = "none";
                document.getElementById('btns-item-mng3').style.display = 'none';
            } else {
                if (document.getElementById('collapsePassItem3').style.display == "none" && document.getElementById('btns-item-mng3').style.display == 'none')
                    document.getElementById('collapsePassItem3').style.display = "initial";
                this.ops = [
                    { label: 'Link', value: 'Link' },
                    { label: 'Número', value: 'Número' },
                    { label: 'Texto', value: 'Texto' }
                ];

                this.general_list = [
                    { label: 'Área', value: 'Área' },
                    { label: 'Cor', value: 'Cor' },
                    { label: 'Fabricante', value: 'Fabricante' },
                    { label: 'Marca', value: 'Marca' },
                    { label: 'Modelo', value: 'Modelo' }
                ];
            }
        });

    }

    showAdd() {
        document.getElementById('btns-item-mng3').style.display = 'none'
        // document.getElementById('collapsePassItem3').style.display = "initial";
    }

    registerListItem() {
        let objReg = this.registerForm.value;
        if (this.registerForm.value.linkType == 'Categoria') {
            objReg.field = this.field['name'];
            objReg.fieldId = this.field['id'];
        } else if(this.registerForm.value.linkType == 'Geral'){
            if(this.category != null){
            objReg.fieldId = this.category.id;
            }
        }
        objReg.inclusionConditionId = this.inclusionCondition.id;

        objReg.expressionCharacters = this.expressionCharacters;
        objReg.side = this.inclusionCondition['type'];
        if (this.listItems.length == 0 && objReg.expressionCharacters == null) objReg.expressionCharacters = "((";
        if (objReg.type == 'Texto' || (objReg.type == 'Link' && objReg.linkType == 'Geral' && objReg.field != 'Área')) objReg.expressionCharacters = "";
        if (objReg.type == 'Número') objReg.field = objReg.field.toString();
        this.apiService.register("inclusionConditionExpressions", objReg)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            // this.registerForm.reset();
                            this.integerField = "Não";
                            this.transformation = "Direta";
                            this.operation = null;
                            this.operationLevel = null;
                            this.field = null;
                            this.expressionCharacters = null;
                            this.category = null;
                            this.linkType = null;
                            this.type = '';
                            this.specific_field_list = [];
                            this.loadAllListItemsByAdmin();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                });
    }

    close() {
        this.integerField = "Não";
        this.transformation = "Direta";
        this.operation = null;
        this.operationLevel = null;
        this.field = null;
        this.expressionCharacters = null;
        this.category = null;
        this.linkType = null;
        this.type = '';
        // document.getElementById('collapsePassItem3').style.display = "none";
        document.getElementById('btns-item-mng3').style.display = 'initial';
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedListItems.forEach(user => {
            ids.push(user.id);
        });
        this.apiService.deleteAll("inclusionConditionExpressions", { "ids": ids }).then(() => {
            this.selectedListItems.length = 0;
            this.loadAllListItemsByAdmin();
        }, (error) => {
            this.swalDeleteFail(error);
        });
    }

    swalDeleteFail(er) {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: er.error.messages,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: InclusionCondition) {
        this.apiService.register("inclusionConditionExpressions", { id: row['id'], "equality": this.makeIdToDuplicateRow(5) + row['equality'], "isVisible": false }).then(() => {
            this.loadAllListItemsByAdmin();
        },
            (er) => {
                if (er.status === 422) {
                    Swal.fire({
                        title: 'Duplicação não realizada!',
                        text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
                        type: 'error',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                }
            }
        );
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllListItemsByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    edit(listItem: InclusionCondition) {
        this.apiService.update("inclusionConditionExpressions", listItem).then(() => {
            this.loadAllListItemsByAdmin()
        }, (error) => {
            var text = '';
            error.error['messages'].forEach((item, index) => {
                text += item;
                if (index < error.error['messages'].length - 1) text += ', ';
            });
            Swal.fire({
                title: 'Edição Não Realizada!',
                text: text,
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
            this.loadAllListItemsByAdmin();
        });
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }

}
