import { Component } from "@angular/core";
import { Environment } from "./environment";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

@Component({
    templateUrl: './environmentView.modal.html',
})
export class EnvironmentViewModalComponent {
    environment: Environment[];
    viewForm: FormGroup;
    loading = false;
    submitted = false;
    binary: SelectItem[];

    constructor(
        private environmentService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.environment = this.config.data;
        this.viewForm = this.formBuilder.group({
            descriptionFloor: [{ value: null, disabled: true }],
            descriptionWall: [{ value: null, disabled: true }],
            appearanceId: [{ value: null, disabled: true }],
            styleId: [{ value: null, disabled: true }],
            appearanceWallId: [{ value: null, disabled: true }],
            // styleWallId: [{ value: null, disabled: true }],
            isExclusive: [{value: null, disabled: true}],
            imgUrl: [{ value: null, disabled: true }],
            blogUrl: [{ value: null, disabled: true }],
            displayOrder: [{ value: null, disabled: true }],
            photoCredits: [{ value: null, disabled: true }]
        });
        this.binary = [
            { label: 'Sim', value: true },
            { label: 'Não', value: false }
          ];
    }

    close() {
        this.ref.close();
    }
}