import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

import { ApiService } from 'src/app/services/api.service';

import { S3Service } from '../../services/s3.service';

import Swal from 'sweetalert2';
import { Standard } from '../standard/standard';
import { Manufacturer } from '../manufacturer/manufacturer';
import { ProductCategory } from '../productCategory/productCategory';
import { Color } from '../color/color';
import { Brand } from '../brand/brand';
import { ProductCategorySpecification } from '../productCategorySpecification/productCategorySpecification';
import { Product } from './product';
import { element } from '@angular/core/src/render3';

@Component({
    templateUrl: './productEdit.modal.html',
    styleUrls: ['./productAdd.modal.css'],
})
export class ProductEditModalComponent implements OnInit {
    product: Product;
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    standards: Standard[];
    brand: Brand;
    manufacturers: Manufacturer[];
    brands: Brand[];
    categories: ProductCategory[];
    colors: Color[];
    binary: SelectItem[];
    binaryRequired: SelectItem[];
    specifics: ProductCategorySpecification[];
    resultSpecificsProduct: any[];
    selectedColors: Color[] = [];
    selectedStandards: Standard[] = [];
    productSpecificsData = [];
    categoryFixedId: string;
    count;
    impactAppearance: string;
    type: SelectItem[];
    isService: any;

    constructor(
        private productService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        this.productService.getAll("manufacturers").then(manu => {
            this.manufacturers = manu['result']
            this.manufacturers.forEach(element => {
                delete element['brands'];
            });
        });
        this.productService.getAll("productCategories").then(cat => {
            this.categories = cat['result'];
            this.categories.forEach(element =>{
                delete element['hasCompleted'];
                delete element['specifics'];
            })
        });
        this.productService.getAll("colors").then(color => {
            this.colors = color['result'];
            this.colors.forEach(e => {
                if (this.config.data['color'].some(elem => elem['id'] == e['id'])) {
                    this.selectedColors.push(e);
                }
            });
            this.config.data['color'] = this.selectedColors;
        });
        this.productService.getAll("standards").then(standard => {
            this.standards = standard['result'];
            this.standards.forEach(e => {
                if (this.config.data['standard'].some(elem => elem['id'] == e['id'])) {
                    this.selectedStandards.push(e);
                }
            });
            this.config.data['standard'] = this.selectedStandards;
        });
        this.resultSpecificsProduct = [];
    }

    ngOnInit() {
        this.campo = [];
        this.prodEsp = [];
        this.count = 0;
        this.resultSpecificsProduct = [];
        delete this.config.data.productCategory.specifics;
        this.product = this.config.data;
        this.categoryFixedId = this.product.productCategoryId;
        this.impactAppearance = "";

        this.loadSpecifics();

        this.updateForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            model: ['', Validators.compose([Validators.minLength(1), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            manufacturerCode: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            manufacturerLink: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            manualPdf: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            impactAppearance: ['', Validators.required],
            commercialColor: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            benefits: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500)])],
            technicalObservations: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500)])],
            productCategory: ['', Validators.required],
            manufacturer: ['', Validators.required],
            standard: [null],
            color: [null],
            brand: ['', Validators.required],
            isService: ['', Validators.required],
            allColorsSelected: ['', Validators.required],
            allStandardsSelected: ['', Validators.required]
        });

        this.binaryRequired = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.type = [
            { label: 'Produto', value: false},
            { label: 'Serviço', value: true}
        ];


        this.binary = [
            //{ label: '', value: null },
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.loadBrands();
    }

    get f() {
        return this.updateForm.controls;
    }

    createItem(): FormGroup {
        return this.formBuilder.group({
            value: ''
        });
    }

    bool: boolean;

    addItem(ev: any) {
        let self = this;
        self.bool = true;
        for (let i = 0; i < this.resultSpecificsProduct.length; i++) {
            if (this.resultSpecificsProduct[i].name == ev.name) {
                if (ev.type == 'Número') this.resultSpecificsProduct[i].value = ev.value.toString();
                else this.resultSpecificsProduct[i].value = ev.value;
                this.resultSpecificsProduct[i].idSpecification = ev.idSpecification;
                self.bool = false;
            }
        }
        if (self.bool) {
            self.resultSpecificsProduct.push({ name: ev.name, id: ev.id, value: ev.value, idSpecification: ev.idSpecification });
        } else {
            self.bool = true;
        }
    }

    specificsSwalError() {
        for (let i = 0; i < this.campo.length; i++) {
            if (this.campo[i].type == 'Número' && this.campo[i].value) this.campo[i].value.toString();
            if (this.campo[i].value == null || this.campo[i].value == "") {
                return true;
            }
        }
        return false;
    }

    checkColorStandard(ev, obj) {
        if (ev != 'required') return true;
        else if (ev == 'required') {
            if (obj.color.length > 0 && obj.standard.length > 0) {
                return true;
            } else return false;
        }
    }

    onSubmit() {
        if (this.specificsSwalError()) {
            Swal.fire({
                title: 'Adição não realizada!',
                text: "Os valores dos campos específicos são obrigatórios!",
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        } else {
            this.campo.forEach(element => {
                if (element.type == 'Número') element.value = element.value.toString();
            });
            let objReg = this.updateForm.value;
            if (this.updateForm.value.standard) {
                objReg.standardId = this.updateForm.value.standard.id;
            }
            objReg.productCategoryId = this.updateForm.value.productCategory.id;
            objReg.manufacturerId = this.updateForm.value.manufacturer.id;
            try { objReg.brandId = this.updateForm.value.brand.id; } catch { }
            objReg.productSpecifics = this.resultSpecificsProduct;
            objReg.id = this.product.id;
            objReg.allColorsSelected = this.product.allColors;
            objReg.allStandardsSelected = this.product.allStandard;
            var arrayColors = [];
            if(this.product.allColors === 'Sim'){
                this.colors.forEach(obj => {
                    arrayColors.push(obj.id);
                });
                objReg.color = arrayColors;
            } else {
                if (this.updateForm.value.color) {
                    this.updateForm.value.color.forEach(obj => {
                        arrayColors.push(obj.id);
                    });
                    objReg.color = arrayColors;
                }    
            }
            var arrayStandards = [];
            if(this.product.allStandard === 'Sim'){
                this.standards.forEach(obj => {
                    arrayStandards.push(obj.id);
                });
                objReg.standard = arrayStandards;
            } else {
                if (this.updateForm.value.standard) {
                    this.updateForm.value.standard.forEach(obj => {
                        arrayStandards.push(obj.id);
                    });
                    objReg.standard = arrayStandards;
                }
            }
            let constBool;

            if (this.product.impactAppearance == 'Sim') {
                constBool = this.checkColorStandard('required', objReg);
            } else constBool = this.checkColorStandard('not required', objReg);
            if (constBool) {
                this.productService.update("products", objReg)
                    .then(
                        (success) => {
                            Swal.fire({
                                title: 'Editado com sucesso!',
                                type: 'success',
                                confirmButtonColor: '#D95536',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.value) {
                                    this.ref.close();
                                }
                            })
                        },
                        (error) => {
                            var errorMsg = error.error.messages.toString();
                            errorMsg = errorMsg.replace(/,/g, '');
                            Swal.fire({
                                title: 'Edição Não Realizada!',
                                text: errorMsg,
                                type: 'warning',
                                confirmButtonColor: '#D95536',
                                allowOutsideClick: false
                            });
                            this.loading = false;
                        });
            } else {
                Swal.fire({
                    title: 'Edição não realizada!',
                    text: "Os valores de Cor e Padrão devem ser obrigatórios quando o Impacto na Aparência for 'Sim'!",
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        }

    }

    close() {
        this.ref.close();
    }

    loadBrands() {
        this.productService.listChildByEntityId(this.product.manufacturer.id, '/manufacturers/brandsByManufacturer/').then((brands) => {
            this.brands = brands['result'];
            if (this.count == 0) this.count = 1;
            else this.product.brand = null;
            // this.product.brand = null;
        })
    }

    makeId(length) {
        let result = '';
        let characters = '123456790-qwertyuiopasdfghjklçzxcvbnmQWERTYUIOPASDFGHJKLÇZXCVBNM';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    tempSpecifics(specifics, prodEsp, val) {
        let optList = [];
        if (specifics.specificationType == 'Lista') {
            specifics['productCategorySpecificationList'].forEach(el => {
                optList.push({ name: specifics.name, id: prodEsp.id, value: el.name, optionsList: [], idSpecification: specifics.id, type: specifics.specificationType });
            });
            optList.forEach(elm => {
                elm.optionsList = optList;
            });
            this.campo.push({ name: specifics.name, id: prodEsp.id, value: val, optionsList: optList, idSpecification: specifics.id, type: specifics.specificationType });
        } else {
            this.campo.push({ name: specifics.name, id: prodEsp.id, value: val, optionsList: [], idSpecification: specifics.id, type: specifics.specificationType })
        }
    }
    campo = [];
    prodEsp = [];
    loadSpecifics() {
        let checkNewFields = false;
        this.campo = [];
        this.productService.listChildByEntityId(this.product.productCategory.id, 'productCategories/specificsByCategoryId/').then(specifics => {
            this.specifics = specifics['result'];

            this.productService.listChildByEntityId(this.product.id, 'productProductCategorySpecifications/getAllByProdId/').then(prodE => {
                this.prodEsp = prodE['result'];
                for (let i = 0; i < this.specifics.length; i++) {
                    if (this.categoryFixedId == this.product.productCategory.id) {
                        for (let j = 0; j < this.prodEsp.length; j++) {
                            if (this.specifics[i].id === this.prodEsp[j].productCategorySpecificationId) {
                                this.tempSpecifics(this.specifics[i], this.prodEsp[j], this.prodEsp[j].value);
                            }
                        }
                    } else {
                        checkNewFields = true;
                        let optList = [];
                        if (this.specifics[i].specificationType == 'Lista') {
                            this.specifics[i]['productCategorySpecificationList'].forEach(el => {
                                optList.push({ name: this.specifics[i].name, id: this.makeId(5), value: el.name, optionsList: [], idSpecification: this.specifics[i].id, type: this.specifics[i].specificationType });
                            });
                            optList.forEach(elm => {
                                elm.optionsList = optList;
                            });
                            this.campo.push({ name: this.specifics[i].name, id: optList[0].id, value: optList[0].value, optionsList: optList, idSpecification: this.specifics[i].id, type: this.specifics[i].specificationType });
                        } else {
                            let val;
                            if (this.specifics[i].specificationType == 'Número') val = '0';
                            if (this.specifics[i].specificationType == 'Texto') val = 'vazio';
                            this.campo.push({ name: this.specifics[i].name, id: this.makeId(5), value: val, optionsList: [], idSpecification: this.specifics[i].id, type: this.specifics[i].specificationType })
                        }
                    }
                }
                if (checkNewFields) {
                    this.campo.forEach(el => this.addItem(el));
                } else if (this.campo.length == 0) {
                    for (let i = 0; i < this.specifics.length; i++) {
                        for (let j = 0; j < this.prodEsp.length; j++) {
                            this.tempSpecifics(this.specifics[i], this.prodEsp[j], null);
                        }
                    }
                }
            })
        })

    }
}
