import { Injectable } from '@angular/core';
import { InclusionCriteria } from './entities/inclusionCriteria/inclusionCriteria';
import { InclusionCondition } from './entities/inclusionCondition/inclusionCondition';
import { ConsumptionCriteria } from './entities/consumptionCriteria/consumptionCriteria';
import { ConsumptionCondition } from './entities/consumptionCondition/consumptionCondition';
import { GroupSpecification } from './entities/groupSpecification/groupSpecification';
import { CombinationAnswer } from './entities/combinationAnswer/combinationsAnswer';
import { Specification } from './entities/specification/specification';

@Injectable()
export class Globals {
  activated_crit: boolean = false;
  activated_cond: boolean = false;
  inclusion_criterias: InclusionCriteria[] = [];
  inclusion_conditions: InclusionCondition[] = [];
  obj_inclusion_criteria: InclusionCriteria;
  activated_crit_consumption: boolean = false;
  activated_cond_consumption: boolean = false;
  consumption_criterias: ConsumptionCriteria[] = [];
  consumption_conditions: ConsumptionCondition[] = [];
  obj_consumption_criteria: ConsumptionCriteria;
  groups: GroupSpecification[] = [];
  combination_answers: any[] = [];
  activated_group: boolean = false;
  obj_combination: any;
  specifications: Specification[] = [];
  activated_comb_answ: boolean = false;
  specification: Specification;
}