import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";

import { ProductCategory } from './productCategory';

@Component({
  templateUrl: './productCategoryView.modal.html'
})
export class ProductCategoryViewModalComponent implements OnInit {
  category: ProductCategory[];
  viewForm: FormGroup;
  loading: false;
  submitted: false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.category = this.config.data;
    
    this.viewForm = this.formBuilder.group({
      name: [{ value: null, disabled: true }],
      unityMeasurement: [{ value: null, disabled: true}]
    });
  }

  close() {
    this.ref.close();
  }

}
