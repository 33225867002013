import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { Color } from 'src/app/entities/color/color';
import { ApiService } from 'src/app/services/api.service';

@Component({
  templateUrl: './colorEdit.modal.html'
})
export class ColorEditModalComponent implements OnInit {
  color: Color[];
  updateForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private colorService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.color = this.config.data;
    this.updateForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])]
    });
  }
  get f() {
    return this.updateForm.controls;
  }

  onSubmit(color: Color) {
    this.loading = true;
    this.colorService.update("colors", color)
      .then(
        () => {
          Swal.fire({
            title: 'Edição realizada com sucesso!',
            type: 'success',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              this.ref.close();
            }
          })
        },
        (error) => {
          var errorMsg = error.error.messages.toString();
          errorMsg = errorMsg.replace(/,/g, '');
          Swal.fire({
            title: 'Edição Não Realizada!',
            text: errorMsg,
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          });
          this.loading = false;
        });
  }

  close() {
    this.ref.close();
  }
}
