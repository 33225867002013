import { Component } from "@angular/core";
import { Material } from "./material";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

@Component({
    templateUrl: './materialView.modal.html',
    styleUrls: ['./material.component.css'],
})
export class MaterialViewModalComponent {
    material: Material[];
    viewForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private materialService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.material = this.config.data;
        this.viewForm = this.formBuilder.group({
            name: [{ value: null, disabled: true }],
            description: [{ value: null, disabled: true }],
            waterResistant: [{ value: null, disabled: true }],
            nonSlip: [{ value: null, disabled: true }],
            scratchResistant: [{ value: null, disabled: true }],
            stainResistant: [{ value: null, disabled: true }],
            cold: [{ value: null, disabled: true }],
            environmentType: [{ value: null, disabled: true }],
            usageType: [{ value: null, disabled: true }],
            waterResistantWall: [{ value: null, disabled: true }],
            nonSlipWall: [{ value: null, disabled: true }],
            scratchResistantWall: [{ value: null, disabled: true }],
            stainResistantWall: [{ value: null, disabled: true }],
            coldWall: [{ value: null, disabled: true }],
            environmentTypeWall: [{ value: null, disabled: true }],
            usageTypeWall: [{ value: null, disabled: true }],
            type: [{value: null, disabled: true}],
            imgUrl: [{ value: null, disabled: true }]
        });
    }

    close() {
        this.ref.close();
    }
}