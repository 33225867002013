import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, Route, ActivatedRoute } from "@angular/router";
import swal from 'sweetalert';
import { User } from 'src/app/entities/user/user';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-registerPassword',
  templateUrl: './registerPassword.component.html',
  styleUrls: ['./registerPassword.component.css']
})
export class RegisterPasswordComponent implements OnInit {

  registerForm: FormGroup;
  user;
  id: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: AuthenticationService,
    
  ) { 
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(6), Validators.pattern(/(?=.*[a-zA-Z])(?=.*[0-9]+).*/)]],
      passwordConfirm: [null, [Validators.required, Validators.minLength(6)]]
    });
    this.id = document.URL.substring(document.URL.lastIndexOf('/') + 1);

  }

  get f() { return this.registerForm.controls; }


  onSubmit() {
    if(this.registerForm.value['password'] == this.registerForm.value['passwordConfirm']){
      this.userService.updatePassword(this.id, this.registerForm.value['password']).pipe(first()).subscribe(() => {
        swal({
          title: "Senha atualizada com sucesso!",
          icon: "success",
        }).then(
          () => {
            this.router.navigate(['/']);
          }
        );
      },
      (error) =>{
        swal({
          title: "Falha na atualização da senha!",
          text: "Você já utilizou esta senha anteriormente",
          icon: "warning",
        });
      }
      );
    } else{
      swal({
        title: "As senhas não conferem!",
        text: "Tente novamente",
        icon: "warning",
      });
    }
  }

}
