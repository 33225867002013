import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  items: MenuItem[];
  stateMenu: any;

  constructor(private router: Router) {}

  ngOnInit() {
    this.items = [
      {
        label: "Árvore de decisão",
        icon: "pi pi-sitemap",
        items: [
          {
            label: "Setup Ambientação",
            items: [
              {
                label: "Cor",
                routerLink: ["/color"],
              },
              {
                label: "Padrão",
                routerLink: ["/standard"],
              },
              {
                label: "Aparência",
                routerLink: ["/appearance"],
              },
              {
                label: "Estilo",
                routerLink: ["/style"],
              },
              {
                label: "Ambientação",
                routerLink: ["/environment"],
              },
            ],
          },
          {
            label: "Setup Ambientes",
            routerLink: ["/ambience"],
          },
          {
            label: "Setup Material",
            items: [
              { label: "Material", routerLink: ["/material"] },
              {
                label: "Aparência Material",
                routerLink: ["/appearancematerial"],
              },
            ],
          },
          {
            label: "Setup Intervenção",
            items: [
              { label: "Preexistência", routerLink: ["/preexistence"] },
              { label: "Intervenção", routerLink: ["/intervention"] },
              { label: "Ação possível", routerLink: ["/possibleaction"] },
              { label: "Pergunta", routerLink: ["/question"] },
            ],
          },
          {
            label: "Setup Produtos",
            items: [
              { label: "Categoria Produto", routerLink: ["/category"] },
              { label: "Aplicação", routerLink: ["/productApplication"] },
              { label: "Produto", routerLink: ["/product"] },
              { label: "Produto Aplicado", routerLink: ["/appliedProduct"] },
            ],
          },
        ],
      },
      {
        label: "Gerenciar Varejistas",
        icon: "lnr lnr-cart",
        routerLink: ["/retail"],
      },
      {
        label: "Gerenciar Fabricantes",
        icon: "lnr lnr-store",
        routerLink: ["/manufacturer"],
      },
      {
        label: "Gerenciar Usuários",
        routerLink: [""],
        icon: "lnr lnr-user",
      },
      {
        label: "Gerenciar Whitelabel",
        routerLink: ["/whitelabel"],
        icon: "lnr lnr-briefcase",
      },
      {
        label: "Gerenciar Leads",
        icon: "lnr lnr-chart-bars",
        routerLink: ["/lead"],
      },
    ];
  }

  //   activeMenu(event) {
  // console.log("event", event);
  // if (
  //   !event.srcElement?.innerText.includes("Árvore de decisão") &&
  //   !event.srcElement?.innerText.includes("Setup Ambientação") &&
  //   !event.srcElement?.innerText.includes("Setup Material") &&
  //   !event.srcElement?.innerText.includes("Setup Intervenção") &&
  //   !event.srcElement?.innerText.includes("Setup Produtos")
  // ) {
  //   event.srcElement.style = "background-color: #ffffff4f";
  //   if (this.stateMenu) {
  //     this.stateMenu.srcElement.style = "none";
  //   }
  //   this.stateMenu = event;
  // }
  // console.log("event", event.toElement.innerText);

  // let node;
  // if (event.target.tagName === "A" && event.target.innerText != "Árvore de decisão") {
  //   node = event.target;
  // } else {
  // //   node = event.target.parentNode;
  // }
  // let menuitem = document.getElementsByClassName("ui-menuitem-link");
  // for (let i = 0; i < menuitem.length; i++) {
  //   menuitem[i].classList.remove("active");
  // }
  // let header = document.getElementsByClassName("ui-panelmenu-header");
  // for( let i = 0; i < header.length; i++){
  //     header[i].classList.remove("active");
  // }
  // let content = document.getElementsByClassName("ui-menuitem");
  // for(let i = 0; i < content.length; i++){
  //     content[i].classList.remove("active");
  // }
  // node.classList.add("active")
  //   }
}
