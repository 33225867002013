import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DialogService, SelectItem, MenuItem } from "primeng/api";
import { ApiService } from 'src/app/services/api.service';

import { PossibleAction } from 'src/app/entities/possibleAction/possibleAction';
import { PossibleActionModalComponent } from 'src/app/entities/possibleAction/possibleActionAdd.modal';
import { PossibleActionEditModalComponent } from 'src/app/entities/possibleAction/possibleActionEdit.modal';
import { PossibleActionViewModalComponent } from 'src/app/entities/possibleAction/possibleActionView.modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-possibleaction',
  templateUrl: './possibleAction.component.html',
  styleUrls: ['./possibleAction.component.css'],
  providers: [DialogService]
})
export class PossibleActionComponent implements OnInit {

  possibleActions: PossibleAction[] = [];
  allPossibleActions = [];
  items: MenuItem[];
  cr: any;
  cols: any[];
  colsToggle: any[];
  selectedColumns: any[];
  selectedItens: PossibleAction[] = [];
  status: SelectItem[];
  isValidOptions: SelectItem[];
  styles: any[];
  actions: any[];
  actionList: SelectItem[] = [];
  materials: any[];
  materialList: SelectItem[] = [];
  preexistences: any[];
  preexistenceList: SelectItem[] = [];
  binary: SelectItem[];
  ternary: SelectItem[];
  interventions: any[];
  interventionList: SelectItem[] = [];
  flag: SelectItem[] = [];
  editmode = false;
  optionsThreePoints: MenuItem[];
  ptBr = {
    firstDayOfWeek: 0,
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
  };
  nameFilter = '';
  actionFilter = null;
  materialFilter = null;
  preexistenceFilter = null;
  interventionsFilter = null
  validFilter = '';
  statusFilter = '';
  updateFilter = '';
  speedFilter = '';
  flagFilter = '';
  rowsPerPageOptions;


  constructor(
    private possibleActionService: ApiService,
    private dialogService: DialogService
  ) {
    this.possibleActionService.getAll("actions").then(actions => {
      this.actions = actions['result'];
    });
    this.possibleActionService.getAll("materials").then(materials => {
      this.materials = materials['result'];
    });
    this.possibleActionService.getAll("preexistences").then(preexistences => {
      this.preexistences = preexistences['result'];
    });
    this.possibleActionService.getAll("interventions").then(interventions => {
      for (var i = 0; i < interventions['result'].length; i++) {
        delete interventions['result'][i].questions;
        delete interventions['result'][i].specificationGroup;
        delete interventions['result'][i].hasCompleted;
      }
      this.interventions = interventions['result'];
    });
  }

  ngOnInit() {
    // this.userService.getById("users", localStorage.getItem('currentUserId')).pipe(first()).subscribe((cur) => {
    //   this.cr = cur['result'];
    this.loadAllPossibleActionsByAdmin();
    // this.loadFilters();
    // });

    this.cols = [
      { orderCol: 'preexistence.name', field: 'preexistence', header: 'Preexistência' },
      { orderCol: 'preexistence.flag', field: 'preexistence.flag', header: 'Tipo da Preexistência' },
      { orderCol: 'material.name', field: 'material', header: 'Material' },
      { orderCol: 'action.name', field: 'action', header: 'Ação' },
      { orderCol: 'isValid', field: 'isValid', header: 'Válido' },
      { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
      { orderCol: 'orderInterventions', field: 'interventions', header: 'Intervenções' },
      //{ orderCol: 'name', field: 'name', header: 'Nome' },
      { orderCol: 'imgUrl', field: 'imgUrl', header: 'Imagem' },
      { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
      { orderCol: 'speed', field: 'speed', header: 'Velocidade' },
    ];

    this.status = [
      { label: 'Ativo', value: true },
      { label: 'Inativo', value: false }
    ];

    this.isValidOptions = [
      { label: 'Válido', value: true },
      { label: 'Inválido', value: false }
    ];

    this.colsToggle = [
      { orderCol: 'preexistence.name', field: 'preexistence', header: 'Preexistência' },
      { orderCol: 'preexistence.flag', field: 'preexistence.flag', header: 'Tipo da Preexistência' },
      { orderCol: 'material.name', field: 'material', header: 'Material' },
      { orderCol: 'action.name', field: 'action', header: 'Ação' },
      { orderCol: 'isValid', field: 'isValid', header: 'Válido' },
      { orderCol: 'isVisible', field: 'isVisible', header: 'Status' },
      { orderCol: 'orderInterventions', field: 'interventions', header: 'Intervenções' },
      { orderCol: 'speed', field: 'speed', header: 'Velocidade' }
    ];

    this.selectedColumns = this.colsToggle;

    this.binary = [
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' }
    ];

    this.ternary = [
      { label: '', value: '' },
      { label: 'Sim', value: 'Sim' },
      { label: 'Não', value: 'Não' }
    ];
    this.flag = [
      { label: 'Piso', value: 'floor' },
      { label: 'Parede', value: 'wall' },
    ]

  }

  onRowSelect(event, row) {
    this.selectedItens.push(row);
  }

  clearFilters(dt) {
    this.nameFilter = '';
    this.actionFilter = null;
    this.materialFilter = null;
    this.preexistenceFilter = null;
    this.interventionsFilter = null
    this.validFilter = '';
    this.statusFilter = '';
    this.updateFilter = '';
    this.speedFilter = '';
    this.flagFilter = '';
    this.possibleActions = this.allPossibleActions;
    dt.reset();
  }

  options(row: any) {
    this.optionsThreePoints = [
      { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
      { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
      //{ label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
      //{ label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
    ]
    return this.optionsThreePoints;
  }

  delete(id: string) {
    Swal.fire({
      title: 'Você tem certeza que deseja realizar a deleção?',
      text: "Esta ação não poderá ser revertida!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#D95536',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.possibleActionService.delete("possibleActions", id).then(() => {
          this.loadAllPossibleActionsByAdmin();
        },
          (error) => {
            this.swalDeleteFail();
          });
      }
    })
  }

  loadFilters() {
    let actionName = [];
    let materialName = [];
    let preexistenceName = [];
    let interventionListName = [];
    this.actionList = [];
    this.materialList = [];
    this.preexistenceList = [];
    this.interventionList = [];

    for (var i = 0; i < this.possibleActions.length; i++) {
      if (!actionName.includes(this.possibleActions[i].action.name)) {
        actionName.push(this.possibleActions[i].action.name);
        this.actionList.push({ "label": this.possibleActions[i].action.name, "value": this.possibleActions[i].action.name });
      }
      if (!materialName.includes(this.possibleActions[i].material.name)) {
        materialName.push(this.possibleActions[i].material.name);
        this.materialList.push({ "label": this.possibleActions[i].material.name, "value": this.possibleActions[i].material.name });
      }
      if (!preexistenceName.includes(this.possibleActions[i].preexistence.name)) {
        preexistenceName.push(this.possibleActions[i].preexistence.name);
        this.preexistenceList.push({ "label": this.possibleActions[i].preexistence.name, "value": this.possibleActions[i].preexistence.name });
      }
      interventionListName.push([]);
      if (this.possibleActions[i].interventions.length) {
        for (var j = 0; j < this.possibleActions[i].interventions.length; j++) {
          delete this.possibleActions[i].interventions[j].InterventionsPossibleAction;

          if (!interventionListName[i].includes(this.possibleActions[i].interventions[j].name)) {
            interventionListName[i].push(this.possibleActions[i].interventions[j].name);
          }
        }
        var abc = JSON.stringify(interventionListName[i]);
        abc = abc.replace(/[\[\"{}\"\]]/g, '');
        if (!this.interventionList.some(intervList => intervList["label"] === abc)) {
          this.interventionList.push({ "label": abc, "value": interventionListName[i] });
        }
        this.possibleActions[i]['orderInterventions'] = interventionListName[i];
      }
    }
  }

  loadAllPossibleActionsByAdmin() {
    this.possibleActionService.getAll("possibleActions", true).then(possibleActions => {
      this.rowsPerPageOptions = undefined;
      if (possibleActions['result'].length > 10 && possibleActions['result'].length < 25) {
        this.rowsPerPageOptions = [10, 25];
      }
      else if (possibleActions['result'].length > 25) {
        this.rowsPerPageOptions = [10, 25, possibleActions['result'].length];
      }
      this.possibleActions = possibleActions['result'];
      this.allPossibleActions = possibleActions['result'];
      // console.log("allPossibleActions", this.possibleActions);

      //Exclui a relacao entre possibleActions e interventions para bind no select de intervencoes na tabela
      this.loadFilters();
    });
  }

  testExportCSV(dt) {
    dt.value.forEach(v => {
      for (let key in v) {
        if (typeof v[key] === "object" && v[key] != null) {
          if (key != 'imgUrl') v[key] = v[key].name;
        }
        if (key == 'updatedAt') {
          v[key] = new Date(v[key]).toLocaleString();
        }
      }
    });
    dt.exportCSV();
    this.loadAllPossibleActionsByAdmin();
  }

  showAdd() {
    this.dialogService.open(PossibleActionModalComponent, {
      header: 'Cadastrar Ação Possível',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAllPossibleActionsByAdmin();
    });
  }

  deleteAll() {
    let ids: string[] = [];
    this.selectedItens.forEach(item => {
      ids.push(item.id);
    });
    this.possibleActionService.deleteAll("possibleActions", { "ids": ids }).then(() => {
      this.loadAllPossibleActionsByAdmin();
    }, (error) => {
      this.swalDeleteFail();
    });
  }

  swalDeleteFail() {
    Swal.fire({
      title: 'Deleção Não Realizada!',
      text: "Verifique se há relação com outro objeto!",
      type: 'warning',
      confirmButtonColor: '#D95536',
      allowOutsideClick: false
    });
  }

  filterUpdatedAtOnTable(value, dt) {
    value = value.getTime();
    dt.value.forEach(val => {
      if (typeof val.updatedAt === "string") {
        val.updatedAt = new Date(val.updatedAt).getTime();
      }
    });
    dt.filter(value, 'updatedAt', 'lte');
  }

  all = [];
  filterObject(event) {
    this.possibleActions = this.allPossibleActions;
    let tempObj: PossibleAction[] = [];
    let ev = event.toUpperCase();
    this.possibleActions.forEach(val => {
      if ((val.material.name.toUpperCase().includes(ev)) || (val.action.name.toUpperCase().includes(ev)) || (val.preexistence.name.toUpperCase().includes(ev))) {
        tempObj.push(val);
      }
    });
    this.all = tempObj;


    if (this.all.length != 0) {
      this.possibleActions = this.all;
    }
  }

  edit(possibleAction: PossibleAction) {
    possibleAction.actionId = possibleAction.action.id;
    possibleAction.materialId = possibleAction.material.id;
    possibleAction.preexistenceId = possibleAction.preexistence.id;
    //Cria a lista de ids de intervencoes para edicao
    var listIdInterventions = [];
    possibleAction.interventions.forEach(e => {
      listIdInterventions.push(e.id);
    });
    //TO DO: aqui ele substitui o objeto exibido na tela oq faz a lista de intervencoes sumir por alguns segundos 
    possibleAction.interventions = listIdInterventions;

    this.possibleActionService.update("possibleActions", possibleAction).then(() => {
      this.loadAllPossibleActionsByAdmin();
    }, (error) => {
      var text = '';
      error.error['messages'].forEach((item, index) => {
        text += item;
        if (index < error.error['messages'].length - 1) text += ', ';
      });
      Swal.fire({
        title: 'Edição Não Realizada!',
        text: text,
        type: 'warning',
        confirmButtonColor: '#D95536',
        allowOutsideClick: false
      });
      this.loadAllPossibleActionsByAdmin();
    });
  }

  showEdit(possibleAction: PossibleActionEditModalComponent) {
    this.dialogService.open(PossibleActionEditModalComponent, {
      data: possibleAction,
      header: 'Editar Ação possível',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAllPossibleActionsByAdmin();
    });
  }

  showView(possibleAction: PossibleAction) {
    this.dialogService.open(PossibleActionViewModalComponent, {
      data: possibleAction,
      header: 'Visualizar Ação possível',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAllPossibleActionsByAdmin();
    });
  }

  makeIdToDuplicateRow(length) {
    let result = '';
    let characters = '123456790';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result = "copy_" + result + "_";
    return result;
  }

  duplicateRow(row: PossibleAction) {
    this.possibleActionService.register("possibleActions", <PossibleAction>{
      "name": this.makeIdToDuplicateRow(5) + row['name'],
      "actionId": row['actionId'],
      "imgUrl": row['imgUrl'],
      "preexistenceId": row['preexistenceId'],
      "speed": row['speed'],
      "interventions": row['interventions'],
      "materialId": row['materialId'],
      "isVisible": false,
      "isValid": row['isValid']
    }).then(() => {
      this.loadAllPossibleActionsByAdmin();
    },
      (er) => {
        if (er.status === 422) {
          Swal.fire({
            title: 'Duplicação não realizada!',
            text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
            type: 'error',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          });
        }
      }
    );
  }

  disabledEditTable() {
    var inputTable = document.getElementsByClassName("disabledInput");

    if (!this.editmode) {
      this.editmode = true;
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table");
        inputTable[i].classList.add("form-pro-table-edit");
      }
    }
    else {
      this.editmode = false
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table-edit");
        inputTable[i].classList.add("form-pro-table");
      }
    }
  }
}
