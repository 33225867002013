import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DialogService, SelectItem, MenuItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PreExistence } from 'src/app/entities/preexistence/preexistence';
import { ApiService } from 'src/app/services/api.service';
import { PreexistenceModalComponent } from 'src/app/entities/preexistence/preexistenceAdd.modal';
import { PreexistenceEditModalComponent } from 'src/app/entities/preexistence/preexistenceEdit.modal';
import { PreexistenceViewModalComponent } from 'src/app/entities/preexistence/preexistenceView.modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-preexistence',
  templateUrl: './preexistence.component.html',
  providers: [DialogService],
  styleUrls: ['./preexistence.component.css']
})
export class PreexistenceComponent implements OnInit {
  preexistences: PreExistence[]
  cr: any;
  cols: any[];
  selectedItens: PreExistence[] = [];
  colsToggle: any[];
  selectedColumns: any[];
  status: SelectItem[];
  flag: SelectItem[];
  editmode = false;
  optionsThreePoints: MenuItem[] = [];
  nameFilter = '';
  statusFilter = '';
  flagFilter = '';
  descriptionFilter = '';
  updateFilter = '';
  rowsPerPageOptions;

  constructor(
    private userService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private preExistenceService: ApiService,
    private dialogService: DialogService) { }

  ptBr = {
    firstDayOfWeek: 0,
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: 'Hoje',
    clear: 'Limpar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
  };

  ngOnInit() {
    this.userService.getById("users", localStorage.getItem('currentUserId'))
      .then((cur) => {
        this.cr = cur['result'];
        this.loadAllPreExistences();
      });
    this.cols = [
      { field: 'name', header: 'Nome' },
      { field: 'description', header: 'Descrição' },
      { field: 'flag', header: 'Tipo' },
      { field: 'isVisible', header: 'Status' },
      { field: 'updatedAt', header: 'Modificado' }
    ];

    this.status = [
      { label: 'Ativo', value: true },
      { label: 'Inativo', value: false }
    ];

    this.flag = [
      { label: 'Piso', value: 'floor'},
      { label: 'Parede', value: 'wall'},
    ]

    var width = window.innerWidth;
    if (width <= 500) {
      this.colsToggle = [
        { field: 'name', header: 'Nome' },
        { field: 'flag', header: 'Tipo' },
        { field: 'isVisible', header: 'Status' }
      ];
    }
    else {
      this.colsToggle = [
        { field: 'name', header: 'Nome' },
        { field: 'description', header: 'Descrição' },
        { field: 'flag', header: 'Tipo' },
        { field: 'isVisible', header: 'Status' },
        { field: 'updatedAt', header: 'Modificado' }
      ];
    }

    this.selectedColumns = this.colsToggle;
  }

  private loadAllPreExistences() {
    this.preExistenceService.getAll("preexistences", true).then((preexistences) => {
      this.rowsPerPageOptions = undefined;
      if (preexistences['result'].length > 10 && preexistences['result'].length < 25) {
        this.rowsPerPageOptions = [10, 25];
      }
      else if (preexistences['result'].length > 25) {
        this.rowsPerPageOptions = [10, 25, preexistences['result'].length];
      }
      this.preexistences = preexistences['result'];
    });
  }

  clearFilters(dt) {
    this.nameFilter = '';
    this.descriptionFilter = '';
    this.statusFilter = '';
    this.flagFilter = '';
    this.updateFilter = '';
    dt.reset();
  }

  options(row: any) {
    this.optionsThreePoints = [
      { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
      { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
      { label: 'Duplicar', command: () => { this.duplicateRow(row); }, icon: 'lnr lnr-layers' },
      { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
    ]
    return this.optionsThreePoints;
  }

  delete(id: string) {
    Swal.fire({
      title: 'Você tem certeza que deseja realizar a deleção?',
      text: "Esta ação não poderá ser revertida! A deleção impactará no módulo de Ação Possível!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#D95536',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.preExistenceService.delete("preexistences", id).then(() => {
          this.loadAllPreExistences();
        },
          (error) => {
            this.swalDeleteFail();
          });
      }
    })
  }

  onRowSelect(event, row) {
    this.selectedItens.push(row);
  }

  filterUpdatedAtOnTable(value, dt) {
    value = value.getTime();
    dt.value.forEach(val => {
      if (typeof val.updatedAt === "string") {
        val.updatedAt = new Date(val.updatedAt).getTime();
      }
    });
    dt.filter(value, 'updatedAt', 'lte');
  }

  testExportCSV(dt) {
    dt.value.forEach(v => {
      for (let key in v) {
        if (typeof v[key] === "object" && v[key] != null) {
          if (key != 'imgUrl') v[key] = v[key].name;
        }
        if (key == 'updatedAt') {
          v[key] = new Date(v[key]).toLocaleString();
        }
      }
    });
    dt.exportCSV();
    this.loadAllPreExistences();
  }

  showAdd() {
    this.dialogService.open(PreexistenceModalComponent, {
      header: 'Cadastrar Preexistência',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAllPreExistences();
    });
  }

  showEdit(preexistence: PreExistence) {
    this.dialogService.open(PreexistenceEditModalComponent, {
      data: preexistence,
      header: 'Editar Preexistência',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAllPreExistences();
    });
  }

  showView(user: PreExistence) {
    this.dialogService.open(PreexistenceViewModalComponent, {
      data: user,
      header: 'Visualizar Preexistência',
      width: '70%',
      contentStyle: { "max-height": "80vh", "overflow": "auto" }
    }).onClose.subscribe(() => {
      this.loadAllPreExistences();
    });
  }

  deleteAll() {
    let ids: string[] = [];
    this.selectedItens.forEach(preexist => {
      ids.push(preexist.id);
    });
    this.preExistenceService.deleteAll("preexistences", { "ids": ids }).then(() => {
      this.selectedItens.length = 0;
      this.loadAllPreExistences();
    },
      (error) => {
        this.swalDeleteFail();
      });
  }

  swalDeleteFail() {
    Swal.fire({
      title: 'Deleção Não Realizada!',
      text: "Verifique se há relação com outro objeto!",
      type: 'warning',
      confirmButtonColor: '#D95536',
      allowOutsideClick: false
    });
  }

  makeIdToDuplicateRow(length) {
    let result = '';
    let characters = '123456790';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    result = "copy_" + result + "_";
    return result;
  }

  duplicateRow(row: PreExistence) {
    this.preExistenceService.register("preexistences", { "name": this.makeIdToDuplicateRow(5) + row['name'], "description": row['description'], "flag": row['flag'] ,"isVisible": row['isVisible'] }).then(() => {
      this.loadAllPreExistences();
    },
      (er) => {
        if (er.status === 422) {
          Swal.fire({
            title: 'Duplicação não realizada!',
            text: 'Não é possível duplicar elementos com mais de 89 caracteres no nome.',
            type: 'error',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
          });
        }
      }
    );
  }

  edit(preexistence: PreExistence) {
    this.preExistenceService.update("preexistences", preexistence).then(() => {
      this.loadAllPreExistences();
    }, (error) => {
      var msgs = error.error['messages'];
      var message = '';
      msgs.forEach((m, index) => {
        message += m;
        if (index < msgs.length - 1) message += ', ';
      });
      Swal.fire({
        title: 'Edição Não Realizada!',
        text: message,
        type: 'warning',
        confirmButtonColor: '#D95536',
        allowOutsideClick: false
      });
      this.loadAllPreExistences();
    });
  }

  disabledEditTable() {
    var inputTable = document.getElementsByClassName("disabledInput");

    if (!this.editmode) {
      this.editmode = true;
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table");
        inputTable[i].classList.add("form-pro-table-edit");
      }
    }
    else {
      this.editmode = false
      for (let i = 0; i < inputTable.length; i++) {
        inputTable[i].classList.remove("form-pro-table-edit");
        inputTable[i].classList.add("form-pro-table");
      }
    }
  }
}
