import { Routes, RouterModule } from '@angular/router';

import { UserComponent } from './entities/user/user.component';
import { LoginComponent } from './entities/auth/login/login.component';
import { RegisterComponent } from './entities/user/register/register.component';
import { ColorComponent } from "./entities/color/color.component";
import { MaterialComponent } from "./entities/material/material.component";
import { InterventionComponent } from "./entities/intervention/intervention.component";
import { AuthGuard } from './guards/auth.guard';
import { StandardComponent } from './entities/standard/standard.component';
import { StyleComponent } from './entities/style/style.component';
import { AppearanceComponent } from "./entities/appearance/appearance.component";
import { EnvironmentComponent } from './entities/environment/environment.component';
import { PreexistenceComponent } from './entities/preexistence/preexistence.component';
import { AmbienceComponent } from './entities/ambience/ambience.component';
import { PossibleActionComponent } from './entities/possibleAction/possibleAction.component';
import { AppearanceMaterialComponent } from './entities/appearanceMaterial/appearanceMaterial.component';
import { ForgotPasswordComponent } from './entities/user/forgotPassword/forgotPassword.component';
import { RegisterPasswordComponent } from "./entities/user/registerPassword/registerPassword.component";
import { EditPerfilComponent } from './entities/user/editPerfil/editPerfil.component';
import { QuestionComponent } from './entities/question/question.component';
import { ProductCategoryComponent } from './entities/productCategory/productCategory.component';
import { ManufacturerComponent } from './entities/manufacturer/manufacturer.component';
import { ProductComponent } from './entities/product/product.component';
import { RetailComponent } from './entities/retail/retail.component';
import { ProductApplicationComponent } from './entities/productApplication/productApplication.component';
import { StoreComponent } from './entities/store/store.component';
import { AppliedProductComponent } from './entities/appliedProduct/appliedProduct.component';
import { LeadComponent } from './entities/lead/lead.component';
import { WhitelabelComponent } from './entities/whitelabel/whitelabel.component';

const appRoutes: Routes = [
    { path: 'home', component: UserComponent, canActivate: [AuthGuard] },
    { path: '', component: LoginComponent },
    { path: 'forgotpassword', component: ForgotPasswordComponent},
    { path: 'passwordRecovery/:id', component: RegisterPasswordComponent},
    { path: 'edituser', component: EditPerfilComponent},
    { path: 'register', component: RegisterComponent },
    { path: 'color', component: ColorComponent, canActivate: [AuthGuard] },
    { path: 'material', component: MaterialComponent, canActivate: [AuthGuard] },
    { path: 'intervention', component: InterventionComponent, canActivate: [AuthGuard] },
    { path: 'standard', component: StandardComponent, canActivate: [AuthGuard] },
    { path: 'style', component: StyleComponent, canActivate: [AuthGuard] },
    { path: 'appearance', component: AppearanceComponent, canActivate: [AuthGuard] },
    { path: 'environment', component: EnvironmentComponent, canActivate: [AuthGuard] },
    { path: 'preexistence', component: PreexistenceComponent, canActivate: [AuthGuard] },
    { path: 'ambience', component: AmbienceComponent, canActivate: [AuthGuard] },
    { path: 'possibleaction', component: PossibleActionComponent, canActivate: [AuthGuard] },
    { path: 'question', component: QuestionComponent, canActivate: [AuthGuard]},
    { path: 'appearancematerial', component: AppearanceMaterialComponent, canActivate: [AuthGuard] },
    { path: 'category', component: ProductCategoryComponent, canActivate: [AuthGuard] },
    { path: 'manufacturer', component: ManufacturerComponent, canActivate: [AuthGuard]},
    { path: 'product', component: ProductComponent, canActivate: [AuthGuard]},
    { path: 'productApplication', component: ProductApplicationComponent, canActivate: [AuthGuard]},
    { path: 'appliedProduct', component: AppliedProductComponent, canActivate: [AuthGuard]},
    { path: 'retail', component: RetailComponent, canActivate: [AuthGuard]},
    { path: 'store', component: StoreComponent, canActivate: [AuthGuard]},
    { path: 'lead', component: LeadComponent, canActivate: [AuthGuard]},
    { path: 'whitelabel', component: WhitelabelComponent, canActivate: [AuthGuard]},
    { path: '**', redirectTo: '' }

];

export const routing = RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', useHash: true });