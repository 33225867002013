import { Component } from "@angular/core";
import { Appearance } from "./appearance";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { Color } from "src/app/entities/color/color";
import { Standard } from "src/app/entities/standard/standard";
import { S3Service } from '../../services/s3.service';
import * as AWS from 'aws-sdk/global';
import Swal from 'sweetalert2';


@Component({
    templateUrl: './appearanceAdd.modal.html',
})
export class AppearanceAddModalComponent {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    selectedColor: Color;
    selectedStandard: Standard;
    colors: Color[];
    standards: Standard[];
    selectedFiles: FileList;
    tempUrl: any;

    constructor(
        private appearanceService: ApiService,
        private colorService: ApiService,
        private standardService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        standardService.getAll("standards", true).then(standard => this.standards = standard['result']);
        colorService.getAll("colors").then(color => this.colors = color['result']);
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            color: ['', Validators.required],
            standard: ['', Validators.required],
            imgUrl: [''],
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    deleteImg() {
        this.tempUrl = null;
        this.registerForm.value.imgUrl1 = null;
        document.getElementById("imgUrl")['value'] = "";
    }


    onSubmit() {
        this.submitted = true;
        this.registerForm.value.imgUrl = this.tempUrl;
        this.loading = true;
        this.appearanceService.register("appearances", <Appearance>{ "name": this.registerForm.value.name, "colorId": this.registerForm.value.color.id, "standardId": this.registerForm.value.standard.id, "imgUrl": this.registerForm.value.imgUrl })
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536'
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536'
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    async selectFile(event) {
        if (event.files.item(0).size <= 2000000) {
            await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                if (imgUrl) {
                    this.tempUrl = imgUrl.toString();
                }
            });
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Coloque uma imagem com tamanho máximo de 2mb',
                type: 'warning',
                confirmButtonColor: '#D95536'
            });
        }
    }
}