import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppearanceMaterial } from './appearanceMaterial';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DialogService, SelectItem } from "primeng/api";
import { AppearanceMaterialAddModalComponent } from "./appearanceMaterialAdd.modal";
import { AppearanceMaterialEditModalComponent } from "./appearanceMaterialEdit.modal";
import { AppearanceMaterialViewModalComponent } from "./appearanceMaterialView.modal";
import { MenuItem } from "primeng/api";
import Swal from 'sweetalert2';

@Component({
    templateUrl: 'appearanceMaterial.component.html',

    providers: [DialogService]
})
export class AppearanceMaterialComponent implements OnInit {
    appearanceMaterials: AppearanceMaterial[] = [];
    allAppearanceMaterials = [];
    items: MenuItem[];
    cr: any;
    cols: any[];
    colsToggle: any[];
    selectedColumns: any[];
    selectedAppearanceMaterials: AppearanceMaterial[] = [];
    status: SelectItem[];
    isValidOptions: SelectItem[];
    appearances: any[];
    appearanceList: SelectItem[] = [];
    materials: any[];
    materialList: SelectItem[] = [];
    optionsThreePoints: MenuItem[];
    appearanceFilter = null;
    materialFilter = null;
    validFilter = '';
    statusFilter = '';
    updateFilter = '';
    rowsPerPageOptions;
    ptBr = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sáb"],
        dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Sem'
    };

    constructor(
        private appearanceMaterialService: ApiService,
        private authenticationService: AuthenticationService,
        private appearanceService: ApiService,
        private materialService: ApiService,
        private router: Router,
        private dialogService: DialogService
    ) {
        this.appearanceService.getAll("appearances").then(appearances => {
            this.appearances = appearances['result'];
        });
        this.materialService.getAll("materials").then(materials => {
            this.materials = materials['result'];
        });
    }

    ngOnInit() {
        this.loadAllAppearanceMaterialsByAdmin();

        this.status = [
            { label: 'Ativo', value: true },
            { label: 'Inativo', value: false }
        ];

        this.isValidOptions = [
            { label: 'Válido', value: true },
            { label: 'Inválido', value: false }
        ];

        this.cols = [
            {  orderCol: 'appearance.name', field: 'appearance', header: 'Aparência', width: '40%' },
            {  orderCol: 'material.name', field: 'material', header: 'Material', width: '40%' },
            {  orderCol: 'isValid', field: 'isValid', header: 'Válido' },
            {  orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' }
        ];

        //Check responsiveness to adjust columns to display on mobile
        var width = window.innerWidth;
        if (width <= 500) {
            this.colsToggle = [
                { field: 'appearance', header: 'Aparência', width: '40%' },
                { field: 'material', header: 'Material', width: '40%' },
                { field: 'isValid', header: 'Válido' }
            ];
        }
        else {
            this.colsToggle = [
                { orderCol: 'appearance.name', field: 'appearance', header: 'Aparência', width: '40%' },
                { orderCol: 'material.name', field: 'material', header: 'Material', width: '40%' },
                { orderCol: 'isValid', field: 'isValid', header: 'Válido' },
                { orderCol: 'updatedAt', field: 'updatedAt', header: 'Modificado' },
            ];
        }

        this.selectedColumns = this.colsToggle;
    }

    clearFilters(dt){
        this.appearanceFilter = null;
        this.materialFilter = null;
        this.validFilter = '';
        this.statusFilter = '';
        this.updateFilter = '';
        this.appearanceMaterials = this.allAppearanceMaterials;
        dt.reset();
    }

    onRowSelect(event, row) {
        this.selectedAppearanceMaterials.push(row);
    }

    options(row: any) {
        this.optionsThreePoints = [
            { label: 'Visualizar', command: () => { this.showView(row); }, icon: 'lnr lnr-eye' },
            { label: 'Editar', command: () => { this.showEdit(row); }, icon: 'lnr lnr-pencil' },
            { label: 'Deletar', command: () => { this.delete(row['id']); }, icon: 'lnr lnr-trash' }
        ]
        return this.optionsThreePoints;
    }

    delete(id: string) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a deleção?',
            text: "Esta ação não poderá ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Deletar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.appearanceMaterialService.delete("appearanceMaterials", id).then(() => {
                    this.loadAllAppearanceMaterialsByAdmin();
                },
                    (error) => {
                        this.swalDeleteFail();
                    });
            }
        })
    }

    makeIdToDuplicateRow(length) {
        let result = '';
        let characters = '123456790';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        result = "copy_" + result + "_";
        return result;
    }

    duplicateRow(row: AppearanceMaterial) {
        this.loadAllAppearanceMaterialsByAdmin();
    }

    testExportCSV(dt) {
        dt.value.forEach(v => {
            for (let key in v) {
                if (typeof v[key] === "object" && v[key] != null) {
                    if (key != 'imgUrl') v[key] = v[key].name;
                }
                if (key == 'updatedAt') {
                    v[key] = new Date(v[key]).toLocaleString();
                }
            }
        });
        dt.exportCSV();
        this.loadAllAppearanceMaterialsByAdmin();
    }

    filterUpdatedAtOnTable(value, dt) {
        value = value.getTime();
        dt.value.forEach(val => {
            if (typeof val.updatedAt === "string") {
                val.updatedAt = new Date(val.updatedAt).getTime();
            }
        });
        dt.filter(value, 'updatedAt', 'lte');
    }

    loadFilters(){
        let appearanceName = [];
        let materialName = [];
        this.appearanceList = [];
        this.materialList = [];

        for(var i = 0; i < this.appearanceMaterials.length; i++){
            if(!appearanceName.includes(this.appearanceMaterials[i].appearance.name)){
                appearanceName.push(this.appearanceMaterials[i].appearance.name);
                this.appearanceList.push({"label": this.appearanceMaterials[i].appearance.name, "value": this.appearanceMaterials[i].appearance.name});                    
            }
            if(!materialName.includes(this.appearanceMaterials[i].material.name)){
                materialName.push(this.appearanceMaterials[i].material.name);
                this.materialList.push({"label": this.appearanceMaterials[i].material.name, "value": this.appearanceMaterials[i].material.name});                    
            }
        }
    }

    private loadAllAppearanceMaterialsByAdmin() {
        this.appearanceMaterialService.getAll("appearanceMaterials", true).then(appearanceMaterials => {
            this.rowsPerPageOptions = undefined;
            if(appearanceMaterials['result'].length > 10 && appearanceMaterials['result'].length < 25){
                this.rowsPerPageOptions = [10, 25];
            }
            else if(appearanceMaterials['result'].length > 25){
                this.rowsPerPageOptions = [10, 25, appearanceMaterials['result'].length];
            }
            this.appearanceMaterials = appearanceMaterials['result'];
            this.allAppearanceMaterials = appearanceMaterials['result'];
            this.loadFilters();
        });
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }

    showAdd() {
        this.dialogService.open(AppearanceMaterialAddModalComponent, {
            header: 'Cadastrar Aparência - Material',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllAppearanceMaterialsByAdmin();
        });
    }

    showEdit(appearanceMaterial: AppearanceMaterial) {
        this.dialogService.open(AppearanceMaterialEditModalComponent, {
            data: appearanceMaterial,
            header: 'Editar Aparência - Material',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllAppearanceMaterialsByAdmin();
        });
    }

    showView(appearanceMaterial: AppearanceMaterial) {
        this.dialogService.open(AppearanceMaterialViewModalComponent, {
            data: appearanceMaterial,
            header: 'Visualizar Aparência - Material',
            width: '70%',
            contentStyle: { "max-height": "80vh", "overflow": "auto" }
        }).onClose.subscribe(() => {
            this.loadAllAppearanceMaterialsByAdmin();
        });
    }

    deleteAll() {
        let ids: string[] = [];
        this.selectedAppearanceMaterials.forEach(appearanceMaterial => {
            ids.push(appearanceMaterial.id);
        });
        this.appearanceMaterialService.deleteAll("appearanceMaterials", { "ids": ids }).then(() => {
            this.selectedAppearanceMaterials.length = 0;
            this.loadAllAppearanceMaterialsByAdmin();
        }, (error) => {
            this.swalDeleteFail();
        });
    }

    swalDeleteFail() {
        Swal.fire({
            title: 'Deleção Não Realizada!',
            text: "Verifique se há relação com outro objeto!",
            type: 'warning',
            confirmButtonColor: '#D95536',
            allowOutsideClick: false
        });
    }

    edit(appearanceMaterial: AppearanceMaterial) {
        Swal.fire({
            title: 'Você tem certeza que deseja realizar a desativação?',
            text: "Esta ação fará com que desative a aparência-material equivalente em todos os whitelabels",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#D95536',
            confirmButtonText: 'Desativar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
                appearanceMaterial.appearanceId = appearanceMaterial.appearance.id;
                appearanceMaterial.materialId = appearanceMaterial.material.id;
                this.appearanceMaterialService.update("appearanceMaterials", appearanceMaterial).then(() => {
                    this.loadAllAppearanceMaterialsByAdmin();
                }, (error) => {
                    var text = '';
                    error['messages'].forEach((item, index) => {
                        text += item;
                        if (index < error['messages'].length - 1) text += ', ';
                    });
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: text,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loadAllAppearanceMaterialsByAdmin();
                });
            } else if(result.dismiss){
                appearanceMaterial.isValid = true;
            }
          })
    }

    editmode = false;
    disabledEditTable() {
        var inputTable = document.getElementsByClassName("disabledInput");
        if (!this.editmode) {
            this.editmode = true;
            for (let i = 0; i < inputTable.length; i++) {
                inputTable[i].classList.remove("form-pro-table");
                inputTable[i].classList.add("form-pro-table-edit");
            }
        }
        else {
            this.editmode = false;
            for (let j = 0; j < inputTable.length; j++) {
                inputTable[j].classList.remove("form-pro-table-edit");
                inputTable[j].classList.add("form-pro-table");
            }
        }
    }


    aparencias = [];
    materiais = [];
    all = [];
    filterObject(event, dt) {
        this.appearanceMaterials = this.allAppearanceMaterials;
        let tempObj: AppearanceMaterial[] = [];
        switch (dt) {
            case 'appearance':
                this.appearanceMaterials.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.appearance) === JSON.stringify(element)) {
                            tempObj.push(val);

                        }
                    });
                });
                this.aparencias = tempObj;
                break;
            case 'material':
                this.appearanceMaterials.forEach(val => {
                    event.value.forEach(element => {
                        if (JSON.stringify(val.material) === JSON.stringify(element)) {
                            tempObj.push(val);
                        }
                    });
                });
                this.materiais = tempObj;
                break;
                case 'global':
                        let ev = event.toUpperCase();
                        this.appearanceMaterials.forEach(val =>{
                                if((val.material.name.toUpperCase().includes(ev)) || (val.appearance.name.toUpperCase().includes(ev))){
                                    tempObj.push(val);
                                }
                        });
                    this.all = tempObj;
                }
                
        if(this.all.length != 0){
            this.appearanceMaterials = this.all;
        } else {
            if ((this.aparencias.length != 0) && (this.materiais.length != 0)) {
                this.appearanceMaterials = this.aparencias.filter(value => -1 !== this.materiais.indexOf(value));
            } else {
                if (this.aparencias.length == 0) {
                    this.appearanceMaterials = this.materiais;
                    if (this.materiais.length == 0) {
                        this.appearanceMaterials = this.allAppearanceMaterials;
                    }
                } else if (this.materiais.length == 0) {
                    this.appearanceMaterials = this.aparencias;
                    if (this.aparencias.length == 0) {
                        this.appearanceMaterials = this.allAppearanceMaterials;
                    }
                }
            }
        }
    }
}