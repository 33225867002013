import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Retail } from "./retail";

@Component({
    templateUrl: './retailView.modal.html',
})
export class RetailViewModalComponent {
    retail: Retail[];
    viewForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.retail = this.config.data;
        this.viewForm = this.formBuilder.group({
            name: [{ value: null, disabled: true }],
            cnpj: [{ value: null, disabled: true }],
            municipalRegistration:  [{ value: null, disabled: true }],
            stateRegistration: [{ value: null, disabled: true }],
            telephone: [{ value: null, disabled: true }],
            address: [{ value: null, disabled: true }],
            site: [{ value: null, disabled: true }],
            email: [{ value: null, disabled: true }],
            facebook: [{ value: null, disabled: true }],
            instagram: [{ value: null, disabled: true }],
            contactName: [{ value: null, disabled: true }],
            contactEmail: [{ value: null, disabled: true }],
            contactCell: [{ value: null, disabled: true }],
            logoUrl: [{ value: null, disabled: true }]
        });
    }

    close() {
        this.ref.close();
    }

}