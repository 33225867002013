import { Component } from "@angular/core";
import { PossibleAction } from "./possibleAction";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import Swal from 'sweetalert2';
import { PreExistence } from "src/app/entities/preexistence/preexistence";
import { Action } from "src/app/entities/action/action";
import { Material } from "src/app/entities/material/material";
import { ApiService } from "src/app/services/api.service";

import { S3Service } from "src/app/services/s3.service";
import { Intervention } from "src/app/entities/intervention/intervention";

@Component({
    templateUrl: './possibleActionEdit.modal.html',
})
export class PossibleActionEditModalComponent {
    possibleAction: PossibleAction[];
    updateForm: FormGroup;
    loading = false;
    submitted = false;
    actions: Action[];
    materials: Material[];
    preexistences: PreExistence[];
    selectedInterventions: Intervention[] = [];
    interventions: Intervention[];
    selectedFiles: FileList;
    tempUrl: any;

    constructor(
        private possibleActionService: ApiService,
        private actionService: ApiService,
        private preexistenceService: ApiService,
        private materialService: ApiService,
        private interventionService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        this.actionService.getAll("actions").then(actions => {
            this.actions = actions['result'];
        });
        this.materialService.getAll("materials").then(materials => {
            this.materials = materials['result'];
        });
        this.preexistenceService.getAll("preexistences").then(preexistences => {
            this.preexistences = preexistences['result'];
        });
        this.interventionService.getAll("interventions", true).then(intervention => {
            this.interventions = intervention['result'];

            this.interventions.forEach(e => {
                if (this.config.data['interventions'].some(elem => elem['id'] == e['id'])) {
                    this.selectedInterventions.push(e);
                }
            });
            this.config.data['interventions'] = this.selectedInterventions;
        });
    }

    ngOnInit() {
        this.possibleAction = this.config.data;
        this.tempUrl = this.possibleAction['imgUrl'];
        this.updateForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            action: [null, Validators.required],
            preexistence: [null, Validators.required],
            imgUrl: [null],
            material: [null],
            interventions: [null],
            speed: [null]
        });
    }

    get f() {
        return this.updateForm.controls;
    }

    deleteImg() {
        this.tempUrl = null;
        this.updateForm.value.imgUrl1 = null;
        document.getElementById("imgUrl")['value'] = "";
    }

    onSubmit(possibleAction: PossibleAction) {
        possibleAction['actionId'] = possibleAction.action.id;
        possibleAction['preexistenceId'] = possibleAction.preexistence.id;
        possibleAction['materialId'] = possibleAction.material.id;
        possibleAction['imgUrl'] = this.tempUrl;
        possibleAction['interventions'] = [];
        this.selectedInterventions.forEach(e => {
            possibleAction['interventions'].push(e.id);
        })
        this.loading = true;
        this.submitted = true;
        if (possibleAction.speed == "") possibleAction.speed = null;
        this.possibleActionService.update("possibleActions", possibleAction)
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Edição realizada com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Edição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }

    async selectFile(event) {
        if (event.files.item(0).type.includes("image")) {
            if (event.files.item(0).size <= 2000000) {
                await this.s3Service.uploadFile(event.files.item(0)).then((imgUrl) => {
                    this.tempUrl = imgUrl.toString();
                });
            } else {
                this.deleteImg();
                Swal.fire({
                    title: 'Upload de imagem não realizado!',
                    text: 'Coloque uma imagem com tamanho máximo de 2mb',
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        } else {
            this.deleteImg();
            Swal.fire({
                title: 'Upload de imagem não realizado!',
                text: 'Formato inválido. Coloque uma imagem no formato jpg, jpeg ou png com tamanho máximo de 2mb ',
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        }
    }
}