import { Component, OnInit } from '@angular/core';
import { Color } from 'src/app/entities/color/color';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { ApiService } from 'src/app/services/api.service';

@Component({
  templateUrl: './colorView.modal.html'
})
export class ColorViewModalComponent implements OnInit {
  color: Color[];
  viewForm: FormGroup;
  loading: false;
  submitted: false;

  constructor(
    private colorService: ApiService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.color = this.config.data;
    this.viewForm = this.formBuilder.group({
      name: [{ value: null, disabled: true }]
    });
  }

  close() {
    this.ref.close();
  }

}
