import { Component } from "@angular/core";
import { AppearanceMaterial } from "./appearanceMaterial";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

@Component({
    templateUrl: './appearanceMaterialView.modal.html',
})
export class AppearanceMaterialViewModalComponent {
    appearanceMaterial: AppearanceMaterial[];
    viewForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private appearanceMaterialService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private router: Router
    ) { }

    ngOnInit() {
        this.appearanceMaterial = this.config.data;
        this.viewForm = this.formBuilder.group({
            appearance: [{ value: null, disabled: true }],
            material: [{ value: null, disabled: true }]
        });
    }

    close() {
        this.ref.close();
    }
}