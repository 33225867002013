import { Component } from "@angular/core";
import { AppearanceMaterial } from "./appearanceMaterial";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";
import { Appearance } from "src/app/entities/appearance/appearance";

import { Material } from "src/app/entities/material/material";

import { S3Service } from '../../services/s3.service';
import * as AWS from 'aws-sdk/global';
import Swal from 'sweetalert2';

@Component({
    templateUrl: './appearanceMaterialAdd.modal.html',
})
export class AppearanceMaterialAddModalComponent {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    selectedAppearance: Appearance;
    selectedMaterial: Material;
    appearances: Appearance[];
    materials: Material[];
    selectedFiles: FileList;
    tempUrl: any;

    constructor(
        private appearanceMaterialService: ApiService,
        private appearanceService: ApiService,
        private materialService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {
        appearanceService.getAll("appearances", true).then(ap => this.appearances = ap['result']);
        materialService.getAll("materials").then(mat => this.materials = mat['result']);
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            appearance: ['', Validators.required],
            material: ['', Validators.required]
        });
    }

    get f() {
        return this.registerForm.controls;
    }

    onSubmit() {
        this.submitted = true;
        this.registerForm.value.imgUrl = this.tempUrl;

        if (this.registerForm.invalid) {
            return;
        }

        this.loading = true;
        this.appearanceMaterialService.register("appearanceMaterials", <AppearanceMaterial>{ "appearanceId": this.registerForm.value.appearance.id, "materialId": this.registerForm.value.material.id })
            .then(
                (success) => {
                    Swal.fire({
                        title: 'Cadastro realizado com sucesso!',
                        type: 'success',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.value) {
                            this.ref.close();
                        }
                    })
                },
                (error) => {
                    var errorMsg = error.error.messages.toString();
                    errorMsg = errorMsg.replace(/,/g, '');
                    Swal.fire({
                        title: 'Adição Não Realizada!',
                        text: errorMsg,
                        type: 'warning',
                        confirmButtonColor: '#D95536',
                        allowOutsideClick: false
                    });
                    this.loading = false;
                });
    }

    close() {
        this.ref.close();
    }
}