import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DynamicDialogRef, SelectItem } from "primeng/api";
import { DynamicDialogConfig } from "primeng/api";
import { Router } from "@angular/router";

import { ApiService } from 'src/app/services/api.service';

import { S3Service } from '../../services/s3.service';

import Swal from 'sweetalert2';
import { Standard } from '../standard/standard';
import { Manufacturer } from '../manufacturer/manufacturer';
import { ProductCategory } from '../productCategory/productCategory';
import { Color } from '../color/color';
import { Brand } from '../brand/brand';
import { ProductCategorySpecification } from '../productCategorySpecification/productCategorySpecification';

@Component({
    templateUrl: './productAdd.modal.html',
    styleUrls: ['./productAdd.modal.css'],
})
export class ProductAddModalComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    standards: Standard[];
    brand: Brand;
    manufacturers: Manufacturer[];
    brands: Brand[];
    categories: ProductCategory[];
    colors: Color[];
    binary: SelectItem[];
    binaryRequired: SelectItem[];
    specifics: ProductCategorySpecification[];
    resultSpecificsProduct: any[];
    impactAppearance: string;
    allColorsSelected: string = 'Não';
    allStandardsSelected: string = 'Não';
    type: SelectItem[];
    isService: any;

    constructor(
        private productService: ApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private s3Service: S3Service,
        private router: Router
    ) {

        this.productService.getAll("standards", true).then(result => {
            this.standards = result['result'];
            // var auxList = [];
            // auxList.push({ "name": null, "id": null, "isVisible": null });
            // this.standards.forEach(el => {
            //     auxList.push({ "name": el.name, "id": el.id, "isVisible": el.isVisible });
            // });

            // this.standards = auxList;
        });
        this.productService.getAll("manufacturers").then(manu => this.manufacturers = manu['result']);
        this.productService.getAll("productCategories").then(cat => this.categories = cat['result']);
        this.productService.getAll("colors").then(color => this.colors = color['result']);
        this.resultSpecificsProduct = [];
        this.specifics = [];
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            model: ['', Validators.compose([Validators.minLength(1), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            manufacturerCode: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            manufacturerLink: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            manualPdf: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            impactAppearance: ['', Validators.required],
            commercialColor: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^((?!\s{2,}).)*$/)])],
            benefits: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500)])],
            technicalObservations: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(500)])],
            productCategory: ['', Validators.required],
            manufacturer: ['', Validators.required],
            standard: [null],
            color: [null],
            isService: ['', Validators.required],
            brand: ['', Validators.required],
            allColorsSelected: ['Não', Validators.required],
            allStandardsSelected: ['Não', Validators.required]
        });

        this.binaryRequired = [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];

        this.type = [
            { label: 'Produto', value: false},
            { label: 'Serviço', value: true}
        ];

        this.binary = [
            // { label: '', value: null },
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ];
    }

    get f() {
        return this.registerForm.controls;
    }

    createItem(): FormGroup {
        return this.formBuilder.group({
            value: ''
        });
    }

    bool: boolean;

    addItem(ev: any, evId: any) {
        let self = this;
        self.bool = true;
        for (let i = 0; i < this.resultSpecificsProduct.length; i++) {
            if (this.resultSpecificsProduct[i].id == evId) {
                this.resultSpecificsProduct[i].name = ev;
                self.bool = false;
            }
        }
        if (self.bool) {
            this.resultSpecificsProduct.push({ id: evId, name: ev });
        } else {
            self.bool = true;
        }
    }

    checkColorStandard(ev, obj) {
        if (ev != 'required') return true;
        else if (ev == 'required') {
            if (obj.color.length > 0 && obj.standard.length > 0) {
                return true;
            } else return false;
        }
    }

    onSubmit() {
        if (this.resultSpecificsProduct.length != this.specifics.length) {
            Swal.fire({
                title: 'Adição não realizada!',
                text: "Os valores dos campos específicos são obrigatórios!",
                type: 'warning',
                confirmButtonColor: '#D95536',
                allowOutsideClick: false
            });
        } else {
            let objReg = this.registerForm.value;
            if (this.registerForm.value.standard) {
                objReg.standardId = this.registerForm.value.standard.id;
            }
            objReg.productCategoryId = this.registerForm.value.productCategory.id;
            objReg.manufacturerId = this.registerForm.value.manufacturer.id;
            try { objReg.brandId = this.registerForm.value.brand.id; } catch { }
            objReg.productSpecifics = this.resultSpecificsProduct;

            if(this.allColorsSelected === 'Sim'){
                let arrayColors = [];
                this.colors.forEach(obj => {
                    arrayColors.push(obj.id);
                });
                objReg.color = arrayColors;
            } else {
                if (this.registerForm.value.color) {
                    let arrayColors = [];
                    this.registerForm.value.color.forEach(obj => {
                        arrayColors.push(obj.id);
                    });
                    objReg.color = arrayColors;
                } else objReg.color = [];
            }
            if(this.allStandardsSelected === 'Sim'){
                let arrayStandards = [];
                this.standards.forEach(obj => {
                    arrayStandards.push(obj.id);
                });
                objReg.standard = arrayStandards;
            } else {
                if (this.registerForm.value.standard) {
                    let arrayStandards = [];
                    this.registerForm.value.standard.forEach(obj => {
                        arrayStandards.push(obj.id);
                    });
                    objReg.standard = arrayStandards;
                } else objReg.standard = [];
            }
            let constBool;

            if (this.impactAppearance == 'Sim') {
                constBool = this.checkColorStandard('required', objReg);
            } else constBool = this.checkColorStandard('not required', objReg);
            if (constBool) {
                this.productService.register("products", objReg)
                    .then(
                        (success) => {
                            Swal.fire({
                                title: 'Cadastro realizado com sucesso!',
                                type: 'success',
                                confirmButtonColor: '#D95536',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.value) {
                                    this.ref.close();
                                }
                            })
                        },
                        (error) => {
                            var errorMsg = error.error.messages.toString();
                            errorMsg = errorMsg.replace(/,/g, '');
                            Swal.fire({
                                title: 'Adição não realizada!',
                                text: errorMsg,
                                type: 'warning',
                                confirmButtonColor: '#D95536',
                                allowOutsideClick: false
                            });
                            this.loading = false;
                        });
            } else {
                Swal.fire({
                    title: 'Adição não realizada!',
                    text: "Os valores de Cor e Padrão devem ser obrigatórios quando o Impacto na Aparência for 'Sim'!",
                    type: 'warning',
                    confirmButtonColor: '#D95536',
                    allowOutsideClick: false
                });
            }
        }
    }

    close() {
        this.ref.close();
    }

    loadBrands() {
        this.brand = null;
        this.registerForm.value.brand = null;
        this.productService.listChildByEntityId(this.registerForm.value.manufacturer.id, '/manufacturers/brandsByManufacturer/').then((brands) => {
            this.brands = brands['result'];
        })
    }

    loadSpecifics() {
        this.resultSpecificsProduct.length = 0;
        this.specifics.length = 0;
        this.productService.listChildByEntityId(this.registerForm.value.productCategory.id, 'productCategories/specificsByCategoryId/').then(specifics => {
            this.specifics = specifics['result'];
        });
    }

}
